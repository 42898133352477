import axiosInstance from '../config/axiosConfig';

export const sendMailChimpEmail = async (userEmail) => {
    try {
        const data = {
            email: userEmail,
        };

        const respAxios = await axiosInstance.post(
            `${process.env.REACT_APP_CORE_API_URI}/web/integrations/mailchimp/subscribe`,
            data
        );

        if (respAxios.status === 200) {
            return true;
        }

        return false;
    } catch (err) {
        console.error(err);
        return null;
    }
};
