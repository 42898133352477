import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { setColor, setFont } from '../../assets/styles/styles';
// sections

// import AuthSocial from '../../api/AuthSocial';
import IntraverseLogoFull from '../landingPages/intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import Button from '../../components/common/button/Button';
import Footer from '../../components/layout/footer/Footer';
import BackgroundContainer from '../../components/common/Backgrounds/BackgroundContainer';
import { isValidEmail } from '../../utils/generalUtils';
import { Input } from '../../components/common/input/Input';
import { forgotPasswordEmail } from '../../api/UserController';

const ForgotPassword = () => {
    const [emailValue, setEmailValue] = useState('');
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmailValue(value);

        if (isEmailError && value !== '') {
            setIsEmailError(false);
            setEmailErrorMsg('');
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsEmailError(false);
        setEmailErrorMsg('');

        if (emailValue === '') {
            setIsEmailError(true);
            setEmailErrorMsg('Email is required');
        } else if (!isValidEmail(emailValue)) {
            setIsEmailError(true);
            setEmailErrorMsg('Email must be a valid email address');
        }

        const response = await forgotPasswordEmail(`${window.location.origin}/reset-password`, emailValue);

        if (response.success) {
            setSuccess(true);
        }
        setEmailErrorMsg(`Email ${emailValue} not found`);
    };

    return (
        <BackgroundContainer loginBorder>
            <HeaderDiv>
                <Link to="/">
                    <LoginLogo src={IntraverseLogoFull} alt="Company Logo" />
                </Link>
                {/* <Link to="/register">
                    <Button variant="OutlinedButton" label="Create Account" margin="0 20px 0 0" />
                </Link> */}
            </HeaderDiv>
            {success ? (
                <ContentContainer>
                    {/* Checkmark icon */}
                    <svg
                        style={{ margin: '30px 0px' }}
                        width="100"
                        height="100"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="Checkmark">
                            <path
                                id="Union"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M30 3L25.3374 3.20976L10.2091 18.338L4.66261 12.7915L0 12.5817L0.20976 17.2444L5.75628 22.7909L5.75508 22.7921L10.2079 27.2449L10.2091 27.2437L10.2124 27.247L14.6653 22.7942L14.662 22.7909L29.7902 7.66261L30 3Z"
                                fill={setColor.gradient}
                            />
                        </g>
                    </svg>
                    <LoginTitle>Success, Email Sent!</LoginTitle>
                    <LoginSubtitle>
                        Please check your email inbox, including your spam or junk folder, for an email from us
                        containing instructions on how to reset your password.
                    </LoginSubtitle>
                </ContentContainer>
            ) : (
                <></>
            )}

            {!success ? (
                <ContentContainer>
                    <LoginTitle>Forgot password</LoginTitle>
                    <LoginSubtitle>Please enter the email associated with your account.</LoginSubtitle>

                    <StyledForm id="formLogin" onSubmit={(e) => onSubmit(e)}>
                        {emailErrorMsg && <ErrorMessage>{emailErrorMsg}</ErrorMessage>}
                        <Input
                            variant="LoginInput"
                            type="email"
                            placeholder="Email address"
                            value={emailValue}
                            onChangeHandler={handleEmailChange}
                        />
                        <Button
                            label="Confirm"
                            buttonWidth={'100%'}
                            variant={!isValidEmail(emailValue) ? 'DefaultButton' : 'PinkButton'}
                            type="submit"
                        >
                            Confirm
                        </Button>
                    </StyledForm>

                    {/* <AuthSocial /> */}
                    <Footer />
                </ContentContainer>
            ) : (
                <></>
            )}
        </BackgroundContainer>
    );
};

export default ForgotPassword;

// ----------------------------------------------------------------------
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 40px 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 90%;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    /* margin-top: -20px; */
`;

const ErrorMessage = styled.div`
    color: ${setColor.errorColor};
    margin-bottom: 3px;
`;

const LoginLogo = styled.img`
    display: flex;
    align-self: flex-start;
    width: 250px;
    height: auto;
    margin-bottom: 30px;
    margin-left: 10px;

    @media (max-width: 768px) {
        align-self: center;
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }
`;
const HeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        margin-left: 0px;
        margin-top: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const LoginTitle = styled.h3`
    color: ${setColor.accent4};
    text-align: center;
    font-family: ${setFont.barlowCondensed};
    font-size: 50px;
    margin-top: -0.7rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    @media (max-width: 960px) {
        font-size: 40px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 40px;
    }
`;

const LoginSubtitle = styled.p`
    color: ${setColor.circularProgress};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 20px;
    font-weight: 400;
    margin-top: 1.5rem;
    @media (max-width: 960px) {
        margin: 1rem -10px;
    }
`;
