import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../../../components/common/text/Typography';
import { setColor } from '../../../assets/styles/styles';
import { hexToRGBA } from '../../../utils/generalUtils';
import colorLogo from '../../../assets/images/logos/colorLogo.png';
import { getPaymentInfoBySessionId } from '../api/controller';

const CheckoutSuccess = () => {
    const { roomName, productId } = useParams();
    const token = useSelector((state) => state?.user?.token);
    const [isLoading, setLoading] = useState(true);
    const checkoutSessionId = localStorage.getItem('checkoutSessionId');
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (checkoutSessionId) {
                    const response = await getPaymentInfoBySessionId(token, checkoutSessionId, roomName, productId);

                    if (response.data && response.status === 200) {
                        if (response.data.status === 'open' && response.data.payment_status === 'unpaid') {
                            if (response.data.url) {
                                setLoading(false);
                                setRedirectUrl(response.data.url);
                            }
                        }
                        if (response.data.status === 'complete' && response.data.payment_status === 'paid') {
                            if (response.data.hostedInvoiceUrl === null) {
                                window.location.reload();
                            }
                            setRedirectUrl(response.data.hostedInvoiceUrl);
                            setLoading(false);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching checkout session:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl; // Redirect when checkoutUrl is available
        }
    }, [redirectUrl]); // Effect to redirect when checkoutUrl changes

    return (
        <>
            <div>
                {isLoading && (
                    <ModalContainer>
                        <ModalContent>
                            <StyledImage src={colorLogo} alt="logo" />
                            <Typography variant="PinkText" margin={`0rem 0 0rem 6rem`} fontSize="25px">
                                Redirecting to Stripe
                            </Typography>
                        </ModalContent>
                    </ModalContainer>
                )}
            </div>
        </>
    );
};

export default CheckoutSuccess;

const StyledImage = styled.img`
    width: 60%;
    height: 60%;
    margin: 0 auto;
    display: block;
    // padding: 10px;
`;

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${hexToRGBA(setColor.black, 0.85)};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
`;

const ModalContent = styled.div`
    border: 3px solid ${setColor.tickerBorder};
    padding: 25px;
    width: 500px;
    height: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @media (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 0.75em; /* Adjust the width as needed */
            border-width: 1px;
        }
        &::-webkit-scrollbar-track {
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;
            border-width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${hexToRGBA(setColor.accent4, 0.1)};
            border-radius: 10px;
            border-width: 1px;
        }
    }
    @media (max-width: 768px) {
        max-height: 100vh;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-radius: 0;
        justify-content: space-between;
    }
`;
