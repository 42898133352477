import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { setColor } from '../../../../assets/styles/styles';
import { default as Button } from '../../../../components/common/button/Button';
import Typography from '../../../../components/common/text/Typography';
import { disconnectMerchant } from '../api/merchantController';

const DisconnectModal = ({ id, closeModal, merchantName, editedStripeClientId }) => {
    const [disconnectText, setDisconnectText] = useState('');
    const [disconnectError, setDisconnectError] = useState('');
    const [disconnectDisabledFlag, setDisconnectDisabledFlag] = useState(true);

    const handleDisconnectClick = async () => {
        if (disconnectText.toLowerCase() === 'disconnect') {
            const resp = await disconnectMerchant(editedStripeClientId, id);
            closeModal();
        } else {
            setDisconnectError('Please type DISCONNECT to proceed.');
        }
    };
    useEffect(() => {
        setDisconnectError('');
        if (disconnectText.toLowerCase() === 'disconnect') {
            setDisconnectError('');
            setDisconnectDisabledFlag(false);
        }
    }, [disconnectText]);

    return (
        <>
            <Typography variant="ConsoleTitle" margin={'0 0 0 4rem'}>
                Disconnect Stripe Account for {merchantName}?
            </Typography>

            <Card>
                <Typography variant="pWhite" margin={'1rem'}>
                    Are you sure you want to disconnect the Stripe account associated with {merchantName}? This action
                    is permanent and cannot be undone.
                </Typography>

                <Typography variant="pWhite" margin={'1rem'}>
                    To proceed, please type <YellowText> DISCONNECT </YellowText> in the field below:
                </Typography>

                {disconnectError && <MessageDesc>{disconnectError}</MessageDesc>}

                <InputBox
                    type="text"
                    value={disconnectText}
                    onChange={(e) => setDisconnectText(e.target.value)}
                    placeholder="Type 'disconnect' to enable the button"
                />

                <Typography variant="pWhite" margin={'0rem'}>
                    Disconnecting will permanently remove the connected Stripe account.
                </Typography>

                <Button
                    variant={'PinkButton'}
                    buttonWidth={'100%'}
                    disabled={disconnectDisabledFlag}
                    label={'PERMANENTLY DISCONNECT'}
                    margin={'2rem 0 2rem 0'}
                    onClick={handleDisconnectClick}
                />

                <Typography variant="GreyText" onClick={closeModal} margin={'0'}>
                    GO BACK
                </Typography>
            </Card>
        </>
    );
};

export default DisconnectModal;

const Card = styled.div`
    height: auto;
    position: relative;
    padding: 10px;
    font-size: 16px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const YellowText = styled.span`
    color: ${setColor.yellowText};
    font-weight: 700;
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 1rem 0rem 1rem 2rem;
    height: 50%;
    width: 90%;
    background-color: transparent;
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
    margin: 0rem 0rem 1rem 2rem;
`;
