import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getSpotifyToken, handleSpotifyAuth } from './api/SpotifyController';
// import { SaveSpotify } from '../../redux/actions/SpotifyActions';
// import { fetchSpotify } from '../../redux/slices/spotify';
import spotifyLogo from '../../assets/images/spotify/spotifyLogo2.png';
import Button from '../../components/common/button/Button';
import { setToSessionStorage } from '../../hooks/useSessionStorage';

const VanitySpotify = (props) => {
    // const dispatch = useDispatch(); // Get the dispatch function
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    // Spotify States
    // const spotify = useSelector(fetchSpotify);

    useEffect(() => {
        // console.log('spotify', spotify);
        if (code) {
            getSpotifyToken(code, urlParams, props.redirectUrl).then((res) => {
                if (res?.accessToken) {
                    getProfile(res.accessToken).then((profile) => {
                        // Grabs profile and check if account is premium
                        if (profile.product === 'free') {
                            props.setSpotifyError(
                                'You need a Spotify Premium account to use Intraverse with Spotify, please login with your Email.'
                            );
                            // props.setSpotifyNotPremiumAccount(true);
                            return;
                        }

                        if (profile.product === 'premium') {
                            props.setEmail(profile.email);
                            setToSessionStorage('email', profile.email);
                            const script = document.createElement('script');
                            script.src = 'https://sdk.scdn.co/spotify-player.js';
                            script.async = true;
                            document.body.appendChild(script);
                            window.onSpotifyWebPlaybackSDKReady = () => {};

                            // dispatch(SaveSpotify(res.accessToken, res.accessToken));
                            setToSessionStorage('spotifyAccessToken', res.accessToken);
                            setToSessionStorage('spotifyRefreshToken', res.refreshToken);
                        }
                    });
                }
            });
        }
    }, []);

    return (
        <Button
            customIcon={spotifyLogo}
            backgroundColor={'black'}
            labelColor={'white'}
            border={'grey'}
            buttonWidth={'100%'}
            borderRadius={'0px'}
            customIconMargin={'5px 10px'}
            iconSize={'35px'}
            label="Continue with Spotify"
            variant="VanityLinkCustomizable"
            onClick={() => handleSpotifyAuth(props.redirectUrl)}
        />
    );
};

export default VanitySpotify;
