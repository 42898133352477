import React from 'react';
import styled from 'styled-components';
import { setColor } from '../../../../assets/styles/styles';
import Typography from '../../../../components/common/text/Typography';
import { device } from '../../../../assets/styles/mediaBreakpoints';
import MusicIcon from '../assets/icons/UI_Icon_Volume_On.png';
import GameController from '../assets/icons/UI_Icon_Console.png';
import GeoIcon from '../assets/icons/UI_Icon_Location.png';
import BroadcastIcon from '../assets/icons/UI_Icon_Broadcast.png';
import SecureIcon from '../assets/icons/UI_Icon_Password.png';
import StarIcon from '../assets/icons/UI_Icon_Creator.png';

const TranscendSection = () => (
    <SectionBackground>
        <SectionContainer>
            <div>
                <Typography variant="LandingPageTitle" style={{ margin: '0', fontSize: '3.2rem' }}>
                    <Highlighted>Transcend Beyond</Highlighted> 2D Scrolling
                </Typography>
            </div>
            <GalleryDisplay>
                <StyledTypographyDescription variant="pWhite">
                    <HighlightedDescription>
                        <Icon src={MusicIcon} alt="Music Icon" />
                        Music & Video Integration
                    </HighlightedDescription>{' '}
                    for a curated audio-visual experience that enhances your world with your content.
                </StyledTypographyDescription>
                <StyledTypographyDescription variant="pWhite">
                    <HighlightedDescription>
                        {' '}
                        <Icon src={GeoIcon} alt="Geo Icon" />
                        Geo-Fencing
                    </HighlightedDescription>{' '}
                    for improved analytics and data-driven advertising and user segmentation.
                </StyledTypographyDescription>
                <StyledTypographyDescription variant="pWhite">
                    <HighlightedDescription>
                        {' '}
                        <Icon src={SecureIcon} alt="Secure Icon" />
                        Secure Transactions
                    </HighlightedDescription>{' '}
                    with fiat & crypto payment gateways, so users can purchase your products or services seamlessly.
                </StyledTypographyDescription>
                <StyledTypographyDescription variant="pWhite">
                    <HighlightedDescription>
                        {' '}
                        <Icon src={GameController} alt="Game Icon" />
                        Gamification
                    </HighlightedDescription>{' '}
                    to build user engagement through fun objectives and quests throughout.
                </StyledTypographyDescription>
                <StyledTypographyDescription variant="pWhite">
                    <HighlightedDescription>
                        <Icon src={BroadcastIcon} alt="Broadcast Icon" />
                        Live Broadcasting
                    </HighlightedDescription>{' '}
                    to stream your content from any live streaming platform to increase connectivity with closer-to-life
                    interactions in a 3D space.
                </StyledTypographyDescription>
                <StyledTypographyDescription variant="pWhite">
                    <HighlightedDescription>
                        {' '}
                        <Icon src={StarIcon} alt="Star Icon" />
                        Customized Assets
                    </HighlightedDescription>{' '}
                    for artists or content producers to represent their unique identity through unique avatars and
                    interior/exterior environments.
                </StyledTypographyDescription>
            </GalleryDisplay>
        </SectionContainer>
    </SectionBackground>
);

export default TranscendSection;

const Icon = styled.img`
    width: 15px;
    margin-right: 10px;
`;

const HighlightedDescription = styled.span`
    color: white;
    font-weight: 800;
`;

const StyledTypographyDescription = styled(Typography)`
    text-align: left;
    margin: 0;
    line-height: 1.4;
    font-size: 19px;

    @media (max-width: 960px) {
        font-size: 16px;
        margin: 0;
    }
`;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #110e25;

    @media (max-width: 960px) {
        height: auto;
    }
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    @media ${device.desktopWide} {
        padding: 75px 0;
        gap: 50px;
    }

    @media (max-width: 960px) {
        width: 90%;
    }
`;

const GalleryDisplay = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 3 items per row
    gap: 30px;

    @media (max-width: 960px) {
        grid-template-columns: 1fr;
        margin-top: 50px;
        padding: 10px;
    }
`;
