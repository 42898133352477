import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { setColor, setFont } from '../../../../../assets/styles/styles';
import { putBannerImage, putPersonalizationItem } from '../../../../../api/RoomsController';
import { uploadFile } from '../../../../../api/FilesController';
import Banner from '../../../../../components/layout/ui/Banner';
import LoadingIcon from '../../../../../components/common/loading/LoadingIcon';

const MediaUploader = ({ frame, typeUpload, setIsOpen, setSuccessBannerMessage, token, userId }) => {
    const [mode, setMode] = useState(typeUpload);
    const [inputValue, setInputValue] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [openBanner, setOpenBanner] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerType, setBannerType] = useState('ERROR');

    const runBanner = (message) => {
        setBannerMessage(message);
        setBannerType('ERROR');
        setOpenBanner(true);
    };

    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState(null);
    const { id } = useParams();

    if (!mode) {
        setMode('link');
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        URL.createObjectURL(file);
        setFile(file);
        setFileName(file.name);
    };

    const isValidUrl = async (url) => {
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?\.(jpg|jpeg|png)$/i;
        if (!urlRegex.test(url)) {
            return false;
        }

        try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.ok;
        } catch (error) {
            console.error('Error checking URL:', error);
            return false;
        }
    };

    const isValidImageFile = (filename) => {
        const fileRegex = /\.(jpg|png)$/i;
        return fileRegex.test(filename);
    };

    const isVideoUrl = (url) => {
        const videoRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|vimeo\.com\/)[a-zA-Z0-9_-]+$/;
        return videoRegex.test(url);
    };

    const isFileSizeLessThan5MB = (fileSizeInBytes) => {
        const sizeLimitInBytes = 5 * 1024 * 1024; // 5 MB in bytes
        return fileSizeInBytes < sizeLimitInBytes;
    };

    const handleSubmitMainThumbnail = async () => {
        setIsButtonDisabled(true);
        setIsLoading(true);
        if (mode === 'link') {
            const validUrl = await isValidUrl(inputValue);

            if (!validUrl) {
                runBanner('Invalid Url, should contain jpg | jpeg | png extension.');
                setIsLoading(false);
                return;
            }

            const response = await putBannerImage(
                id,
                { bannerImageKey: null, bannerImageExternalUrl: inputValue },
                token
            );

            if (response.status === 200) {
                setSuccessBannerMessage('URL updated');
                setIsOpen(false);
            } else {
                runBanner('Invalid URL provided');
            }
        }
        if (mode === 'file') {
            file.usageType = 'roomBannerImage';
            if (!isValidImageFile(file.name)) {
                runBanner('There was an error uploading your file. Please use .jpg or .png files');
                setIsLoading(false);
                return;
            }

            if (!isFileSizeLessThan5MB(file.size)) {
                runBanner('Media exceeds maximum file size of 5 MB');
                setIsLoading(false);
                return;
            }

            const response = await uploadFile(
                { file, usageType: 'roomBannerImage', roomId: frame.roomId, targetUserId: userId },
                token
            );

            if (response.status === 200) {
                const fileId = response.data.id;

                const responseUpdateRoom = await putBannerImage(
                    id,
                    { bannerImageKey: fileId, bannerImageExternalUrl: null },
                    token
                );
                if (responseUpdateRoom.status === 200) {
                    setSuccessBannerMessage('File Successfully Updated');
                    setIsOpen(false);
                } else {
                    runBanner('There was an error with uploading your file.');
                }
            } else {
                runBanner('There was an error with uploading your file.');
            }
        }
    };

    const handleSubmit = async () => {
        setIsButtonDisabled(true);
        setIsLoading(true);

        if (frame.isMain) {
            await handleSubmitMainThumbnail();
            return;
        }

        if (mode === 'link') {
            const validUrl = await isValidUrl(inputValue);

            if (!validUrl) {
                runBanner('Invalid Url, should contain jpg | jpeg | png extension.');
                setIsLoading(false);
                return;
            }

            if (frame.frameType === 'video') {
                if (!isVideoUrl(inputValue)) {
                    runBanner('Invalid URL provided. Please provide a youtube or Vimeo link');
                    setIsLoading(false);
                    return;
                }
            }

            const response = await putPersonalizationItem(
                frame.id,
                {
                    isExternalUrl: true,
                    externalUrl: inputValue,
                    fileId: null,
                },
                token
            );

            if (response.status === 204 || response.status === 200) {
                setSuccessBannerMessage('Successfully updated URL');
                setIsOpen(false);
            } else {
                runBanner('Invalid URL Provided');
            }
        }

        if (mode === 'file') {
            file.usageType = 'roomDetailsImage';

            if (!isValidImageFile(file.name)) {
                runBanner('There was an error uploading your file. Please use .jpg or .png files');
                setIsLoading(false);
                return;
            }

            if (!isFileSizeLessThan5MB(file.size)) {
                runBanner('Media exceeds maximum file size of 5 MB');
                setIsLoading(false);
                return;
            }

            let response = await uploadFile(
                { file, usageType: 'roomDetailsImage', roomId: frame.roomId, targetUserId: userId },
                token
            );

            if (response.status === 200) {
                const fileId = response.data.id;
                response = await putPersonalizationItem(
                    frame.id,
                    {
                        isExternalUrl: false,
                        externalUrl: null,
                        fileId,
                    },
                    token
                );
                if (response.status === 204) {
                    setSuccessBannerMessage('Successfully uploaded file');
                    setIsOpen(false);
                }
            } else {
                runBanner('Error Uploading File');
            }

            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsButtonDisabled(true);
        if (mode === 'link' && inputValue.length > 0) {
            setIsButtonDisabled(false);
        }
        if (mode === 'file' && file) {
            setIsButtonDisabled(false);
        }
    }, [mode, file, inputValue]);

    return (
        <MediaUploaderContainer>
            <Banner
                message={bannerMessage}
                type={bannerType}
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                topDisplay
                left={'0'}
                showCloseButton={false}
                timer={8000}
                position={'absolute'}
            />
            <UserConsoleText style={{ marginTop: '0', textAlign: 'center' }}>Update media</UserConsoleText>
            <SmallSubtitle>Customize your space by uploading an image or using an existing link.</SmallSubtitle>
            <ButtonsContainer>
                <CustomButton
                    isActive={mode === 'file'}
                    onClick={() => {
                        if (frame.frameType) {
                            if (frame.frameType === 'video') return;
                        }
                        setMode('file');
                    }}
                    isVideo={frame.frameType === 'video'}
                >
                    Upload File
                </CustomButton>
                <CustomButton
                    isActive={mode === 'link'}
                    onClick={() => {
                        setMode('link');
                    }}
                >
                    Online Link
                </CustomButton>
            </ButtonsContainer>

            {mode === 'link' ? (
                <UrlInput
                    placeholder="https://www.myimage.com/cats.jpg"
                    value={inputValue}
                    onInput={(e) => setInputValue(e.target.value)}
                />
            ) : (
                <></>
            )}

            {mode === 'file' ? (
                <FormInputWrapper>
                    <FormInput id="fileInput" type="file" name="file" onChange={handleFileChange} required />
                    <SvgHolder>
                        {!file ? (
                            <>
                                <svg
                                    width="47"
                                    height="30"
                                    viewBox="0 0 47 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M37.5323 11.325C36.2134 4.85625 30.3362 0 23.2759 0C17.6703 0 12.8017 3.075 10.3772 7.575C4.53879 8.175 0 12.9563 0 18.75C0 24.9562 5.21767 30 11.6379 30H36.8534C42.2069 30 46.5517 25.8 46.5517 20.625C46.5517 15.675 42.5754 11.6625 37.5323 11.325ZM27.1552 16.875V24.375H19.3966V16.875H13.5776L23.2759 7.5L32.9741 16.875H27.1552Z"
                                        fill="#BEAEFF"
                                    />
                                </svg>

                                <TextFileUpload>Drag or browse a file to upload.</TextFileUpload>
                            </>
                        ) : (
                            <>
                                <TextFileUpload>Selected File</TextFileUpload>
                                <TextFileUpload>{fileName}</TextFileUpload>
                            </>
                        )}
                    </SvgHolder>
                </FormInputWrapper>
            ) : (
                <></>
            )}

            <InfoContainer>
                <SpanRow>
                    <Text>
                        Font:{' '}
                        <TextLight>
                            {(() => {
                                if (frame.type === 'video') {
                                    return 'YouTube or Vimeo link.';
                                }
                                return 'PNG or JPG';
                            })()}
                        </TextLight>
                    </Text>
                </SpanRow>
                <SpanRow>
                    <Text>
                        Recommended size:
                        <TextLight>{frame.recommendedFileSize} pixels</TextLight>
                    </Text>
                </SpanRow>
                <SpanRow>
                    <Text>
                        Maximun file size:
                        <TextLight>5 MB</TextLight>
                    </Text>
                </SpanRow>
            </InfoContainer>
            <PinkButton type="button" disabled={isButtonDisabled} onClick={() => handleSubmit()}>
                {isLoading ? <LoadingIcon /> : 'ADD MEDIA'}
            </PinkButton>
        </MediaUploaderContainer>
    );
};

const UserConsoleText = styled.h1`
    color: ${setColor.iconColor3};
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 1rem 0rem;
`;

const MediaUploaderContainer = styled.div``;

const FormInputWrapper = styled.div`
    border-radius: 5px;
    margin-top: 25px;
    border: 2.5px dashed ${setColor.greyBackground};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 2;
`;

const SvgHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    /* top: 20%; */
    /* left: 30%; */
`;

const FormInput = styled.input`
    height: 100px;
    opacity: 0; // Make the input transparent;
    width: 100%;
    z-index: 4;
`;

const TextFileUpload = styled.h3`
    font-family: ${setFont.barlow};
    color: ${setColor.iconColor};
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
`;

const UrlInput = styled.input`
    margin: 20px 0px;
    width: 100%;
    height: 50px;
    background-color: transparent;
    padding-left: 8px;
    border: 0.2px solid white;
    border-radius: 5px;
    color: white;
    padding-left: 10px;
    box-sizing: border-box;
`;

const SmallSubtitle = styled.p`
    text-align: center;
    color: ${setColor.circularProgress};
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 10px;
    padding-bottom: 5px;

    & > span {
        color: ${setColor.iconColor3};
        font-weight: 700;
    }
`;

const InfoContainer = styled.div`
    background-color: ${setColor.greyBackground};
    margin-top: 15px;
    box-sizing: border-box;
    padding: 10px;
`;

const SpanRow = styled.span`
    font-size: 16px;
    display: flex;
    color: ${setColor.iconColor};
`;

const Text = styled.strong`
    display: flex;
    font-size: 16px;
`;

const TextLight = styled.p`
    padding-left: 5px;
    font-weight: 400;
`;

const ButtonsContainer = styled.div`
    display: flex;
    font-size: 20px;
    font-family: ${setFont.barlow};
    justify-content: space-between;
`;

const CustomButton = styled.div`
    display: flex;
    background-color: ${(props) => (props.isActive ? `${setColor.iconColor3}` : '')};
    background-color: ${(props) => (props.isVideo ? `grey` : '')};
    border: ${(props) => (props.isActive ? `1px solid` : '1px solid #2B2A38')};
    color: ${(props) => (props.isActive ? `${setColor.inputBgColor}` : `${setColor.iconColor}`)};
    font-weight: ${(props) => (props.isActive ? '700' : '400')};
    font-family: ${setFont.barlow};
    width: 285px;
    height: 64px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
`;

const PinkButton = styled.button`
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background: ${(props) => (props.disabled ? 'grey' : setColor.pinkButtonBgGradient)};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    padding: 16px 35px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    color: ${setColor.inputText};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media (max-width: 768px) {
        padding: 15px 15px;
        font-size: 0.6rem;
        letter-spacing: 1px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 15px 15px;
        font-size: 0.6rem;
        letter-spacing: 1px;
    }
`;

export default MediaUploader;
