import axiosInstance from '../../../../config/axiosConfig';

export async function disconnectMerchant(accountId, merchantId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/stripe/connect-merchant/${accountId}/${merchantId}`;
        const respAxios = await axiosInstance.delete(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getAccountLink(accountId, merchantId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/stripe/account-link`;
        const respAxios = await axiosInstance.post(url, {
            merchantId,
            accountId,
            refreshUrl: window.location.href,
            returnUrl: window.location.href,
        });
        respAxios.data.status = respAxios.status;
        return respAxios.data;
    } catch (err) {
        return err;
    }
}

export async function connectMerchant(email, merchantId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/stripe/connect-merchant`;

        const respAxios = await axiosInstance.post(url, {
            email,
            merchantId,
        });
        respAxios.data.status = respAxios.status;
        return respAxios.data;
    } catch (err) {
        return err;
    }
}

export async function deleteMerchant(merchantId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/merchant/${merchantId}`;
        const respAxios = await axiosInstance.delete(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getStripeAccountStatus(accountId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/connect-merchant/${accountId}`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getMerchantProducts(merchantId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/stripe/connect-merchant/products/${merchantId}`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}
