import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import { ReactComponent as CheckmarkIcon } from '../../../../../assets/images/icons/Checkmark.svg';
import { setColor, setFont } from '../../../../../assets/styles/styles';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icons/deleteIcon.svg';
import { ReactComponent as viewIcon } from '../../../../../assets/images/icons/viewIcon.svg';
import { ReactComponent as editIcon } from '../../../../../assets/images/icons/editIcon.svg';
import Modal from '../../../components/Modal';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';

const RolesPermissionsTable = ({ data, requestType, totalNumber }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const ROWS_PER_PAGE = 10;
    const totalPages = Math.ceil(totalNumber / ROWS_PER_PAGE);
    const visibleRows = data.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE);

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };
    
    const handleDeleteClick = (row) => {
        setSelectedRow(row);
        setIsModalDeleteOpen(true);
    }

    const handleViewClick = (row) => {
        navigate(`/console/${row.id}/permissions`);
    }
    
    const handleEditClick = (row) => {
        setSelectedRow(row);
        setIsModalEditOpen(true);
    }

    const renderRoleRow = (object) => {
        const buttonDel = (
            <StyledDeleteIcon onClick={() => handleDeleteClick(object)} />
        );
        
        const buttonView = (
            <StyledViewIcon onClick={() => handleViewClick(object)} />
        );
        const buttonEdit = (
            <StyledEditIcon onClick={() => handleEditClick(object)} />
        );

        return [
            object.id,
            object.name,
            <>{buttonView}</>,
            <>{buttonEdit}</>,
            <>{buttonDel}</>, 
        ];
    }

    const renderPermissionRow = (object) => {
        const buttonDel = (
            <StyledDeleteIcon onClick={() => handleDeleteClick(object)} />
        );
       
        return [
            object.permissionId,
            object.permissionName,
            object.permissionType,
            <>{buttonDel}</>, 
        ];
    }

    return (
        <>
            {requestType === 'roles' && (
                <DataTable
                    headers={['ROLE ID', 'NAME', '', '']}
                    data={visibleRows}
                    renderRow={renderRoleRow}
                    openModal={openModal}
                    isModalOpen={isModalOpen}
                    selectedRow={selectedRow}
                    isHover={false}
                />
            )}

            {requestType === 'permissions' && (
                <DataTable
                    headers={['PERMISSION ID', 'NAME', 'TYPE', '']}
                    data={visibleRows}
                    renderRow={renderPermissionRow}
                    openModal={openModal}
                    isModalOpen={isModalOpen}
                    selectedRow={selectedRow}
                    isHover={false}
                />
            )}

            {isModalDeleteOpen && (
                <Modal
                    isOpen={isModalDeleteOpen}
                    setIsOpen={setIsModalDeleteOpen}
                    children={
                        <DeleteModal
                            roleId={requestType === 'roles'? selectedRow.id : selectedRow.roleId}
                            permissionId= {requestType === 'permissions'? selectedRow.permissionId : null}
                            requestType={requestType}
                            closeDeleteModal={() => setIsModalDeleteOpen(false)}
                            name={requestType === 'roles' ? selectedRow.name : selectedRow.permissionName}
                        />
                    }
                />
            )}

            {isModalEditOpen && (
                <Modal
                    isOpen={isModalEditOpen}
                    setIsOpen={setIsModalEditOpen}
                    children={
                        <EditModal
                            requestType={requestType}
                            roleId={selectedRow.id}
                            closeEditModal={() => setIsModalEditOpen(false)}
                        />
                    }
                />
            )}

            <Footer>
                {requestType === 'roles' && (
                    <TableColumnLeft>
                        Total Number of Roles: {totalNumber}
                    </TableColumnLeft>
                )}
                {requestType === 'permissions' && (
                    <TableColumnLeft>
                        Total Number of Permissions: {totalNumber}
                    </TableColumnLeft>
                )}
            
                <TableColumnRight>
                    {visibleRows.length !== 0 && (
                        <Pagination>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <PaginationButton
                                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </PaginationButton>
                            <PaginationButton
                                onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </PaginationButton>
                        </Pagination>
                    )}
                </TableColumnRight>
            </Footer>
        </>
    );
};

export default RolesPermissionsTable;

const StyledCheckmark = styled(CheckmarkIcon)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
    #Union {
        fill: #93ebc7;
    }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledEditIcon = styled(editIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledViewIcon = styled(viewIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText}; 
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled}; 
    }
`;