import React from 'react';
import PropTypes from 'prop-types';

import style from './loadingIcon.module.css';

function LoadingIcon({ hover }) {
    const ball1Style = hover ? style.ball1 - hover : style.ball1;
    const ball2Style = hover ? style.ball2 - hover : style.ball2;
    const ball3Style = hover ? style.ball3 - hover : style.ball3;
    return (
        <div className={style.multiple1}>
            <div className={ball1Style} />
            <div className={ball2Style} />
            <div className={ball3Style} />
        </div>
    );
}

LoadingIcon.propTypes = {
    hover: PropTypes.bool,
};

export default LoadingIcon;
