import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setColor } from '../../../assets/styles/styles';
// import { size } from '../../../assets/styles/mediaBreakpoints';

const LandingPageContainer = ({ children, showOutline }) => (
    <IntraverseHomeContainer>
        {showOutline && <BorderOutlineStroke />}
        {children}
    </IntraverseHomeContainer>
);

LandingPageContainer.propTypes = {
    children: PropTypes.node,
    showOutline: PropTypes.bool,
};

export default LandingPageContainer;

const BorderOutlineStroke = styled.div`
    position: absolute;
    width: 97%;
    height: 99.4%;
    opacity: 0.2;
    pointer-events: none;
    top: 0.3%;
    left: 1.5%;
    z-index: 3000;
    border: ${() => `3px solid ${setColor.redBackground}`};

    @media (max-width: 768px) {
        top: 0.15%;
        height: 99.7%;
    }
`;

const IntraverseHomeContainer = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    overflow: hidden;
`;
