import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import 'react-datetime/css/react-datetime.css';
import { setColor } from '../../../../assets/styles/styles';
import Typography from '../../../../components/common/text/Typography';
import Banner from '../../../../components/layout/ui/Banner';
import { default as Button } from '../../../../components/common/button/Button';
import { ReactComponent as addicon } from '../../../../assets/images/icons/add.svg';
import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/Chevron.svg';
import { createRoom, getAllUsers, getRoomTags, getUserGroups } from '../api/myRoomsController';

const CreateMyRoom = ({ closeAddModal }) => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const timezone = 'America/Los_Angeles';
    const minDate = moment().startOf('day'); // Start of current day
    const [isLoading, setIsLoading] = useState(false);
    const [successMessageBanner, setSuccessMessageBanner] = useState('');
    const [isError, setIsError] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [roomName, setRoomName] = useState('');
    const [roomCode, setRoomCode] = useState('');
    const [roomType, setRoomType ] = useState('')
    const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
    const [isDropdownOpenType2, setIsDropdownOpenType2] = useState(false);
    const [isDropdownOpenType1, setIsDropdownOpenType1] = useState(false);
    const [selectedRoomType, setSelectedRoomType] = useState('');
    const [newRoomType, setNewRoomType] = useState('');
    const [roomTypeOptions, setRoomTypeOptions] = useState([
        { id: 'Apartment', name: 'Apartment' },
        { id: 'Casino', name: 'Casino' },
        { id: 'BandRoom', name: 'BandRoom' },
        { id: 'TerraZeroIsland', name: 'TerraZeroIsland' },
        { id: 'ResidentialHouse', name: 'ResidentialHouse' }
    ]);
    const [roomTags, setRoomTags] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedRoomTags, setSelectedRoomTags] = useState([]);
    const [selectedUserGroup, setSelectedUserGroup] = useState('');
    const [freeFormJson, setFreeFormJson] = useState({});
    const [selectedExpiryDate, setSelectedExpiryDate] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [userVisible, setUserVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpenUser, setIsDropdownOpenUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]); 
    const [allUsers, setAllUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [roomUsers, setRoomUsers] = useState([]);

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeAddModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await getAllUsers();
            if( response.status === 200 ){
                const data = response.data.data;
                setAllUsers(data);
            }
            if( response.status !== 200 ){
                setAllUsers([]);
            }
            const responseRoomTags = await getRoomTags();
            if (responseRoomTags) {
                setRoomTags(responseRoomTags);
            }
            const responseGroups = await getUserGroups();

            if (responseGroups) {
                setUserGroups(responseGroups);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };
    
    const handleRemoveTag = (tag) => {
        setSelectedRoomTags(selectedRoomTags.filter((selectedTag) => selectedTag !== tag));
    };

    const handleRoomTagsClick = (option) => {
        if (selectedRoomTags.includes(option)) {
            setSelectedRoomTags(selectedRoomTags.filter((tag) => tag.id !== option.id));
        } else {
            setSelectedRoomTags([...selectedRoomTags, option]);
        }

        setIsDropdownOpenType(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUserClick = (option) => {
        setSelectedUserId(option.userId);
        setIsDropdownOpenUser(false);
        setSelectedUser(option.email);
        setSearchTerm(option.email);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        const filtered = allUsers.filter(user =>
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(filtered);
        setIsDropdownOpenUser(true);
    };

    const addUserVisible = () => {
        setUserVisible(true);
    }

    const handleDateChange = (date) => {
        if (moment(date).isSameOrAfter(moment(), 'day')) {
          setSelectedExpiryDate(date);
        }
    };      

    const toggleDropdownType = () => {
        setIsDropdownOpenType(!isDropdownOpenType);
    };

    const handleRoomTypeClick = (option) => {
        setSelectedRoomType(option.id);
        setIsDropdownOpenType(false);
    }

    const handleNewRoomTypeChange = (e) => {
        setNewRoomType(e.target.value);
    };


    const handleAddNewRoomType = () => {
        if (newRoomType === '') {
            setIsError(true);
            setErrorMessage('Please enter a room type');
        }
        if(newRoomType !== ''){
            if (!roomTypeOptions.find(option => option.name === newRoomType)) {
                const newOption = { id: newRoomType, name: newRoomType };
                setRoomTypeOptions([...roomTypeOptions, newOption]);
                setSelectedRoomType(newRoomType);
                setNewRoomType('');
                setIsDropdownOpenType(false);
                setErrorMessage('');
                setIsError(false);
            }
        }
    };

    const AddRoomClick = async() => {
        const tags = selectedRoomTags.map((tag) => tag.id);
        const groupId = selectedUserGroup.id;
        
        const responseAddRoom = await createRoom(roomName, roomCode, 
            selectedRoomType, freeFormJson, selectedExpiryDate, 
            subtitle, description, selectedUserId, tags,
            groupId);
        if(responseAddRoom.status === 201){
            setSuccessMessageBanner('Room Added Successfully');
            setRoomName('');
            setRoomCode('');
            setSelectedRoomType('');
            setFreeFormJson('');
            setSelectedExpiryDate('');
            setSubtitle('');
            setDescription('');
            setSelectedUserId('');
            setSelectedRoomTags([]);
            setSelectedUserGroup('');
            setSelectedUser('');
            setRoomUsers([]);
            setErrorMessage('');
            setIsError(false);
            setIsDropdownOpenType(false);
            setIsDropdownOpenUser(false);
            setFilteredUsers([]);
            setAllUsers([]);
        

            setTimeout(() => {
                setSuccessMessageBanner('');
                closeAddModal();
                window.location.reload();
            }, 3000);
        }
    }

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={'SUCCESS'}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={'0px'}
                left={'0'}
            />
            <ModalContent>
                <GeneralContainer>
                    <Typography variant="h2PurpleHeading" margin={`0 0 10px 0`}>
                        Create MY Room
                    </Typography>

                    <Card>
                        <Table>
                            <TableRow>
                                <TableColumn>
                                    <Label>Your Room Name</Label>
                                    <InputBox
                                        type="text"
                                        id='roomName'
                                        placeholder="Enter Room Name"
                                        value={roomName}
                                        onChange={(e) => setRoomName(e.target.value)}
                                    /> 
                                </TableColumn>
                            </TableRow>
                            
                            <TableRow>
                                <TableColumn>
                                    <Label>Your Room Code</Label>
                                    <InputBox
                                        type="text"
                                        id='roomCode'
                                        placeholder="Enter Room Code"
                                        value={roomCode}
                                        onChange={(e) => setRoomCode(e.target.value)}
                                    /> 
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Room Type</Label>
                                    <DropdownButton onClick={toggleDropdownType} isopen={isDropdownOpenType.toString()}>                                          
                                        <TitleText isSelected={selectedRoomType === ''}>
                                            {selectedRoomType === '' ? 'Select Room Type' : selectedRoomType}
                                        </TitleText>

                                        <StyledDropdownIcon isopen={isDropdownOpenType.toString()} />
                                        {isDropdownOpenType && (
                                            <DropdownContent>
                                                {roomTypeOptions.map(option => (
                                                    <DropdownItem
                                                        key={option.id}
                                                        onClick={() => handleRoomTypeClick(option)}
                                                        isSelected={selectedRoomType === option.name}
                                                    >
                                                        {option.name}
                                                    </DropdownItem>
                                                ))}
                                                <DropdownItem>
                                                    <InputBox
                                                        width={'70%'}
                                                        type="text"
                                                        id='newRoomType'
                                                        placeholder="Add new room type"
                                                        value={newRoomType}
                                                        onChange={handleNewRoomTypeChange}
                                                        onClick={(e) => e.stopPropagation()}
                                                    /> 
                                                    <StyledAddIcon onClick={handleAddNewRoomType}/>
                                                </DropdownItem>
                                            </DropdownContent>
                                        )}
                                    </DropdownButton>
                                    {isError && (
                                        <MessageDesc>{errorMessage}</MessageDesc>
                                    )}
                                </TableColumn>
                            </TableRow> 

                            <TableRow>
                                <TableColumn>
                                    <Label>Room Tags</Label>
                                    <DropdownButton
                                        onClick={() => setIsDropdownOpenType2((value) => !value)}
                                        isopen={isDropdownOpenType2}
                                    >
                                        <TitleText isSelected={selectedRoomTags.length === 0}>
                                            {selectedRoomTags.length === 0 ? 'Select Room Tags' : ''}
                                        </TitleText>

                                        {isDropdownOpenType2 && (
                                            <DropdownContent>
                                                {roomTags.map((option) => (
                                                    <DropdownItem
                                                        key={option.id}
                                                        onClick={() => handleRoomTagsClick(option)}
                                                        isSelected={selectedRoomTags.some(
                                                            (tag) => tag.id === option.id
                                                        )}
                                                    >
                                                        {option.label}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownContent>
                                        )}
                                        {selectedRoomTags.length !== 0 && (
                                            <SelectedTags>
                                                {selectedRoomTags.map((tag) => (
                                                    <TagCard key={tag.id}>
                                                        {tag.label}
                                                        <RemoveTagButton onClick={() => handleRemoveTag(tag)}>
                                                            X
                                                        </RemoveTagButton>
                                                    </TagCard>
                                                ))}
                                            </SelectedTags>
                                        )}
                                        <StyledDropdownIcon isopen={isDropdownOpenType2} />
                                    </DropdownButton>
                                    {isError && <MessageDesc>{errorMessage}</MessageDesc>}
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>User Group</Label>
                                    <DropdownButton
                                        onClick={() => setIsDropdownOpenType1((value) => !value)}
                                        isopen={isDropdownOpenType1}
                                    >
                                        <TitleText isSelected={selectedUserGroup.length === 0}>
                                            {selectedUserGroup === '' ? 'Select User Group' : selectedUserGroup.level}
                                        </TitleText>

                                        <StyledDropdownIcon isopen={isDropdownOpenType1} />
                                        {isDropdownOpenType1 &&
                                            (console.log('userGroups', userGroups),
                                            (
                                                <DropdownContent>
                                                    {userGroups.map((option) => (
                                                        <DropdownItem
                                                            key={option.id}
                                                            onClick={() => setSelectedUserGroup(option)}
                                                            isSelected={selectedUserGroup === option.level}
                                                        >
                                                            {option.level}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownContent>
                                            ))}
                                    </DropdownButton>
                                    {isError && <MessageDesc>{errorMessage}</MessageDesc>}
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Room Expiry</Label>
                                    <CustomDatetime
                                        onChange={handleDateChange}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat="HH:mm:ss"
                                        utc
                                        inputProps={{
                                            placeholder: 'Choose Date',
                                            value: selectedExpiryDate ? moment(selectedExpiryDate).tz(timezone).format('YYYY-MM-DD HH:mm:ss') : ''
                                        }}
                                        isValidDate={(current) => current.isAfter(minDate)}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Subtitle</Label>
                                    <InputBox
                                        type="text"
                                        id='subtitle'
                                        placeholder="Enter Subtitle"
                                        value={subtitle}
                                        onChange={(e) => setSubtitle(e.target.value)}
                                    /> 
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Description</Label>
                                    <InputBox
                                        type="text"
                                        id='description'
                                        placeholder="Enter Description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    /> 
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    {!userVisible && (
                                        <Link onClick={addUserVisible}>Click here to add user into the room</Link>
                                    )}

                                    {userVisible && (
                                        <>
                                            <InputBox
                                                type="text"
                                                placeholder="Type to search user email"
                                                value={searchTerm}
                                                onChange={handleSearchTermChange}
                                            /> 
                                            {isDropdownOpenUser && (
                                                <DropdownContentUsers>
                                                    {filteredUsers.map(option => (
                                                        <DropdownItem
                                                            key={option.userId}
                                                            onClick={() => handleUserClick(option)}
                                                            isSelected={selectedUser === option.email}
                                                        >
                                                            {option.email}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownContentUsers>
                                            )}
                                            
                                            <MessageNote>
                                                Note: User will be added to the room with the default role of "guest"
                                            </MessageNote>
                                        </>
                                    )}
                                </TableColumn>
                            </TableRow>



                            <TableRow>
                                <Button
                                    variant={'PinkButton'}
                                    buttonWidth={'100%'}
                                    disabled={roomName === '' || roomCode === '' || selectedRoomType === ''}
                                    label={'Add Room'}
                                    onClick={AddRoomClick}
                                /> 
                            </TableRow>
                        </Table>
                    </Card>
                </GeneralContainer>
            </ModalContent>
        </>
    );
};

export default CreateMyRoom;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
`;

const MessageNote = styled.div`
    font-size: 14px;
    color: ${setColor.inputText};
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const Link = styled.a`
    color: ${setColor.hightlight};
    font-size: 14px;
    cursor: pointer;
    &:hover {
        color: ${setColor.accent4};
    }
`;
const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 292px); // 292px is the height of the nav bar + title component combined
    height: 100%;
    margin: 0;
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;


const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const InputBox = styled.input`
    padding: 10px;
    border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: ${(props) => (props.width ? props.width : '100%')};
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage ? '0.5rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const SelectedTags = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
    color: white;
`;

const TagCard = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: ${setColor.accent4};
    border-radius: 4px;
`;
const RemoveTagButton = styled.button`
    background: none;
    border: none;
    margin-left: 2px;
    cursor: pointer;
    color: black;
`;
const DropdownButton = styled.button`
    display: flex;
    font-family: 'Barlow-Regular', Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;

const TitleText = styled.p`
    font-family: 'Barlow-Regular', Helvetica;
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => (props.isSelected ? `#767676` : `${setColor.inputText}`)};
`;

const StyledAddIcon = styled(addicon)`
    width: 50px;
    height: 25px;
    cursor: pointer;
    margin: 0 0 -7px 0px;
`;

const CustomDatetime = styled(Datetime)`
    .rdtDisabled {
        cursor: not-allowed !important;
        color: #ccc; 
    }
    .rdt {
        background-color: #504c5c;
    }
    .rdtPicker {
        background-color: #504c5c;
        width: 100%;
        border-radius: 5px;
    }
    .rdtTimeToggle {
        background-color: #504c5c;
    }
    .rdtDays {
        background-color: #504c5c;
    }
    .rdtTime {
        background-color: #504c5c;
    }
    input {
        padding: 10px;
        border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
        border-radius: 5px;
        color: ${setColor.inputText};
        margin: 0rem 0rem 0rem 0rem;
        width: ${(props) => (props.width ? props.width : '100%')};
        background-color: transparent;
        margin-bottom: ${(props) => (props.errorMessage ? '0.5rem' : '0rem')};
        &:disabled {
            background-color: ${setColor.grey1};
        }
    }
`;

const DropdownContentUsers = styled.div`
    position: realtive;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
    max-height: 200px; 
    overflow-y: auto;
`;
