import React from 'react';
import styled from 'styled-components';
import { setColor } from '../../../assets/styles/styles';
import GreyChevron from '../../../assets/images/icons/GreyChevron.png';
import IntraverseMiniLogoIcon from '../../../assets/images/icons/ColoredIntraverseLogo.png';
import Typography from '../../../components/common/text/Typography';

const MobileSimpleNav = ({ title, func, showTitle }) => (
    <Container>
        {showTitle && (
            <ChevronAndTitleContainer onClick={func}>
                <Icon>
                    <img className="chevron-icon" src={GreyChevron} alt="Navigate" />
                </Icon>
                <Typography
                    variant="GreyText"
                    style={{
                        textTransform: 'uppercase',
                        margin: '0',
                        padding: '0',
                        fontSize: '16px',
                        letterSpacing: '1px',
                    }}
                >
                    {title}
                </Typography>
            </ChevronAndTitleContainer>
        )}
        <PortalHeaderLogo src={IntraverseMiniLogoIcon} alt="Intraverse Logo" />
    </Container>
);

export default MobileSimpleNav;

const Container = styled.div`
    position: sticky;
    width: 100%;
    height: 45px;
    min-hight: 45px;
    top: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: ${setColor.black};
    z-index: 1000;
    padding: 0px 20px;
`;

const PortalHeaderLogo = styled.img`
    width: 25px;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

const Icon = styled.div`
    cursor: pointer;
    margin-right: 12px;

    .chevron-icon {
        resizemode: contain;
        height: 12px;
        width: auto;
    }
`;

const ChevronAndTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
