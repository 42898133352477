import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import RoomsDetails from './RoomsDetails';
import { ReactComponent as CheckmarkIcon } from '../../../../../assets/images/icons/Checkmark.svg';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/images/icons/NotFound.svg';
import { ReactComponent as Cross } from '../../../../../assets/images/icons/Cross.svg';
import { ReactComponent as NullIcon } from '../../../../../assets/images/icons/System.svg';
import { setColor } from '../../../../../assets/styles/styles';
import { capitalizeFirstLetter } from '../../../../../utils/generalUtils';

const RoomsTable = ({ roomsData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
        navigate(`/console/rooms/${row.id}`);
    };

    const renderRow = (room) => {
        let featureComponent;
        const featureText = String(room.isFeatured);
        let textColor;

        if (room.isFeatured === true) {
            featureComponent = <StyledCheckmark />;
            textColor = setColor.successColor;
        } else if (room.isFeatured === false) {
            featureComponent = <StyledCloseIcon />;
            textColor = setColor.redColor;
        } else {
            featureComponent = <StyledNullIcon />;
            textColor = setColor.spanTextColor;
        }

        return [
            room.id,
            room.name,
            room.roomType,
            room.createdDateTime,
            room.updatedDateTime,
            <>
                {featureComponent}
                <StyledText color={textColor}>{capitalizeFirstLetter(featureText)}</StyledText>
            </>,
        ];
    };

    return (
        <DataTable
            headers={['ROOM ID', 'NAME', 'ROOM TYPE', 'CREATED AT', 'UPDATED AT', 'IS FEATURED']}
            data={roomsData}
            renderRow={renderRow}
            openModal={openModal}
            isModalOpen={isModalOpen}
            selectedRow={selectedRow}
            detailsComponent={RoomsDetails}
        />
    );
};

export default RoomsTable;

const StyledText = styled.span`
    color: ${(props) => props.color};
`;

const StyledCheckmark = styled(CheckmarkIcon)`
    width: 12px;
    height: 12px;
    margin-right: 10px;
    #Union {
        fill: ${setColor.successColor};
    }
`;
const StyledCloseIcon = styled(Cross)`
    width: 12px;
    height: 12px;
    margin-right: 10px;
    #Union {
        fill: ${setColor.errorColor};
    }
`;
const StyledNullIcon = styled(NullIcon)`
    width: 12px;
    height: 12px;
    margin-right: 10px;
`;
