import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { setColor } from '../../../../../../assets/styles/styles';
import { default as Button } from '../../../../../../components/common/button/Button';
import Typography from '../../../../../../components/common/text/Typography';
import Banner from '../../../../../../components/layout/ui/Banner';
import { getAllFormTypes, getFormStyleTypes, addSurvey } from '../../api/surveysController';
import { ReactComponent as DropdownIcon } from '../../../../../../assets/images/icons/Chevron.svg';
import AddSurveyOptions from './AddSurveyOptions';
import AddSurveyQuestion from './AddSurveyQuestion';
import SurveyDropdown from '../SurveyDropdown';

const AddSurvey = ({ closeAddModal }) => {  
    const token = useSelector((state) => state?.user?.token);
    const [validationError, setValidationError] = useState('');
    const [finalOptionsSurveysData, setFinalOptionsSurveysData] = useState([]);
    const [successMessageBanner, setSuccessMessageBanner] = useState('');
    const [optionsData, setOptionsData] = useState([]);
    const [surveyName, setSurveyName] = useState('');
    const [questionBody, setQuestionBody] = useState('');
    const [isDropdownOpenStyle, setIsDropdownOpenStyle] = useState(false);
    const [selectedOptionStyle, setSelectedOptionStyle] = useState('');
    const [styleOptionsData, setStyleOptionsData] = useState([]);
    const [isDropdownOpenAnonymous, setIsDropdownOpenAnonymous] = useState(false);
    const [selectedOptionAnonymous, setSelectedOptionAnonymous] = useState('');
    const [isDropdownOpenMustFinish, setIsDropdownOpenMustFinish] = useState(false);
    const [selectedOptionMustFinish, setSelectedOptionMustFinish] = useState('');
    const [isDropdownOpenPrvAllowed, setIsDropdownOpenPrvAllowed] = useState(false);
    const [selectedOptionPrvAllowed, setSelectedOptionPrvAllowed] = useState('');
    const [isDropdownOpenFeedback, setIsDropdownOpenFeedback] = useState(false);
    const [selectedOptionFeedback, setSelectedOptionFeedback] = useState('');
    const [isDropdownOpenRdmQuePool, setIsDropdownOpenRdmQuePool] = useState(false);
    const [selectedOptionRdmQuePool, setSelectedOptionRdmQuePool] = useState('');
    const [isDropdownOpenTimeLimit, setIsDropdownOpenTimeLimit] = useState(false);
    const [selectedOptionTimeLimit, setSelectedOptionTimeLimit] = useState('');
    const [optionName, setOptionName] = useState('');
    const [isHide, setIsHide] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showOption, setShowOption] = useState(false);
    const [quizOptionHide, setQuizOptionHide] = useState(false);
    const [minPassingScore, setMinPassingScore] = useState('');
    const [quizTime, setQuizTime] = useState('');
    const [maxPoints, setMaxPoints] = useState('');
    const [selectedOptionType, setSelectedOptionType] = useState('');
    const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
    const [showAllDoneFlag, setShowAllDoneFlag] = useState(false);
    const [isAddMoreQuestion, setIsAddMoreQuestion] = useState(false);
    const [questionSets, setQuestionSets] = useState([{ id: Date.now(), questionData: {}, optionData: [] }]);

    const options = [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
    ];

    const handleAddNewQuestionAndOption = () => {
        setIsAddMoreQuestion(true);
        setQuestionSets([...questionSets, { id: Date.now(), questionData: {}, optionData: [] }]);
    };

    const ReviewSurveyClick = () => {
        // Common survey-specific validation
        if (
            surveyName === '' || 
            questionBody === '' || 
            selectedOptionStyle === '' ||
            selectedOptionAnonymous === '' ||
            selectedOptionMustFinish === '' ||
            selectedOptionPrvAllowed === '' ||
            questionSets.length === 0
        ) {
            setValidationError('Please fill out all the survey fields.');
            return;
        }

        // Quiz-specific validation if selectedOptionType is 1 (indicating a quiz)
        if (selectedOptionType === 1) {
            if (
                selectedOptionFeedback === '' ||
                selectedOptionRdmQuePool === '' ||
                selectedOptionTimeLimit === '' ||
                minPassingScore === '' ||
                quizTime === '' ||
                maxPoints === ''
            ) {
                setValidationError('Please fill out all the quiz fields.');
                return;
            }
        }

        // If all validations pass
        setShowAllDoneFlag(true);
    };    

    const AddSurveyClick = async () => {
        try {
            const response = await addSurvey(token, surveyName, questionBody, selectedOptionType, selectedOptionStyle, selectedOptionAnonymous, selectedOptionMustFinish, selectedOptionPrvAllowed, questionSets, minPassingScore, quizTime, maxPoints, selectedOptionFeedback, selectedOptionRdmQuePool, selectedOptionTimeLimit);
            if (response.status === 201) {
                setSuccessMessageBanner(`${response.message}. Redirecting to the survey question page in 3 seconds...`);
            }
        } catch (error) {
            console.error('Error adding survey:', error);
        }
    };

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeAddModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    const fetchFormData = async () => {
        try {
            const response = await getAllFormTypes();
            if (response.status === 200) {
                const data = response.data.formTypes;
                const makeDropDownData = data.map((item) => {
                    return {
                        value: item.id,
                        label: item.formTypeName
                    };
                });
                
                setOptionsData(makeDropDownData);
            }
            const responseStyles = await getFormStyleTypes();
            if (responseStyles.status === 200) {
                const data = responseStyles.data.styleTypes;
                const makeDropDownData = data.map((item) => {
                    return {
                        value: item.id,
                        label: item.styleTypeName
                    };
                });
                setStyleOptionsData(makeDropDownData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchFormData();
    }, []);

    const handleFinalOptionsData = (data, id) => { 
        setFinalOptionsSurveysData(data);
        setQuestionSets(questionSets.map(set => set.id === id ? { ...set, optionData: data } : set)); 
        const updatedSets = questionSets.map(set => set.id === id ? { ...set, optionData: data } : set);
    };

    const handleFinalQuestionsData = (data, id) => {
        setQuestionSets(questionSets.map(set => set.id === id ? { ...set, questionData: data } : set));
    };
    
    const toggleDropdownType = () => {
        setIsDropdownOpenType(!isDropdownOpenType);
    };

    const handleOptionClickType = (option) => {
        setIsDropdownOpenType(false);
        setSelectedOptionType(option);
        if(option === 1){
            setQuizOptionHide(true);
        }
        if(option !== 1){
            setQuizOptionHide(false);
        }   
    };

    const toggleDropdownStyle = () => {
        setIsDropdownOpenStyle(!isDropdownOpenStyle);
    };

    const handleOptionClickStyle = (option) => {
        setIsDropdownOpenStyle(false);
        setSelectedOptionStyle(option);
    };

    const toggleDropdownAnonymous = () => {
        setIsDropdownOpenAnonymous(!isDropdownOpenAnonymous);
    };

    const handleOptionClickAnonymous = (option) => {
        setIsDropdownOpenAnonymous(false);
        setSelectedOptionAnonymous(option);
    };

    const toggleDropdownMustFinish = () => {
        setIsDropdownOpenMustFinish(!isDropdownOpenMustFinish);
    };

    const handleOptionClickMustFinish = (option) => {
        setIsDropdownOpenMustFinish(false);
        setSelectedOptionMustFinish(option);
    };

    const toggleDropdownPrvAllowed = () => {
        setIsDropdownOpenPrvAllowed(!isDropdownOpenPrvAllowed);
    };

    const handleOptionClickPrvAllowed = (option) => {
        setIsDropdownOpenPrvAllowed(false);
        setSelectedOptionPrvAllowed(option);
    };

    const toggleDropdownFeedback = () => {
        setIsDropdownOpenFeedback(!isDropdownOpenFeedback);
    };

    const handleOptionClickFeedback = (option) => {
        setIsDropdownOpenFeedback(false);
        setSelectedOptionFeedback(option);
    };

    const toggleDropdownRdmQuePool = () => {
        setIsDropdownOpenRdmQuePool(!isDropdownOpenRdmQuePool);
    };

    const handleOptionClickRdmQuePool = (option) => {
        setIsDropdownOpenRdmQuePool(false);
        setSelectedOptionRdmQuePool(option);
    };

    const toggleDropdownTimeLimit = () => {
        setIsDropdownOpenTimeLimit(!isDropdownOpenTimeLimit);
    };

    const handleOptionClickTimeLimit = (option) => {
        setIsDropdownOpenTimeLimit(false);
        setSelectedOptionTimeLimit(option);
    };

    const addQuestionClick = () => {
        setIsHide(true);
        setShowQuestion(true);
    }

    const addOptionClick = () => {
        setShowOption(true);
    }

    const handleFieldChange = (e, field) => {
        if (field === 'body') {
            setQuestionBody(e.target.value);
        }
        if (field === 'surveyName') {
            setSurveyName(e.target.value);
        }
        if (field === 'minPassingScore') {
            setMinPassingScore(e.target.value);
        }
        if (field === 'quizTime') {
            setQuizTime(e.target.value);
        }
        if (field === 'maxPoints') {
            setMaxPoints(e.target.value);
        }
        if (field === 'optionName') {
            setOptionName(e.target.value);
        }
    }

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={'SUCCESS'}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={'0px'}
                left={'0'}
            />
            {!showAllDoneFlag && (
                <GeneralContainer>
                    <Typography variant="PinkHeading" margin={`0 0 0 15rem`}>
                        ADD SURVEY
                    </Typography>

                    <Card>
                        <SurveyTable>
                            <TableRow>
                                <TableColumn>
                                    <Label>Survey Name</Label>
                                    <InputBox
                                        type="text"
                                        id="surveyName"
                                        value={surveyName}
                                        placeholder="Enter survey name/title"
                                        onChange={(e) => handleFieldChange(e, 'surveyName')}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Body</Label>
                                    <InputBox
                                        type="text"
                                        id="body"
                                        value={questionBody}
                                        placeholder="Enter question body"
                                        onChange={(e) => handleFieldChange(e, 'body')}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Anonymous?</Label>
                                    <SurveyDropdown
                                        options={options}
                                        selectedOption={selectedOptionAnonymous}
                                        onOptionSelect={handleOptionClickAnonymous}
                                        toggleDropdown={toggleDropdownAnonymous}
                                        isDropdownOpen={isDropdownOpenAnonymous}
                                        title="Choose Options"
                                        trueFalseOptions={'true'}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Must be Finished?</Label>
                                    <SurveyDropdown
                                        options={options}
                                        selectedOption={selectedOptionMustFinish}
                                        onOptionSelect={handleOptionClickMustFinish}
                                        toggleDropdown={toggleDropdownMustFinish}
                                        isDropdownOpen={isDropdownOpenMustFinish}
                                        title="Choose Options"
                                        trueFalseOptions={'true'}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Previous Question Allowed?</Label>
                                    <SurveyDropdown
                                        options={options}
                                        selectedOption={selectedOptionPrvAllowed}
                                        onOptionSelect={handleOptionClickPrvAllowed}
                                        toggleDropdown={toggleDropdownPrvAllowed}
                                        isDropdownOpen={isDropdownOpenPrvAllowed}
                                        title="Choose Options"
                                        trueFalseOptions={'true'}
                                    />
                                </TableColumn>
                            </TableRow>
                            
                            <TableRow>
                                <TableColumn>
                                    <Label>Style Type</Label>
                                    <SurveyDropdown
                                        options={styleOptionsData}
                                        selectedOption={selectedOptionStyle}
                                        onOptionSelect={handleOptionClickStyle}
                                        toggleDropdown={toggleDropdownStyle}
                                        isDropdownOpen={isDropdownOpenStyle}
                                        title="Select Style Type"
                                        trueFalseOptions={'true'}
                                    />
                                </TableColumn>
                            </TableRow>
                            
                            <TableRow>
                                <TableColumn>
                                    <Label>Form Type</Label>
                                    <SurveyDropdown
                                        options={optionsData}
                                        selectedOption={selectedOptionType}
                                        onOptionSelect={handleOptionClickType}
                                        toggleDropdown={toggleDropdownType}
                                        isDropdownOpen={isDropdownOpenType}
                                        title="Select Form Type"
                                        trueFalseOptions={'false'}
                                    />
                                </TableColumn>
                            </TableRow>

                            {/* quiz_options if type is "quiz" */}
                            {quizOptionHide && (
                            <>
                                <Typography variant="h2PurpleHeading" fontSize={'24px'} margin={`1rem 0 1rem 0`}>
                                    Add Quiz Options
                                </Typography>
                            
                                <TableRow>
                                    <TableColumn>
                                        <Label>Instant Feedback?</Label>
                                        <SurveyDropdown
                                            options={options}
                                            selectedOption={selectedOptionFeedback}
                                            onOptionSelect={handleOptionClickFeedback}
                                            toggleDropdown={toggleDropdownFeedback}
                                            isDropdownOpen={isDropdownOpenFeedback}
                                            title="Choose Options"
                                            trueFalseOptions={'false'}
                                        />
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Random Question Pool?</Label>
                                        <SurveyDropdown
                                            options={options}
                                            selectedOption={selectedOptionRdmQuePool}
                                            onOptionSelect={handleOptionClickRdmQuePool}
                                            toggleDropdown={toggleDropdownRdmQuePool}
                                            isDropdownOpen={isDropdownOpenRdmQuePool}
                                            title="Choose Options"
                                            trueFalseOptions={'false'}
                                        />
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Time Limit?</Label>
                                        <SurveyDropdown
                                            options={options}
                                            selectedOption={selectedOptionTimeLimit}
                                            onOptionSelect={handleOptionClickTimeLimit}
                                            toggleDropdown={toggleDropdownTimeLimit}
                                            isDropdownOpen={isDropdownOpenTimeLimit}
                                            title="Choose Options"
                                            trueFalseOptions={'false'}
                                        />
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Minimum Passing Score</Label>
                                        <InputBox
                                            type="number"
                                            id="minPassingScore"
                                            value={minPassingScore}
                                            placeholder="Enter minimum passing score (0-100)"
                                            onChange={(e) => handleFieldChange(e, 'minPassingScore')}
                                        />
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Quiz Time</Label>
                                        <InputBox
                                            type="number"
                                            id="quizTime"
                                            value={quizTime}
                                            placeholder="Enter quiz time in seconds (0-3600)"
                                            onChange={(e) => handleFieldChange(e, 'quizTime')}
                                        />
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Maximum Points</Label>
                                        <InputBox
                                            type="number"
                                            id="maxPoints"
                                            value={maxPoints}
                                            placeholder="Enter maximum points for the quiz(0-1000)"
                                            onChange={(e) => handleFieldChange(e, 'maxPoints')}
                                        />
                                    </TableColumn>
                                </TableRow>
                            </>
                            )}

                            {!isHide && (
                            <TableRow>
                                <TableColumn>
                                    <Button
                                        variant={'OutlinedButton'}
                                        buttonWidth={'100%'}
                                        disabled={successMessageBanner !== ''}
                                        label={'ADD QUESTION SET'}
                                        onClick={addQuestionClick}
                                    />
                                </TableColumn>
                            </TableRow>
                            )}         
                        </SurveyTable>  

                        {/* Add Question Part */}
                        {questionSets.map((set, index) => (
                            <div key={set.id}>
                            {showQuestion && (
                                <Typography variant="h2PurpleHeading" fontSize={'24px'} margin={`2rem 0 2rem 0`}>
                                {isAddMoreQuestion ? 'Add More Question' : 'Add Question'}
                                </Typography>
                            )}

                            {showQuestion && (
                                <AddSurveyQuestion
                                    getQuestionsData={(data) => handleFinalQuestionsData(data, set.id)}
                                    addOptionClick={addOptionClick}
                                />
                            )}

                            {/* Add Question's Option Part */}
                            <SecondPart>
                                {showOption && (
                                    <Typography variant="h2PurpleHeading" fontSize={'24px'} margin={`0 0 0 0`}>
                                        Add Options/Answers
                                    </Typography>
                                )}
                                {showOption && (
                                    <AddSurveyOptions
                                        getOptionsData={(data) => handleFinalOptionsData(data, set.id)}
                                        // getOptionsData={handleFinalOptionsData}
                                    />
                                )}
                            </SecondPart>
                            </div>
                        ))}

                        <MessageError>{validationError}</MessageError>

                        {finalOptionsSurveysData.length !== 0 && (
                            <TableRow>
                                <TableColumn>
                                    <Button
                                        variant={'OutlinedButton'}
                                        buttonWidth={'100%'}
                                        label={'Add NEW QUESTION & OPTION'}
                                        margin={'1rem 0 0 0'}
                                        onClick={handleAddNewQuestionAndOption}
                                    />  
                                </TableColumn>
                            </TableRow>
                        )}

                        <TableRow>
                            <Button
                                variant={'PinkButton'}
                                buttonWidth={'100%'}
                                // disabled={validationError === ''}
                                label={'Review & Submit Survey'}
                                margin={'2rem 0 0 0'}
                                onClick={ReviewSurveyClick}
                            />  
                        </TableRow>
                    </Card>
                </GeneralContainer>
            )}

            {showAllDoneFlag && (
                <GeneralContainer>
                    <Typography variant="pWhite" fontSize={`30px`} margin={`0 0 0 0`}>
                        Are you sure you want to add this survey?  Review the details below.
                    </Typography>
                    <Card>
                        <SurveyTable>
                            <TableRow>
                                <TableColumn>
                                    <TitleText>
                                        Form Details:
                                    </TitleText>
                                </TableColumn>

                                <TableColumn>
                                    <TitleText>
                                        Survey Name: {surveyName}
                                    </TitleText>
                                    <TitleText>
                                        Question Body: {questionBody}
                                    </TitleText>
                                    <TitleText>
                                        Form Type: {selectedOptionType}
                                    </TitleText>
                                    <TitleText>
                                        Style Type: {selectedOptionStyle}
                                    </TitleText>
                                    <TitleText>
                                        Anonymous: {selectedOptionAnonymous}
                                    </TitleText>
                                    <TitleText>
                                        Must Finish: {selectedOptionMustFinish}
                                    </TitleText>
                                    <TitleText>
                                        Previous Question Allowed: {selectedOptionPrvAllowed}
                                    </TitleText>
                                    </TableColumn>
                                </TableRow>
                                {selectedOptionType === 1 && (
                                    <Line />
                                )}
                                <TableRow>
                                    {selectedOptionType === 1 && (
                                    <>
                                    <TableColumn>
                                        <TitleText>
                                            Quiz Options:
                                        </TitleText>
                                    </TableColumn>  
                                    <TableColumn>
                                        <TitleText>
                                            Instant Feedback: {selectedOptionFeedback}
                                        </TitleText>
                                        <TitleText>
                                            Random Question Pool: {selectedOptionRdmQuePool}
                                        </TitleText>
                                        <TitleText>
                                            Time Limit: {selectedOptionTimeLimit}
                                        </TitleText>
                                        <TitleText>
                                            Minimum Passing Score: {minPassingScore}
                                        </TitleText>
                                        <TitleText>
                                            Quiz Time: {quizTime}
                                        </TitleText>
                                        <TitleText>
                                            Maximum Points: {maxPoints}
                                        </TitleText>
                                    </TableColumn>
                                    </>
                                    )}
                            </TableRow>
                            <Line />

                            <TableRow>
                                <TableColumn>
                                    <TitleText>
                                        Total Question Sets: {questionSets.length}
                                    </TitleText>
                                </TableColumn>
                            </TableRow>
                            <Line />
                            {questionSets.map((set, index) => (
                                <div key={set.id}>
                                    <TableRow>
                                        <TableColumn>
                                            <TitleText>
                                                Question: {index+1}
                                            </TitleText>
                                        </TableColumn>
                                        <TableColumn>
                                        {set.questionData.map((option, index) => (
                                            <React.Fragment key={index}>
                                            <TitleText>
                                                Question Name: {option.name}
                                                </TitleText>
                                            <TitleText>
                                                Question Body: {option.body}
                                            </TitleText>
                                            <TitleText>
                                                Question Type: {option.formQuestionType}
                                            </TitleText>
                                            </React.Fragment>
                                        ))}
                                        </TableColumn>
                                    </TableRow>

                                    <TableRow>
                                        <TableColumn>
                                            <TitleText>
                                                Option/s:
                                            </TitleText>
                                        </TableColumn>
                                        <TableColumn>
                                        {set.optionData.map((option, index) => (
                                            <React.Fragment key={index}>
                                                <TitleText>
                                                    Option Name: {option.name}
                                                </TitleText>
                                                <TitleText>
                                                    Correct or Not: {option.isCorrect}
                                                </TitleText>
                                                <TitleText>
                                                    Points: {option.points}
                                                </TitleText>
                                            </React.Fragment>
                                        ))}
                                        </TableColumn>
                                    </TableRow>
                                    <Line />
                                </div>
                            ))}
                        </SurveyTable>
                        <TableRow>
                            <Button
                                variant={'PinkButton'}
                                buttonWidth={'100%'}
                                // disabled={validationError === ''}
                                label={'Submit Survey'}
                                margin={'2rem 0 0 0'}
                                onClick={AddSurveyClick}
                            />  
                        </TableRow>
                    </Card>
                </GeneralContainer>
            )}
        </>
    );
};

export default AddSurvey;

const TitleText = styled.p`
    font-family: 'Barlow-Regular', Helvetica;
    color: #767676;
    font-weight: 500;
`;
const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const SurveyTable = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const SecondPart = styled.div`
    background: ${setColor.passwordValidBox};
`;

const QuestionTable = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;


const DropdownButton = styled.button`
    display: flex;
    font-family: 'Barlow-Regular', Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;

const Line = styled.hr`
    border-bottom: 0.5px solid ${setColor.grey1};
    margin: 10px 0;
`;

const MessageError = styled.p`
    color: ${setColor.warning};
    font-size: 16px;
    font-weight: 600;
    margin: 1rem 0 0 0;
`;