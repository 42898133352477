import axiosInstance from '../config/axiosConfig';
import { checkPermission } from '../utils/generalUtils';

export async function getAllRoles() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getPermissionsAndCheckAccess(role, permissionName) {
    try {
        const respPermissions = await axiosInstance.get(
            `${process.env.REACT_APP_CORE_API_URI}/web/permissions/role-name/${role}`
        );
        const permissions = [];
        for (let i = 0; i < respPermissions.data.permissions.length; i += 1) {
            permissions.push(respPermissions.data.permissions[i].name);
        }
        return checkPermission([permissionName], permissions);
    } catch (err) {
        return err;
    }
}
