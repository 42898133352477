import storage from 'redux-persist/lib/storage'; // Import storage
import { refreshAccessToken } from './actions/UserActions';

const isTokenExpired = (expiration) => {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
    return currentTimestamp >= expiration;
};

export const tokenMiddleware = () => (next) => async (action) => {
    // Retrieve persisted user data from storage
    const persistedUserDataString = await storage.getItem('persist:user');
    const persistedUserData = JSON.parse(persistedUserDataString);

    const userString = persistedUserData?.user;
    const userData = userString ? JSON.parse(userString) : null;

    // Extract expiration and userId
    const tokenExp = userData?.expiration || 0;
    const userId = userData?.userId || '';

    // check if the token is valid
    if (userId && tokenExp !== 0 && isTokenExpired(tokenExp)) {
        await refreshAccessToken(userId);
    }
    next(action);
};
