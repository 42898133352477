import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchVanityLinkInfo } from '../../../redux/slices/vanityLinks';
import ModalContainer from '../../../components/layout/ui/ModalContainer';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import { sendVagonMessage } from '../../vagon/helpers/utils';
import * as constants from '../../../constants';
import { getBroadcastingPermissions } from '../services/BroadcastController';

const BroadCasterModal = (props) => {
    const vanityLinkInfo = useSelector(fetchVanityLinkInfo);
    const [openBanner, setOpenBanner] = useState(props.broadCaster ? 'MESSAGE' : '');
    const [password, setPassword] = useState('');
    const [broadcastTitle, setBroadcastTitle] = useState('Broadcast your thoughts');
    const [broadcastDescription, setBroadcastDescription] = useState(
        'Enter the password to start or stop broadcasting to all guests in this session.'
    );
    const [broadcastInputPlaceholder, setBroadcastInputPlaceholder] = useState('Enter Password');
    const [broadcastNavTitle, setBroadcastNavTitle] = useState('Podium Panel');

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('submit-button').click();
        }
    };

    useEffect(() => {
        if (
            vanityLinkInfo.freeformJson &&
            vanityLinkInfo.freeformJson.podiumPanel !== null &&
            vanityLinkInfo.freeformJson.podiumPanel !== undefined
        ) {
            console.log(vanityLinkInfo.freeformJson);
            setBroadcastTitle(vanityLinkInfo.freeformJson.podiumPanel.title);
            setBroadcastDescription(vanityLinkInfo.freeformJson.podiumPanel.description);
            setBroadcastInputPlaceholder(vanityLinkInfo.freeformJson.podiumPanel.inputPlaceholder);
            setBroadcastNavTitle(vanityLinkInfo.freeformJson.podiumPanel.navTitle);
        }
    }, [vanityLinkInfo]);

    const fetchBroadcastPermissions = () => {
        setOpenBanner('');

        getBroadcastingPermissions(props.roomName, password)
            .then((res) => {
                if (res) {
                    localStorage.setItem('currentIdentity', res.identity);
                    props.setBroadCaster(true);
                    setOpenBanner('MESSAGE');
                    setPassword('');
                    return startBroadcast(res.token);
                }

                return setOpenBanner('ERROR');
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const startBroadcast = async (token) => {
        const audioStream = await getMedia();
        const audioTrack = new window.LivekitClient.LocalAudioTrack(audioStream.getAudioTracks()[0]);

        const room = new window.LivekitClient.Room();
        await room.connect(process.env.REACT_APP_BROADCAST_ROOM_ID, token);
        room.localParticipant.publishTrack(audioTrack);
        props.setRoom(room);
    };

    const getMedia = async () => {
        let audioContextValue = props.audioContext;
        let streamDestinationValue = props.streamDestination;
        try {
            if (!audioContextValue) {
                const desiredSampleRate = 96000;
                const audioContextOptions = {
                    sampleRate: desiredSampleRate,
                };
                const newAudioContext = new AudioContext(audioContextOptions);
                audioContextValue = newAudioContext;
                props.setAudioContext(newAudioContext);
            }
            if (!streamDestinationValue) {
                streamDestinationValue = audioContextValue.createMediaStreamDestination();
                props.setStreamDestination(streamDestinationValue);
            }

            const micStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            const voiceInput = audioContextValue.createMediaStreamSource(micStream);
            voiceInput.connect(streamDestinationValue);

            return streamDestinationValue.stream;
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <ModalContainer
                header={
                    <Header
                        functionLogic={() => {
                            sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                            props.setOpenBroadcastModal(false);
                        }}
                        title={broadcastNavTitle}
                    />
                }
                body={
                    <Body
                        openBanner={openBanner}
                        setOpenBanner={setOpenBanner}
                        password={password}
                        setPassword={setPassword}
                        broadCaster={props.broadCaster}
                        handleInputKeyDown={handleInputKeyDown}
                        broadcastTitle={broadcastTitle}
                        broadcastDescription={broadcastDescription}
                        broadcastInputPlaceholder={broadcastInputPlaceholder}
                    />
                }
                footer={
                    <Footer
                        buttonFunction={
                            props.broadCaster
                                ? () => {
                                      props.disconnect();
                                      setOpenBanner('');
                                  }
                                : () => fetchBroadcastPermissions()
                        }
                        value={props.broadCaster ? props.broadCaster : password}
                        broadCaster={props.broadCaster}
                    />
                }
            />
        </>
    );
};
export default BroadCasterModal;
