import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const AmbientSound = (props) => {
    const audioRef = useRef(null);

    useEffect(() => {
        if (props.play) {
            playAudio();
        } else {
            pauseAudio();
        }
    }, [props.play]);

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
            audioRef.current.volume = 0.07;
        }
    };

    const pauseAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
    };

    return (
        <audio ref={audioRef} loop>
            <source src={props.source} type="audio/mpeg" />
            <track kind="captions" src="" label="English" default />
        </audio>
    );
};

AmbientSound.propTypes = {
    source: PropTypes.string.isRequired,
    play: PropTypes.bool.isRequired,
};

export default AmbientSound;
