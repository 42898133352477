import { store } from '../Store';

export const SaveMerchant = (merchantName, merchantId, merchantAddress, merchantBusinessType, stripeClientId) => ({
    type: 'MERCHANT_SAVE',
    payload: {
        merchantName,
        merchantId,
        merchantAddress,
        merchantBusinessType,
        stripeClientId,
    },
});

export const UpdateMerchantStripeId = (stripeClientId) => ({
    type: 'UPDATE_MERCHANT_STRIPE_ID',
    payload: {
        stripeClientId,
    },
});

export const DeleteMerchant = () => {
    store.dispatch({ type: 'DELETE_MERCHANT' });
};
