import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { setColor } from '../../../../../../assets/styles/styles';
import { default as Button } from '../../../../../../components/common/button/Button';
import Typography from '../../../../../../components/common/text/Typography';
import Banner from '../../../../../../components/layout/ui/Banner';
import { getFormQuestionTypes, updateQuestion } from '../../api/surveysController';
import { ReactComponent as DropdownIcon } from '../../../../../../assets/images/icons/Chevron.svg';

const EditQuestion = ({ data, surveyId, closeEditModal }) => {
    const [optionsData, setOptionsData] = useState([]);
    const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
    const [selectedOptionType, setSelectedOptionType] = useState(data.type);
    const [successMessageBanner, setSuccessMessageBanner] = useState('');
    const [questionBody, setQuestionBody] = useState(data.body);
    const [questionTitle, setQuestionTitle] = useState(data.name);
    const [hasChanges, setHasChanges] = useState(false);

    const updateQuestionClick = async () => {
        try {
            let formQuestionTypeId;
            const selectedOptionTypeStr = String(selectedOptionType);

            if (selectedOptionTypeStr.includes('-')) {
                const splitText = selectedOptionTypeStr.split('-');
                formQuestionTypeId = splitText[0].trim();
            } else {
                formQuestionTypeId = selectedOptionTypeStr.trim();
            }

            const response = await updateQuestion(surveyId, data.id, questionBody, questionTitle, formQuestionTypeId);
            if (response.status === 200) {
                setSuccessMessageBanner(`${response.data.message}. Redirecting to the survey question page in 3 seconds...`);
            }
        } catch (error) {
            console.error('Error updating question:', error);
        }
    };

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeEditModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    const fetchData = async () => {
        try {
            const response = await getFormQuestionTypes();
            if (response.status === 200) {
                const data = response.data.questionTypes;
                setOptionsData(data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
        
    const toggleDropdownType = () => {
        setIsDropdownOpenType(!isDropdownOpenType);
    };

    const handleOptionClickType = (option) => {
        setIsDropdownOpenType(false);
        setSelectedOptionType(option);
    };

    const handleFieldChange = (e, field) => {
        setHasChanges(true);
        if (field === 'body') {
            setQuestionBody(e.target.value);
        }
        if (field === 'title') {
            setQuestionTitle(e.target.value);
        }
    }

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={'SUCCESS'}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={'0px'}
                left={'0'}
            />
            <GeneralContainer>
                <Typography variant="h2PurpleHeading" margin={'0 0 0 0'}>
                    EDIT QUESTION
                </Typography>
                <Card>
                    <Table>
                        <TableRow>
                            <TableColumn>
                                <Label>Question ID</Label>
                                <InputBox
                                    type="text"
                                    id="question_id"
                                    value={data.id}
                                    disabled
                                />
                            </TableColumn>
                        </TableRow>

                        <TableRow>
                            <TableColumn>
                                <Label>Question</Label>
                                <InputBox
                                    type="text"
                                    id="body"
                                    value={questionBody}
                                    onChange={(e) => handleFieldChange(e, 'body')}
                                />
                            </TableColumn>
                        </TableRow>

                        <TableRow>
                            <TableColumn>
                                <Label>Title</Label>
                                <InputBox
                                    type="text"
                                    id="title"
                                    value={questionTitle}
                                    onChange={(e) => handleFieldChange(e, 'title')}
                                />
                            </TableColumn>
                        </TableRow>

                        <TableRow>
                            <TableColumn>
                                <Label>Type</Label>
                                <DropdownButton onClick={toggleDropdownType} isopen={isDropdownOpenType.toString()}>
                                    {selectedOptionType === null ? 'Select Type' : selectedOptionType}
                                    <StyledDropdownIcon isopen={isDropdownOpenType.toString()} />
                                    {isDropdownOpenType && (
                                        <DropdownContent>   
                                            {optionsData.map((option) => (
                                                <DropdownItem
                                                    key={option.formQuestionTypeId}
                                                    onClick={() => handleOptionClickType(option.formQuestionTypeId)}
                                                    isSelected={selectedOptionType === option.formQuestionTypeId}
                                                >
                                                    {option.formQuestionTypeId} - {option.formQuestionTypeName}
                                                </DropdownItem>
                                            ))}
                                        </DropdownContent>
                                    )}
                                </DropdownButton>
                            </TableColumn>
                        </TableRow>

                        <TableRow>
                            <Button
                                variant={'PinkButton'}
                                buttonWidth={'100%'}
                                disabled={successMessageBanner !== ''}
                                label={'EDIT QUESTION'}
                                onClick={updateQuestionClick}
                            />  
                        </TableRow>
                    </Table>
                </Card>
            </GeneralContainer>
        </>
    );
};

export default EditQuestion;


const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;


const DropdownButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;
