import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { setColor, setFont } from '../../../assets/styles/styles';

function ModalContainer({ header, body, footer }) {
    return (
        <Container>
            {header}
            {body}
            {footer}
        </Container>
    );
}
export default ModalContainer;

ModalContainer.propTypes = {
    header: PropTypes.any,
    body: PropTypes.any,
    footer: PropTypes.any,
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${setColor.darkBlue};
    backdrop-filter: blur(17.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
`;
