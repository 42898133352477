import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/common/button/Button';
import Typography from '../../../components/common/text/Typography';
import { setColor } from '../../../assets/styles/styles';
import { isValidEmail, hexToRGBA } from '../../../utils/generalUtils';
import { EMAIL_ERROR_MESSAGE } from '../../../constants';
import { Input } from '../../../components/common/input/Input';
import { ReactComponent as PurpleDot } from '../../console/user/wallet/images/purple_dot.svg';
import { sendMailChimpEmail } from '../../../api/MailchimpController';

const EmailInputModal = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState(EMAIL_ERROR_MESSAGE);

    const updateEmail = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setEmail(value);
    };

    const submitEmail = async (e) => {
        setEmailError(false);

        e.preventDefault();
        if (!isValidEmail(email)) {
            return setEmailError(true);
        }

        const result = await sendMailChimpEmail(email);

        if (result) {
            setSuccess(true);
        } else {
            setEmailError(true);
            setEmailErrorMessage('There was a problem with subscribing, please try again.');
        }

        setTimeout(() => {
            setEmailError(false);
            setEmailErrorMessage(EMAIL_ERROR_MESSAGE);
            setShowEmailForm(false);
            setSuccess(false);
            setEmail('');
        }, 5000);
    };

    return (
        <div>
            <Typography variant="h2PurpleHeading" margin={'0'}>
                Join Us
            </Typography>
            <Typography variant="pWhite" margin={'30px 0'}>
                Enter your email to be the first to know when our beta launches!
            </Typography>
            <Card>
                <BulletPointRow>
                    <IconHolder>
                        <PurpleDot />
                    </IconHolder>
                    <Typography
                        variant="pWhite"
                        margin={'0'}
                        fontSize={'18px'}
                        color={`${setColor.circularProgress}`}
                        align={'left'}
                    >
                        Get exclusive updates
                    </Typography>
                </BulletPointRow>
                <br />
                <BulletPointRow>
                    <IconHolder>
                        <PurpleDot />
                    </IconHolder>
                    <Typography
                        variant="pWhite"
                        margin={'0'}
                        fontSize={'18px'}
                        color={`${setColor.circularProgress}`}
                        align={'left'}
                    >
                        Contribute to the development of app features
                    </Typography>
                </BulletPointRow>
                <br />
                <BulletPointRow>
                    <IconHolder>
                        <PurpleDot />
                    </IconHolder>
                    <Typography
                        variant="pWhite"
                        margin={'0'}
                        fontSize={'18px'}
                        color={`${setColor.circularProgress}`}
                        align={'left'}
                    >
                        Reach your favorite creators faster
                    </Typography>
                </BulletPointRow>
            </Card>
            <ContentContainer>
                <EmailInputContainer>
                    <Input
                        variant="LoginInput"
                        type="email"
                        placeholder="What's your email"
                        value={email}
                        onChangeHandler={(e) => updateEmail(e)}
                        error={emailError}
                        success={success}
                        errorMessage={emailError ? emailErrorMessage : ''}
                        style={{ marginBottom: '25px' }}
                    />
                </EmailInputContainer>
                <Button
                    variant="PinkButton"
                    label={success ? 'Email Submitted' : 'Get Early Access'}
                    onClick={submitEmail}
                    style={{ ...(success && { background: '#166443' }), marginTop: '15px' }}
                    disabled={success}
                />
            </ContentContainer>
        </div>
    );
};

export default EmailInputModal;

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const EmailInputContainer = styled.div`
    width: 100%;
`;

const BulletPointRow = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
`;

const Card = styled.div`
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    background: ${hexToRGBA(setColor.accent4, 0.1)};
    margin: 2rem auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const IconHolder = styled.div`
    display: flex;
    padding-top: 6px;
    min-width: 30px;
    justify-content: space-evenly;
`;
