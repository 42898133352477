import React, { useState } from 'react';
import styled from 'styled-components';
import { setColor } from '../../../../assets/styles/styles';
import Typography from '../../../../components/common/text/Typography';
import Button from '../../../../components/common/button/Button';
import { device } from '../../../../assets/styles/mediaBreakpoints';
import Modal from '../../../console/components/Modal';
import EmailInputModal from '../../components/EmailInputModal';
import { isMobileDevice } from '../../../../utils/generalUtils';

const FeatureSection = () => {
    const isMobile = isMobileDevice();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Modal isOpen={openModal} setIsOpen={setOpenModal} children={<EmailInputModal />} />
            <SectionBackground>
                <SectionContainer>
                    <Feature>
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                Customized {isMobile ? <br /> : null} <Highlighted>For You</Highlighted>
                            </StyledTypographyTitle>
                            <StyledTypographyDescription variant="pWhite">
                                Becoming a creator gives you the power to create a{' '}
                                <HighlightedDescription>custom avatar and environment</HighlightedDescription> built
                                just <HighlightedDescription>for you.</HighlightedDescription> A perfect way to showcase
                                your brand.
                            </StyledTypographyDescription>
                            <Button
                                variant="VanityLinkCustomizable"
                                label="Design My Space"
                                onClick={() => setOpenModal(true)}
                                style={{ borderRadius: '10px', width: 'fit-content' }}
                            />
                        </FeatureTextContainer>
                        <FeatureImageContainer>
                            <FeatureVideo muted autoPlay loop>
                                <source type="video/mp4" src={'/videos/landingPages/Pro_Customized_For_You.mp4'} />
                            </FeatureVideo>
                        </FeatureImageContainer>
                    </Feature>
                    <Feature>
                        {!isMobile && (
                            <FeatureImageContainer>
                                <FeatureVideo muted autoPlay loop>
                                    <source type="video/mp4" src={'/videos/landingPages/Pro_Sell_Your_Products.mp4'} />
                                </FeatureVideo>
                            </FeatureImageContainer>
                        )}
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                <Highlighted>Sell</Highlighted> Your Products
                            </StyledTypographyTitle>
                            <ProductStyledTypographyDescription variant="pWhite">
                                Want to sell products?
                            </ProductStyledTypographyDescription>
                            <ProductStyledTypographyDescription variant="pWhite">
                                <HighlightedDescription>Just upload a picture</HighlightedDescription>, connect a
                                payment gateway and{' '}
                                <HighlightedDescription>we'll handle the rest!</HighlightedDescription>
                            </ProductStyledTypographyDescription>
                            <ProductStyledTypographyDescription variant="pWhite">
                                We integrate with the most popular payment methods so you can generate revenue in no
                                time.
                            </ProductStyledTypographyDescription>
                            <Button
                                variant="VanityLinkCustomizable"
                                label="Start Selling"
                                onClick={() => setOpenModal(true)}
                                style={{ borderRadius: '10px', width: 'fit-content' }}
                            />
                        </FeatureTextContainer>
                        {isMobile && (
                            <FeatureImageContainer>
                                <FeatureVideo muted autoPlay loop>
                                    <source type="video/mp4" src={'/videos/landingPages/Pro_Sell_Your_Products.mp4'} />
                                </FeatureVideo>
                            </FeatureImageContainer>
                        )}
                    </Feature>
                    <Feature>
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                <Highlighted>Engage</Highlighted>
                                {isMobile ? <br /> : null} With Fans
                            </StyledTypographyTitle>
                            <StyledTypographyDescription variant="pWhite">
                                Beyond <HighlightedDescription>live broadcasts and chats</HighlightedDescription>, we
                                enhance the virtual experience with{' '}
                                <HighlightedDescription>gamified features</HighlightedDescription> like quizzes,
                                surveys, and quests to help you and your fans engage on a deeper, more intuitive level.
                            </StyledTypographyDescription>
                            <Button
                                variant="VanityLinkCustomizable"
                                label="Join The Fun"
                                onClick={() => setOpenModal(true)}
                                style={{ borderRadius: '10px', width: 'fit-content' }}
                            />
                        </FeatureTextContainer>
                        <FeatureImageContainer>
                            <FeatureVideo muted autoPlay loop>
                                <source type="video/mp4" src={'/videos/landingPages/Pro_Engage_With_Fans.mp4'} />
                            </FeatureVideo>
                        </FeatureImageContainer>
                    </Feature>
                    <Feature>
                        {!isMobile && (
                            <FeatureImageContainer>
                                <FeatureVideo muted autoPlay loop>
                                    <source type="video/mp4" src={'/videos/landingPages/Pro_Audience_Insights.mp4'} />
                                </FeatureVideo>
                            </FeatureImageContainer>
                        )}
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                Audience <Highlighted>Insights</Highlighted>
                            </StyledTypographyTitle>
                            <StyledTypographyDescription variant="pWhite" style={{ marginBottom: '10px' }}>
                                Gain insights into your community to make{' '}
                                <HighlightedDescription>smarter decisions</HighlightedDescription> and drive
                                improvement. To name a few, you can track: resonates with you
                            </StyledTypographyDescription>
                            <IconSection>
                                <ul style={{ paddingLeft: '15px' }}>
                                    <StyledTypographyDescription variant="pWhite" style={{ margin: '0' }}>
                                        <li>Total Users</li>
                                    </StyledTypographyDescription>
                                    <StyledTypographyDescription variant="pWhite" style={{ margin: '0' }}>
                                        <li>Average Session Time</li>
                                    </StyledTypographyDescription>
                                    <StyledTypographyDescription variant="pWhite" style={{ margin: '0' }}>
                                        <li>Interactable Stats</li>
                                    </StyledTypographyDescription>
                                </ul>
                                <MobileResponsiveUL>
                                    <StyledTypographyDescription variant="pWhite" style={{ margin: '0' }}>
                                        <li>Click-Through Rate</li>
                                    </StyledTypographyDescription>
                                    <StyledTypographyDescription variant="pWhite" style={{ margin: '0' }}>
                                        <li>Raycasting</li>
                                    </StyledTypographyDescription>
                                    <StyledTypographyDescription variant="pWhite" style={{ margin: '0' }}>
                                        <li>Heat Maps</li>
                                    </StyledTypographyDescription>
                                </MobileResponsiveUL>
                            </IconSection>
                            <Button
                                variant="VanityLinkCustomizable"
                                label="Learn More"
                                onClick={() => setOpenModal(true)}
                                style={{ borderRadius: '10px', width: 'fit-content' }}
                            />
                        </FeatureTextContainer>
                        {isMobile && (
                            <FeatureImageContainer>
                                <FeatureVideo muted autoPlay loop>
                                    <source type="video/mp4" src={'/videos/landingPages/Pro_Audience_Insights.mp4'} />
                                </FeatureVideo>
                            </FeatureImageContainer>
                        )}
                    </Feature>
                </SectionContainer>
            </SectionBackground>
        </>
    );
};

export default FeatureSection;

const MobileResponsiveUL = styled.ul`
    @media (max-width: 960px) {
        padding-left: 15px;
    }
`;

const StyledTypographyTitle = styled(Typography)`
    font-size: 3.2rem;
    text-align: left;

    @media (max-width: 960px) {
        text-align: center;
        margin-bottom: 0;
    }
`;

const StyledTypographyDescription = styled(Typography)`
    text-align: left;
    margin-top: 0;
    margin-left: 0;
    line-height: 1.6;
    font-size: 19px;

    @media (max-width: 960px) {
        text-align: center;
        font-size: 16px;
        margin-right: 0;
        margin: 20px 0;
    }
`;

const ProductStyledTypographyDescription = styled(Typography)`
    text-align: left;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 15px;
    font-size: 19px;

    @media (max-width: 960px) {
        text-align: center;
        font-size: 16px;
        margin-right: 0;
        margin: 10px 0;
    }
`;

const IconSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: 950px) {
        gap: 10px;
        grid-template-columns: 1fr; // 1 item per row on smaller screens
    }
`;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const HighlightedDescription = styled.span`
    color: ${setColor.yellowText};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0b0a14;
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 25px;

    @media ${device.desktopWide} {
        padding: 75px 0;
    }

    @media (max-width: 960px) {
        width: 90%;
        gap: 15px;
    }
`;

const Feature = styled.div`
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 950px) {
        flex-direction: column;
        height: auto;
    }
`;

const FeatureTextContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    @media (max-width: 950px) {
        width: 100%;
        align-items: center;
    }
`;

const FeatureImageContainer = styled.div`
    width: auto;
    height: 100%;

    @media (max-width: 960px) {
        width: 100%;
        justify-content: center;
        margin-left: 0 !important;
    }
`;

const FeatureVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: fill;

    @media (max-width: 960px) {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
`;
