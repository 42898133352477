/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import Collapsible from 'react-collapsible';
import { NavLink, MemoryRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setColor } from '../../../assets/styles/styles';
import useOnClickOutside from '../../../hooks/useOnClickOutside'; // adjust the path as needed
import { uniqueId } from 'lodash';

export function Dropdown({ items, title }) {
    const ref = useRef();
    useOnClickOutside(ref, () => setOpen(false));

    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [activeTitle, setActiveTitle] = useState(false);
    const [newSelection, setNewSelection] = useState(null);

    useEffect(() => {
        const currentItem = items.find((item) => item.route === location.pathname);
        if (currentItem) {
            setActiveTitle(true);
            setNewSelection(currentItem.value);
        } else {
            setActiveTitle(false);
            setNewSelection(null);
        }
    }, [location.pathname]);

    const handleToggle = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const handleOnClick = (item) => {
        setNewSelection(item.value);
        setOpen(false);
    };

    return (
        <div ref={ref}>
            <CollapsibleContainer
                open={open}
                tabIndex={0}
                trigger={
                    <DropdownHeader
                        open={open}
                        activeTitle={activeTitle}
                        tabIndex={0}
                        role="button"
                        onKeyPress={handleToggle}
                        onPointerDown={handleToggle}
                    >
                        <Title activeTitle={activeTitle} open={open}>
                            {title}
                        </Title>
                        <DDHeaderAction>
                            <IconContainer>
                                <StyledDropdownIcon
                                    activeTitle={activeTitle}
                                    open={open}
                                    viewBox="0 0 16 14"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="Chevron">
                                        <path
                                            id="Union"
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.07557 12.4883L6.68541 11.1324L0.92845 5.62934L0.476074 3.24517L2.89955 3.62403L8.0582 8.55093L13.1257 3.53794L13.2657 3.53794L15.5138 3.14933L15.1345 5.37003L15.1345 5.51324L8.07557 12.4883Z"
                                        />
                                    </g>
                                </StyledDropdownIcon>
                            </IconContainer>
                        </DDHeaderAction>
                    </DropdownHeader>
                }
            >
                <DropdownList open={open}>
                    {items.map((item) => (
                        <DropdownItem
                            to={item.route}
                            exact="true"
                            key={uniqueId()}
                            onClick={() => handleOnClick(item)}
                            isActive={newSelection === item.value}
                        >
                            {item.value}
                        </DropdownItem>
                    ))}
                </DropdownList>
            </CollapsibleContainer>
        </div>
    );
}

export default Dropdown;

Dropdown.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    selected: PropTypes.any,
    setSelected: PropTypes.any,
};

Dropdown.defaultProps = {
    items: [],
    iconHeight: '30px',
    iconWidth: '30px',
};

const Title = styled.h2`
    text-decoration: none;
    color: ${setColor.iconColor};
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0;

    ${(props) => {
        if (props.open || props.activeTitle) {
            return `
                color: ${setColor.accent4};
            `;
        }
    }}

    &.active {
        /* Style for active NavLink */
        color: ${setColor.accent4};
    }

    &:hover {
        color: ${setColor.accent4};
    }

    @media (max-width: 768px) {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-right: 25px;
    }
`;

const DropdownItem = styled(({ isActive, ...props }) => <NavLink {...props} />)`
    display: block;
    padding: 10px;

    cursor: pointer;
    text-decoration: none;
    color: ${setColor.iconColor};

    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 15px;

    white-space: nowrap; /* Prevent text from wrapping */

    ${(props) => {
        if (props.isActive) {
            return `
                background-color: ${setColor.iconColor3};
                color: ${setColor.inputBgColor};
            `;
        }
    }}

    &:hover {
        background-color: ${setColor.iconColor3};
        color: ${setColor.inputBgColor};
    }
`;

const StyledDropdownIcon = styled.svg`
    fill: ${(props) => (props.open || props.activeTitle ? `${setColor.accent4}` : `${setColor.iconColor}`)};
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    margin-left: 5px;
    transition: transform 0.3s;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownHeader = styled.div`
    display: inline-block;
    width: 125px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding-bottom: 20px;
    transition: all 0.15s linear;
    margin-right: 50px;

    ${(props) => {
        if (props.open || props.activeTitle) {
            return `
                border-bottom: 6px solid ${setColor.iconColor3};
            `;
        }
    }}

    @media (max-width: 768px) {
        padding: 7px 13px;
    }
`;

const DropdownList = styled.div`
    border: 2px solid #24242f;
    margin-top: 10px;
    border-radius: 5px;
    padding: 15px 15px 0 15px;
    background-color: ${setColor.primary};
    color: ${setColor.textPrimary};
    cursor: pointer;
    box-shadow: ${(props) => `0 0.125rem 0.25rem ${setColor.boxShadowColor} !important`};
    /* box-shadow: 0 0.125rem 0.25rem #00000013 !important; */
    width: fit-content;
    min-width: 215px;
    height: auto;
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: absolute; // Add this if not already positioned
    z-index: 9999; // Add this
`;

const DDHeaderAction = styled.div`
    font-size: 26px;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 0 0 0 15px;
    @media (max-width: 768px) {
        font-size: 15px !important;
    }
`;

const CollapsibleContainer = styled(Collapsible)`
    position: relative;
`;
