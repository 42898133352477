import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { setColor } from '../../../../assets/styles/styles';
import Typography from '../../../../components/common/text/Typography';
import Background from '../assets/backgrounds/UI_Website_BG_City.png';
import images from '../utils/ImportImages';
import { device } from '../../../../assets/styles/mediaBreakpoints';
import { isMobileDevice } from '../../../../utils/generalUtils';

const burlbTitles = ['Creators', 'Musicians', 'Artists', 'Streamers', 'Influencers', 'Brands'];

const GallerySection = () => {
    const isMobile = isMobileDevice();
    const [blurbIndex, setCurrentBlurbIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible(false);
            setTimeout(() => {
                setCurrentBlurbIndex((prevIndex) => (prevIndex + 1) % burlbTitles.length);
                setIsVisible(true);
            }, 1000);
        }, 2500);

        return () => clearInterval(interval);
    }, []);

    const currentFeature = burlbTitles[blurbIndex];

    return (
        <SectionBackground>
            <SectionContainer>
                <div>
                    <StyledTypography variant="LandingPageTitle">
                        {isMobile ? (
                            <>
                                A Universe with <br />
                                your Favorite <br />
                            </>
                        ) : (
                            'A Universe with your Favorite'
                        )}
                        <HighlightedTitle isVisible={isVisible}>{currentFeature}</HighlightedTitle>{' '}
                    </StyledTypography>
                    <StyledParagraphTypography variant="pWhite">
                        {isMobile ? (
                            <>
                                It is the{' '}
                                <HighlightedDescription>future of immersive social engagement</HighlightedDescription>{' '}
                                by offering creative freedom through customizable features, gamified rewards, and an
                                exciting community full of like-minded people!
                            </>
                        ) : (
                            <>
                                Intraverse is a 3D social platform innovating how fans can connect with creators.
                                Experience the
                                <HighlightedDescription> future of immersive social engagement </HighlightedDescription>
                                with personalized features, gamified rewards, and a vibrant community of like-minded
                                individuals.
                            </>
                        )}
                    </StyledParagraphTypography>
                </div>
                <GalleryDisplay>
                    <VideoContainer>
                        <FeatureVideo muted autoPlay loop>
                            <source
                                type="video/mp4"
                                src={'/videos/landingPages/Live_Showcase_Mindfulness_Garden.mp4'}
                            />
                        </FeatureVideo>
                    </VideoContainer>
                    <VideoContainer>
                        <FeatureVideo muted autoPlay loop>
                            <source type="video/mp4" src={'/videos/landingPages/Live_Showcase_Ric_Wilson.mp4'} />
                        </FeatureVideo>
                    </VideoContainer>
                    <VideoContainer>
                        <FeatureVideo muted autoPlay loop>
                            <source type="video/mp4" src={'/videos/landingPages/Live_Showcase_Teddy_Swims.mp4'} />
                        </FeatureVideo>
                    </VideoContainer>
                </GalleryDisplay>
                <div>
                    <Typography variant="LandingPageTSubtitle" style={{ marginBottom: '2rem' }}>
                        Our List of Growing <HighlightedSubTitle isVisible>Partners</HighlightedSubTitle>
                    </Typography>
                    <GalleryDisplayLogos>
                        {Object.keys(images).map((key, index) => (
                            <Image key={index} src={images[key]} alt={`logo-${index}`} />
                        ))}
                    </GalleryDisplayLogos>
                </div>
            </SectionContainer>
        </SectionBackground>
    );
};

export default GallerySection;

const FeatureVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledTypography = styled(Typography)`
    margin-right: 275px;

    @media (max-width: 960px) {
        margin-right: 0;
    }
`;

const StyledParagraphTypography = styled(Typography)`
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 19px;

    @media (max-width: 960px) {
        font-size: 16px;
        margin-bottom: 25px;
    }
`;

const HighlightedTitle = styled.span`
    color: ${setColor.accent4};
    animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 1s ease-in-out;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;
    position: absolute;
    margin-left: 15px;

    @media (max-width: 960px) {
        position: static;
        margin-left: 0;
    }
`;

const HighlightedSubTitle = styled.span`
    color: ${setColor.accent4};
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const HighlightedDescription = styled.span`
    color: ${setColor.yellowText};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:
        linear-gradient(rgba(27, 27, 35, 0.8), rgba(27, 27, 35, 0.8)),
        url(${Background}) no-repeat center/cover;

    @media (max-width: 950px) {
        height: auto;
    }
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media ${device.desktopWide} {
        padding: 75px 0;
    }

    @media (max-width: 960px) {
        width: 90%;
    }
`;

const GalleryDisplay = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    @media (max-width: 950px) {
        gap: 25px;
        flex-wrap: wrap;
        margin-bottom: 25px;
    }
`;

const GalleryDisplayLogos = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    @media (max-width: 950px) {
        gap: 5px;
        flex-wrap: wrap;
    }
`;

const VideoContainer = styled.div`
    width: 375px;
    height: 225px;
    border-radius: 10px;
    border: 4px solid rgba(190, 174, 255, 0.3);
    overflow: hidden;

    @media (max-width: 950px) {
        height: 175px;
    }
`;

const Image = styled.img`
    width: auto;
    height: 35px;
    margin: 0 20px;
`;
