import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createNPC, deleteNPC, getRoomNPCs, updateNPC } from '../../../../api/AdminController';
import Title from '../../components/Title';
import Button from '../../../../components/common/button/Button';
import { setColor, setFont } from '../../../../assets/styles/styles';
import Banner from '../../../../components/layout/ui/Banner';

const NPCDialogues = () => {
    const { roomId } = useParams();
    const token = useSelector((state) => state?.user?.token);

    const [openBanner, setOpenBanner] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');

    const [npcs, setNPCs] = useState([]);
    const [reload, setReload] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [dialogues, setDialogues] = useState([]);

    const fetchNPCDialogues = async () => {
        const response = await getRoomNPCs(token, roomId);
        console.log(response);
        if (response.status === 200) {
            console.log(response.data);
            setNPCs(response.data.npcs);
        } else {
            console.log('Error querying room npcs');
            setErrorMessage('Error querying room npcs');
            setOpenBanner('ERROR');
        }
    };

    const handleUpdate = async (npcIndex) => {
        const npcData = npcs[npcIndex];
        const response = await updateNPC(token, npcData);
        console.log(response);
        if (response.status === 200) {
            setBannerMessage('Success updating record');
            setOpenBanner('MESSAGE');
            setReload(!reload);
        } else {
            setErrorMessage('Error updating npc');
        }
    };

    const handleCreateNPC = async () => {
        const response = await createNPC(token, roomId);
        console.log(response);
        if (response.status === 201) {
            setBannerMessage('Success creating npc');
            setOpenBanner('MESSAGE');
            setReload(!reload);
        } else {
            setErrorMessage('Error creating NPC');
        }
    };

    const handleDeleteNPC = async (i) => {
        console.log(i);
        const npc = npcs[i];
        const response = await deleteNPC(token, npc.npcId);
        console.log(response);
        if (response.status === 204) {
            setBannerMessage('Success deleting record');
            setOpenBanner('MESSAGE');
            setReload(!reload);
        } else {
            setErrorMessage('Error deleting NPC');
            setBannerMessage('ERROR deleting record');
            setOpenBanner('ERROR');
        }
    };

    useEffect(() => {
        fetchNPCDialogues();
    }, [reload]);

    const handleInput = (e, i) => {
        const value = e.target.value;
        const key = e.target.name;
        const newNPCS = JSON.parse(JSON.stringify(npcs));
        console.log(newNPCS[i][key]);
        newNPCS[i][`${key}`] = value;
        setNPCs(newNPCS);
    };

    const handleDialogChange = (e, i, j) => {
        const value = e.target.value;
        const newNPCS = JSON.parse(JSON.stringify(npcs));
        console.log(e, j, i);
        console.log(newNPCS[i]);
        newNPCS[i].dialogue.text[j] = value;
        setNPCs(newNPCS);
    };

    const handleAddDialogue = (i, j) => {
        const newNPCS = JSON.parse(JSON.stringify(npcs));
        const length = newNPCS[i].dialogue.text.length;
        newNPCS[i].dialogue.text[length] = '';
        setNPCs(newNPCS);
    };

    const handleDeleteDialogues = (e, i, j) => {
        const newNPCS = JSON.parse(JSON.stringify(npcs));
        newNPCS[i].dialogue.text.splice(j, 1);
        setNPCs(newNPCS);
    };

    return (
        <div>
            <Title title={'NPC Dialogues'} description={'Create and manage npc dialogues.'} />
            <Button margin={'20px'} variant="PinkButton" label="Create NPC" onClick={() => handleCreateNPC()} />
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <NPCSContainer>
                {npcs.map((npc, i) => {
                    return (
                        <NPCCard>
                            <NPCGeneralInfo>
                                <RowInfo>
                                    <NPCPtag>NPC Name: </NPCPtag>
                                    <NPCInfoInput name="name" onInput={(e) => handleInput(e, i)} value={npc.name} />
                                </RowInfo>
                                <RowInfo>
                                    <NPCPtag>NPC Key: </NPCPtag>
                                    <NPCInfoInput name="key" onInput={(e) => handleInput(e, i)} value={npc.key} />
                                </RowInfo>
                                <RowInfo>
                                    <NPCPtag>Updated: </NPCPtag>
                                    <NPCInfoInput disabled value={npc.updatedAt} />
                                </RowInfo>
                                <RowInfo>
                                    <NPCPtag>Created: </NPCPtag>
                                    <NPCInfoInput disabled value={npc.createdAt} />
                                </RowInfo>
                            </NPCGeneralInfo>
                            <NPCDialoguesContainer>
                                {npc.dialogue.text.map((dialog, j) => {
                                    return (
                                        <RowInfo>
                                            <IndexDialogue>{j}</IndexDialogue>
                                            <NPCDialogueInput
                                                onInput={(e) => handleDialogChange(e, i, j)}
                                                value={dialog}
                                            />
                                            <DeleteDialogueButton onClick={(e) => handleDeleteDialogues(e, i, j)}>
                                                X
                                            </DeleteDialogueButton>
                                        </RowInfo>
                                    );
                                })}
                                <ButtonContainer>
                                    <Button
                                        exact
                                        onClick={(e, j) => handleUpdate(i)}
                                        label="Save"
                                        margin={'0'}
                                        fontSize={'14px'}
                                    />
                                    <Button
                                        exact
                                        onClick={(e, j) => handleAddDialogue(i, j)}
                                        label="Add"
                                        margin={'0'}
                                        fontSize={'14px'}
                                    />
                                    <Button
                                        exact
                                        onClick={() => handleDeleteNPC(i)}
                                        label="Delete NPC"
                                        margin={'0'}
                                        fontSize={'14px'}
                                    />
                                </ButtonContainer>
                            </NPCDialoguesContainer>
                        </NPCCard>
                    );
                })}
            </NPCSContainer>
            <Banner
                message={bannerMessage}
                type={'MESSAGE'}
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                topDisplay
                showCloseButton={false}
                timer={8000}
            />
        </div>
    );
};

const NPCInfoInput = styled.input`
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    height: 20px;
    width: 130px;
    align-self: flex-end;
`;

const DeleteDialogueButton = styled.button`
    background-color: red;
    height: 45px;
    margin-left: 5px;
    border-radius: 5px;
    margin-top: 0px;
    background-color: ${setColor.iconColor3};
`;

const IndexDialogue = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    font-weight: 800;
    color: ${setColor.boxContainerBg};
`;

const NPCPtag = styled.p`
    color: ${setColor.boxContainerBg};
`;

const NPCSContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const NPCCard = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 30px;
    margin-bottom: 20px;
    min-height: 200px;
    border-radius: 25px;
    border: 1px solid ${setColor.greyIcon};
`;

const NPCGeneralInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font: ${setFont.barlow};
    justify-content: center;
    width: 25%;
    background-color: ${setColor.passwordValidBox};
    padding: 30px;
`;

const NPCDialogueInput = styled.textarea`
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
`;

const NPCDialoguesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 75%;
    align-items: center;
`;

const RowInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    grid-gap: 15px;
`;

const ErrorMessage = styled.h3`
    color: red;
    font-size: 40px;
    margin: auto;
    text-align: center;
    width: 100%;
`;

export default NPCDialogues;
