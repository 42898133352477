import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRecordInfo, updateRecordDynamically } from '../../../../api/AdminController';
import Body from '../../layout/Body';
import Typography from '../../../../components/common/text/Typography';
import Button from '../../../../components/common/button/Button';
import Banner from '../../../../components/layout/ui/Banner';
import FilePreviewer from '../files/FilePreviewer';
import { getFileUrl } from '../../../../api/FilesController';
import { setColor } from '../../../../assets/styles/styles';

const RecordDetails = () => {
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [record, setRecord] = useState(null);
    const [error, setError] = useState(null);
    const [softDelete, setSoftDelete] = useState(false);
    const [openBanner, setOpenBanner] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');
    const [modalError, setModalError] = useState(false);

    const [isUploaderOn, setIsUploaderOn] = useState(false);
    const [fileUrl, setFileUrl] = useState('');

    const modalLabels = [
        'id',
        'original_name',
        'file_name',
        'created_at',
        'user_id',
        'file_type',
        'folder_name',
        'usage',
    ];

    const filePreviewerSettings = (table) => {
        const tablesWithPreviewer = ['rooms', 'avatar_presets', 'files'];
        return tablesWithPreviewer.includes(table);
    };

    const getFileColumns = (table) => {
        let fields;
        switch (table) {
            case 'rooms':
                fields = [
                    'image_explore_id',
                    'template_tile_image_key',
                    'image_community_id',
                    'image_trending_id',
                    'room_details_image_id',
                    'room_owner_image_id',
                ];
                break;
            case 'avatar_presets':
                fields = ['file_id'];
                break;
            case 'files':
                fields = ['id'];
                break;
            default:
                fields = [];
                break;
        }
        return fields;
    };

    const [modalInfo, setModalInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isJSONEditorOpen, setIsJSONEditorOpen] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const token = useSelector((state) => state?.user?.token);

    const id = searchParams.get('id');
    const tableName = searchParams.get('table');
    const primaryKey = searchParams.get('primary_key');
    const backUrl = searchParams.get('back_url');

    const filePreviewerButton = filePreviewerSettings(tableName);

    const fetchRecordData = async () => {
        const response = await getRecordInfo(token, tableName, id, primaryKey);
        if (response.status === 200) {
            setRecord(response.data.record);
            if (response.data.record.is_deleted === false) {
                setSoftDelete(true);
            }
            setColumns(response.data.columns);
            setLoading(false);
            return;
        }
        setLoading(false);
        setError(response.error);
    };

    const updateRecord = async (e) => {
        const column = e.target.name;
        let value = record[column];
        if (value === null || value === 'null' || value === 'NULL') {
            value = 'NULL';
        }
        let body = { column, value };
        if (typeof value === 'object') {
            body = { column, value: JSON.stringify(value) };
        }

        try {
            const response = await updateRecordDynamically(token, tableName, id, primaryKey, body);
            if (response.status === 200) {
                setBannerMessage('Success updating record');
                setOpenBanner('MESSAGE');
                fetchRecordData();
            }
            if (response.status === 400) {
                console.log(response);
                setBannerMessage('Error updating record');
                setOpenBanner('ERROR');
            }
        } catch (err) {
            console.log(err);
            setBannerMessage('Error updating record');
            setOpenBanner('ERROR');
        }
    };

    const deleteRecord = async (e) => {
        const body = { column: 'is_deleted', value: true };
        const response = await updateRecordDynamically(token, tableName, id, primaryKey, body);
        if (response.status === 200) {
            setBannerMessage('Success deleting record - Soft delete');
            setOpenBanner('MESSAGE');
            fetchRecordData();
        }
        if (response.status === 400) {
            setBannerMessage(`Error deleting record: ${response.response.data.message}`);
            setOpenBanner('MESSAGE');
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchRecordData();
    }, []);

    const handleChange = (e) => {
        const { value, name } = e.target;
        const copyRecord = { ...record, [name]: value };
        setRecord(copyRecord);
    };

    const handleHover = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.children.length === 0) {
            return;
        }
        if (e.target.children[0].children.length === 0) {
            return;
        }
        let fieldName = e.target.children[0].children[0].innerHTML;
        if (fieldName.includes('(disabled)')) {
            const splitStringArr = fieldName.split(' ');
            fieldName = splitStringArr[0];
        }
        fieldName = fieldName.trim();
        const fileColumns = getFileColumns(tableName);
        if (fileColumns.includes(fieldName)) {
            const fileId = e.target.children[0].children[1].innerHTML;
            if (fileId !== 'null') {
                handleModalInfo(fileId);
            }
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleModalInfo = async (id) => {
        if (isModalOpen) {
            const responseRecord = await getRecordInfo(token, 'files', id, 'id');

            if (responseRecord.status !== 200) {
                console.log('Error getting file record');
                return;
            }
            const row = responseRecord.data.record;

            setModalError(false);
            const newModalInfo = {
                ...row,
                fileTypeModal: false,
                fileImageUrl: false,
                fileLinkUrl: false,
            };

            const response = await getFileUrl(id, token);

            if (response.status === 200) {
                setFileUrl(response.data);
            } else {
                setModalError(true);
            }

            if (row.file_type.includes('image')) {
                newModalInfo.fileTypeModal = 'image';
                newModalInfo.fileImageUrl = 'www.image.com';
            } else {
                newModalInfo.fileTypeModal = 'other';
                newModalInfo.fileImageUrl = false;
                newModalInfo.fileLinkUrl = 'www.filelink.com';
            }
            setModalInfo(newModalInfo);
        }
    };

    return (
        <>
            <Typography variant="ConsoleTitle">Record Details</Typography>
            <Link to={!backUrl ? `/console/table/${tableName}` : backUrl}>Go back to schema</Link>
            <Typography variant="ConsoleDescription">Table: {tableName}</Typography>
            <Typography variant="ConsoleDescription">
                {primaryKey}: {id}
            </Typography>
            <Banner
                message={bannerMessage}
                type={'MESSAGE'}
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                topDisplay
                showCloseButton={false}
                timer={8000}
            />
            <Body isLoading={loading}>
                {isModalOpen ? (
                    <FilePreviewer
                        handleCloseModal={handleCloseModal}
                        setIsUploaderOn={setIsUploaderOn}
                        modalInfo={modalInfo}
                        fileUrl={fileUrl}
                        modalLabels={modalLabels}
                    />
                ) : (
                    <></>
                )}
                {isJSONEditorOpen ? (
                    <JSONEditorContainer>
                        <JSONEditorRow>
                            <JSONEditorButton onClick={(e) => setIsJSONEditorOpen(!isJSONEditorOpen)}>
                                Close
                            </JSONEditorButton>
                        </JSONEditorRow>
                        <iframe
                            title="json-editor"
                            id="JSON-iframe"
                            style={{ width: '100%', height: '100%' }}
                            src="https://json-gui.esstudio.site/"
                        />
                    </JSONEditorContainer>
                ) : (
                    <></>
                )}
                <ButtonHolder>
                    {filePreviewerButton ? (
                        <Button
                            exact
                            onClick={(e) => handleOpenModal(e)}
                            label="File Previewer"
                            margin={'0'}
                            fontSize={'14px'}
                        />
                    ) : (
                        <></>
                    )}
                    <Button
                        exact
                        onClick={(e) => setIsJSONEditorOpen(!isJSONEditorOpen)}
                        label="JSON Editor"
                        margin={'0'}
                        fontSize={'14px'}
                    />
                    {softDelete ? (
                        <Button
                            exact
                            onClick={(e) => deleteRecord(e)}
                            label="Delete Record"
                            variant="PinkButton"
                            margin={'0'}
                            fontSize={'14px'}
                        />
                    ) : (
                        <></>
                    )}
                    {tableName === 'rooms' ? (
                        <Link to={`/console/npc_dialogues/${id}`}>
                            <Button exact label="NPC Dialogues" variant="PinkButton" margin={'0'} fontSize={'14px'} />
                        </Link>
                    ) : (
                        <></>
                    )}
                </ButtonHolder>
                <FlexContainer>
                    {columns.map((column, i) => {
                        let value = record[column.column_name];
                        if (typeof record[column.column_name] === 'object') {
                            value = JSON.stringify(value);
                        }
                        const columnSpecs = Object.entries(column);
                        const disabledColumns = [
                            primaryKey,
                            'created_at',
                            'date_created',
                            'created_at',
                            'last_updated',
                        ];
                        const checkDisable = disabledColumns.includes(column.column_name);
                        return (
                            <RowGridContainer onMouseEnter={handleHover} key={`rowgrid-${column.column_name}-${i}`}>
                                <RowCell key={`rowcell-${column.column_name}-${i}`}>
                                    <Label key={`lable-${column.column_name}-${i}`}>
                                        {column.column_name} {checkDisable ? '     (disabled)' : ''}
                                    </Label>
                                    {column.data_type === 'boolean' ? (
                                        <StyledSelect
                                            key={`select-${column.column_name}-${i}`}
                                            value={value}
                                            name={column.column_name}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <option key={`option-${column.column_name}-true`} value="true">
                                                true
                                            </option>
                                            <option key={`option-${column.column_name}-false`} value="false">
                                                false
                                            </option>
                                            <option key={`option-${column.column_name}-null`} value="null">
                                                null
                                            </option>
                                        </StyledSelect>
                                    ) : (
                                        <Input
                                            disabled={checkDisable}
                                            key={`input-${column.column_name}-${i}`}
                                            value={value}
                                            name={column.column_name}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    )}
                                </RowCell>
                                <RowCell key={`rowcell2-${column.column_name}-${i}`}>
                                    {columnSpecs.map((entry) => {
                                        const key = entry[0];
                                        let value = entry[1];
                                        if (value === null) {
                                            value = 'NULL';
                                        }
                                        return (
                                            <div>
                                                <p>
                                                    <b>{key.toUpperCase()}: </b> {value}
                                                </p>
                                            </div>
                                        );
                                    })}
                                    <ButtonUpdate
                                        disabled={checkDisable}
                                        name={`${column.column_name}`}
                                        onClick={(e) => updateRecord(e)}
                                    >
                                        Update
                                    </ButtonUpdate>
                                </RowCell>
                            </RowGridContainer>
                        );
                    })}
                </FlexContainer>
            </Body>
        </>
    );
};

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-top: 50px;
    padding-bottom: 80px;
    grid-row-gap: 20px;
`;

const RowGridContainer = styled.div`
    display: grid;
    width: 100%;
    align-self: center;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
    font-size: 12px;
    color: white;
    padding: 10px;
    border-radius: 8px;
    &:hover {
        background-color: ${setColor.iconColor3};
        color: ${setColor.black};
    }
`;

const ButtonHolder = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
`;

const StyledSelect = styled.select`
    width: 150px;
`;

const ButtonUpdate = styled.button`
    width: 200px;
    margin-top: 5px;
    &:hover {
        background-color: ${setColor.iconColor3};
        color: ${setColor.black};
    }
`;

const Label = styled.p`
    font-size: 14px;
`;

const Input = styled.textarea`
    max-width: 80%;
    height: fit-content;
`;

const RowCell = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    justify-items: center;
    margin-top: 20px;
`;

const JSONEditorContainer = styled.div`
    position: absolute;
    width: 900px;
    height: 600px;
    top: 0;
    margin: 100px;
`;

const JSONEditorRow = styled.div`
    width: 100%;
    height: 70px;
    background-color: ${setColor.white};
    padding: 10px;
    display: flex;
    justify-content: flex-end;
`;

const JSONEditorButton = styled.button`
    height: 35px;
`;

export default RecordDetails;
