import React, { useState } from 'react';
import styled from '@emotion/styled';
import { setColor } from '../../../../../../assets/styles/styles';
import LoadingIcon from '../../../../../../components/common/loading/LoadingIcon';
import AddQuestion from './AddQuestion';
import AddSurvey from './AddSurvey';
import AddOption from './AddOption';

const AddModal = ({ surveyId, questionId, requestType, closeAddModal, name }) => {
    const [isLoading, setIsLoading] = useState(false);
    
    return (
        <>
            {isLoading && (
                <LoadingDiv>
                    <LoadingIcon />
                </LoadingDiv>
            )}

            {!isLoading && requestType === 'survey' && (
                <ModalContent>
                    <AddSurvey
                        closeAddModal={closeAddModal}
                    />
                </ModalContent>
            )}

            {!isLoading && requestType === 'question' && (
                <ModalContent>
                    <AddQuestion 
                        surveyId={surveyId}
                        closeAddModal={closeAddModal}
                        name={name}
                    />
                </ModalContent>
            )}

            {!isLoading && requestType === 'option' && (
                <ModalContent>
                    <AddOption 
                        surveyId={surveyId}
                        questionId={questionId}
                        closeAddModal={closeAddModal}
                        name={name}
                    />
                </ModalContent>
            )}
        </>
    );
};

export default AddModal;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 292px); // 292px is the height of the nav bar + title component combined
    height: 100%;
    margin: 0;
`;
