import React, { useState } from 'react';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import SearchModal from './SearchModal';
import { uploadFile } from '../../../../api/FilesController';
import Button from '../../../../components/common/button/Button';
import { setColor } from '../../../../assets/styles/styles';

const FileUploader = ({ setIsUploaderOn }) => {
    const fileTypeOptions = [
        'ambientMusic',
        'profileIcon',
        'profileImages',
        'inventoryItemIcon',
        'inventoryDescriptionImage',
        'inventoryCategoryIcon',
        'characterAspectIcon',
        'characterCategoryIcon',
        'roomTemplateTileImage',
        'roomDetailsImage',
        'roomBannerImage',
        'storeItemImage',
        'storeDetailsImage',
        'roomFloorWallStyle',
        'roomObject',
        'avatar',
        'achievements',
    ];

    const initialState = {
        usageType: fileTypeOptions[0],
        targetUserId: '',
        roomId: '',
        file: null,
        filePreviewUrl: null,
    };

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const user = useSelector((state) => state?.user);

    const handleClearForm = () => {
        setFormData(initialState);
    };

    const handleSelectCurrentUser = () => {
        setFormData({ ...formData, targetUserId: user.userId });
    };

    const [formData, setFormData] = useState(initialState);
    const [showUploader, setShowUploader] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const filePreviewUrl = URL.createObjectURL(file);
        setFormData({ ...formData, file, filePreviewUrl });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await uploadFile(formData, user.token);
        if (response.status === 200) {
            setSuccessMessage(`File uploaded successfully with id: \n ${response.data.id}`);
        } else {
            console.log('Error uploading file');
            setErrorMessage(`Error uploading file: HTTP code ${response.status}`);
        }
    };

    const closeUploader = () => {
        setFormData(initialState);
        setIsUploaderOn(false);
    };

    const initialSearchModalState = {
        type: '',
        show: false,
        formValue: '',
    };

    const [searchModal, setSearchModal] = useState(initialSearchModalState);

    const handleSearchModalClose = () => {
        setSearchModal(initialSearchModalState);
    };

    const handleSelectedValue = (record, primaryKey) => {
        setFormData({ ...formData, [searchModal.formValue]: record[primaryKey] });
        setSearchModal(initialSearchModalState);
    };

    const handleOpenSearchModal = (table, formValue) => {
        setSearchModal({
            type: table,
            show: true,
            formValue,
        });
    };

    return (
        <FileUploaderContainer>
            <Row>
                <button onClick={closeUploader}>X</button>
            </Row>
            <>
                <ImageHolder>
                    {formData.filePreviewUrl ? (
                        <img
                            src={formData.filePreviewUrl}
                            alt="Preview"
                            style={{ maxWidth: '400px', maxHeight: '400px', marginBottom: '10px' }}
                        />
                    ) : (
                        <ImagePlaceHolder />
                    )}
                </ImageHolder>

                {!successMessage ? <></> : <SuccessMessage>{successMessage}</SuccessMessage>}
                {!errorMessage ? <></> : <ErrorMessage>{errorMessage}</ErrorMessage>}

                <FormContainer
                    onSubmit={(e) => {
                        handleSubmit(e);
                    }}
                >
                    <RowFormContainer>
                        <FormLabel htmlFor="name">
                            Usage Type:
                            {/* <input type="text" name="name" value={formData.name} onChange={handleChange} required /> */}
                            <select
                                name="usageType"
                                required
                                value={formData.usageType}
                                defaultValue={'ambientMusic'}
                                onChange={handleChange}
                            >
                                {fileTypeOptions.map((option, i) => {
                                    return (
                                        <option key={uniqueId()} value={option}>
                                            {option}
                                        </option>
                                    );
                                })}
                            </select>
                        </FormLabel>
                    </RowFormContainer>
                    <RowFormContainer>
                        <FormLabel htmlFor="targetUserId">
                            Target User Id:
                            <FormInput
                                type="text"
                                name="targetUserId"
                                value={formData.targetUserId}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                        <button onClick={() => handleOpenSearchModal('users', 'targetUserId')}>Search User</button>
                        <button onClick={handleSelectCurrentUser}>Set to Current User</button>
                    </RowFormContainer>
                    <RowFormContainer>
                        <FormLabel htmlFor="roomId">
                            Room Id:
                            <FormInput
                                type="text"
                                name="roomId"
                                value={formData.roomId}
                                onChange={handleChange}
                                required
                            />
                            <button onClick={() => handleOpenSearchModal('rooms', 'roomId')}>Search Room</button>
                        </FormLabel>
                    </RowFormContainer>
                    <RowFormContainer>
                        <FormLabel htmlFor="file">
                            <p>Upload File:</p>
                            <FormInput id="fileInput" type="file" name="file" onChange={handleFileChange} required />
                        </FormLabel>
                    </RowFormContainer>
                    <ButtonContainer>
                        <button type="submit">Submit Button</button>
                        {/* <Button variant="DefaultButton" label="Submit" onClick={handleSubmit}  /> */}
                        <Button variant="DefaultButton" label="Clear" onClick={handleClearForm} />
                    </ButtonContainer>
                </FormContainer>
            </>
            {!searchModal.show ? (
                <></>
            ) : (
                <SearchModal
                    options={searchModal}
                    handleSearchModalClose={handleSearchModalClose}
                    handleOpenSearchModal={handleOpenSearchModal}
                    handleSelectedValue={handleSelectedValue}
                />
            )}
        </FileUploaderContainer>
    );
};

const FileModal = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10;
    left: 800px;
    top: 300px;
    width: 650px;
    height: 650px;
    border-width: 5px;
    border-style: solid;
    border-color: ${setColor.iconColor3};
    background-color: ${setColor.grey};
`;

const RowFormContainer = styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const SuccessMessage = styled.h1`
    font-size: 30px;
    color: ${setColor.iconColor3};
    text-align: center;
    white-space: pre-line;
`;

const ErrorMessage = styled.h1`
    font-size: 40px;
    color: ${setColor.iconColor3};
`;

const ImageHolder = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
`;

const ImagePlaceHolder = styled.div`
    width: 400px;
    height: 400px;
    background-color: ${setColor.fileuploadGrey};
`;

const FormContainer = styled.form`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 200px;
`;

const FormInput = styled.input`
    width: 250px;
    font-size: 12px;
`;

const FormLabel = styled.label`
    width: 100%px;
    display: flex;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 20px;
`;

const FileUploaderContainer = styled.div`
    width: 800px;
    height: 800px;
    position: absolute;
    z-index: 10;
    top: 200px;
    left: 800px;
    border-width: 5px;
    border-style: solid;
    border-color: ${setColor.iconColor3};
    background-color: ${setColor.grey};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Row = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    padding-right: 30px;
    padding-top: 20px;
`;

export default FileUploader;
