import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getTableInfo } from '../../../../api/AdminController';
import { setColor } from '../../../../assets/styles/styles';

const SearchModal = ({ options, handleSearchModalClose, handleSelectedValue }) => {
    const tableName = options.type;

    const getColumnsByTable = (tableName) => {
        switch (tableName) {
            // Put primary key in index 0 of each array (used for selection);
            case 'users':
                return ['user_id', 'display_name', 'email', 'is_deleted'];
            case 'rooms':
                return ['id', 'name', 'room_type', 'owner_name', 'is_visible_in_nav_menu'];
            default:
                return [];
        }
    };

    const [allRecords, setAllRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [loading, setLoading] = useState(true);

    const [columns, setColumns] = useState(getColumnsByTable(tableName));

    const token = useSelector((state) => state?.user?.token);
    
    const fetchTableInfo = async () => {
        const response = await getTableInfo(token, tableName);
        setAllRecords(response.data.records);
        setFilteredRecords(response.data.records);
    };

    useEffect(() => {
        fetchTableInfo();
    }, []);

    const handleInput = async (e) => {
        setSearchString(e.target.value);
    };

    const filterResults = async (e) => {
      e.preventDefault();
      e.stopPropagation()
      
      if (searchString === "") {
        setFilteredRecords(allRecords)
        return
      }

      const newRecords = [];
      for (let i = 0; i < allRecords.length; i+=1) {
        const record = allRecords[i];
        for (let j = 0; j < columns.length; j+=1) {
          const column = columns[j];
          const value = record[column];
          if (value) {
            if (typeof value === "string") {
              if (value.toLowerCase().includes(searchString.toLowerCase())) {
                newRecords.push(record);
                break
              }
            }
          }
        }
      }
      console.log(newRecords)
      setFilteredRecords(newRecords)
    }


    if (!options.show) {
        return <></>;
    }

    return (
        <ModalContainer>
            <HeaderModal>
              <CloseButton onClick={handleSearchModalClose}>Close</CloseButton>
            </HeaderModal>
            <h1>Search {options.type}</h1>
            <SearchBarContainer>
              <SearchBarInput onChange={(e) => handleInput(e)} />
              <button onClick={filterResults}>Filter</button>
            </SearchBarContainer>
            <table border="2">
                <tbody>
                    <tr>
                        {
                          columns.map(columnName => {
                            return <td key={uniqueId("column")}>{columnName}</td>
                          })
                        }
                    </tr>
                        {
                          filteredRecords.map(record => {
                            return <DataRow key={uniqueId("tr")} onClick={() => handleSelectedValue(record, columns[0])}>
                              {
                                columns.map((column) => {
                                  if (typeof record[column] === "boolean") {
                                    return <td key={uniqueId("td")}>{`${record[column]}`}</td>
                                  }
                                  return <td key={uniqueId("td")}>{record[column]}</td>
                                })
                              }
                            </DataRow>
                          })
                        }
                </tbody>
            </table>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
  height: 100%;
  width: 800px;
  z-index: 20;
  position: absolute;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: ${setColor.white};
  
`;

const SearchBarContainer = styled.div`
  height: 50px;
  background-color: ${setColor.searchbarWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SearchBarInput = styled.input`
  width: 200px;
`;

const TableRow = styled.input`
  height: 20px;
  font-size: 10px;
`;

const HeaderModal = styled.div`
  height: 50px;
  /* display: flex; */
  justify-content: end;
  box-sizing: border-box;
  padding: 5px;
  /* top: -80; */
  display: flex;
  align-content: flex-end;
  `;

const CloseButton = styled.button`
  height: 30px;

`;



const DataRow = styled.tr`
  z-index: 30;
  height: 30px;
  font-size: 12px;
  &:hover {
        background-color: ${setColor.iconColor3};
        color: ${setColor.black};
    }
`;

export default SearchModal;
