import { getFromSessionStorage } from '../../../hooks/useSessionStorage';
import * as spotifyConstants from '../../../constants';
import axiosInstance from '../../../config/axiosConfig';

export async function handleSpotifyAuth(redirectUrl) {
    console.log('redirectUrl', redirectUrl);
    try {
        window.location.href = `${process.env.REACT_APP_CORE_API_URI}/web/spotify/login/${encodeURIComponent(
            redirectUrl
        )}`;
    } catch (error) {
        console.error('Error during Spotify authentication:', error);
    }
}

export async function getSpotifyToken(code, urlParams, redirectUrl) {
    const token = null;

    if (code && urlParams) {
        // If the code is present, send a request to the backend to fetch the token
        try {
            const config = {
                method: 'GET',
                url: `${process.env.REACT_APP_CORE_API_URI}/web/spotify/callback/${code}/${encodeURIComponent(
                    redirectUrl
                )}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            };

            const response = await axiosInstance.request(config);

            if (response.status === 200) {
                const data = await response.data;

                // Remove the code and state parameters from the URL
                urlParams.delete('code');
                urlParams.delete('state');
                const newUrl = `${window.location.origin}${window.location.pathname}`;
                window.history.replaceState({}, '', newUrl);

                return data;
            }
            console.error('Failed to fetch the token from the backend');
            return token;
        } catch (error) {
            console.error('Error during token fetch:', error);
            return token;
        }
    } else {
        return token;
    }
}

// eslint-disable-next-line camelcase
export async function transferPlayback(device_id, token) {
    try {
        const config = {
            method: 'GET',
            // eslint-disable-next-line camelcase
            url: `${process.env.REACT_APP_CORE_API_URI}/web/spotify/transferPlayback/${device_id}/${token}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        };

        const response = await axiosInstance.request(config);

        return response;
    } catch (error) {
        console.error('Error during transfer playback:', error);
    }
}

export async function resumePlayer(token, contextURI, type) {
    try {
        const config = {
            method: 'POST',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/spotify/resumePlayback`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                accessToken: token,
                contextURI,
                type,
            },
        };

        const response = await axiosInstance.request(config);
        // This is assigning the device id passed as the active spotify application
        return response.status;
    } catch (error) {
        console.error('Error during resume player:', error);
        return null;
    }
}

export async function setRepeat(state, deviceId, token) {
    try {
        const config = {
            method: 'GET',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/spotify/setRepeat/${state}/${deviceId}/${token}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        };

        const response = await axiosInstance.request(config);

        return response;
    } catch (error) {
        console.error('Error during resume player:', error);
    }
}

export async function getProfile(token) {
    try {
        const config = {
            method: 'GET',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/spotify/profile/${token}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        };

        const response = await axiosInstance.request(config);

        if (response.status === 200) {
            const data = await response.data;
            return data;
        }

        return null;
    } catch (error) {
        console.error('Error during resume player:', error);
    }
}

export async function getSpotifyRefreshToken(refreshToken) {
    const token = null;

    if (refreshToken) {
        // If the code is present, send a request to the backend to fetch the token
        try {
            const config = {
                method: 'GET',
                url: `${process.env.REACT_APP_CORE_API_URI}/web/spotify/refreshToken/${refreshToken}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            };

            const response = await axiosInstance.request(config);

            if (response.status === 200) {
                const data = await response.data;

                return data.accessToken;
            }
            console.error('Failed to fetch the token from the backend');
            return token;
        } catch (error) {
            console.error('Error during token fetch:', error);
            return token;
        }
    } else {
        return token;
    }
}

export async function refreshUserToken(userId) {
    try {
        const config = {
            method: 'POST',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/users/auth-session`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: { userId },
        };

        const response = await axiosInstance.request(config);

        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error('Error during refresh token:', error);
        return null;
    }
}

export async function signUpUser(displayName, email) {
    try {
        const config = {
            method: 'POST',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/users/login`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: { displayName, email },
        };

        const response = await axiosInstance.request(config);

        if (response.status === 200) {
            const data = await response.data;
            return data;
        }

        return null;
    } catch (error) {
        console.error('Error during singn up:', error);
        return null;
    }
}

export const logStreaming = async (song, uri) => {
    const userId = getFromSessionStorage('userId');
    const userSession = getFromSessionStorage('userToken');
    if (!userId) {
        return;
    }

    const config = {
        method: 'POST',
        url: `${process.env.REACT_APP_CORE_API_URI}/web/spotify/misc/teddys-lounge-spotify-tracking`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userSession}`,
        },
        data: {
            analyticsData: {
                trackId: uri,
                sessionId: userSession,
                userId,
                datetimeStarted: new Date(),
                song,
                durationInSeconds: spotifyConstants.STREAM_SECONDS,
            },
        },
    };

    const response = await axiosInstance.request(config);
};

export async function sendTeddyNoteToDb(name, message, userToken) {
    console.log('api request');
    try {
        const config = {
            url: `${process.env.REACT_APP_CORE_API_URI}/web/users/action-log`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
            data: {
                analyticsData: {
                    name,
                    message,
                },
            },
        };

        const response = await axiosInstance.request(config);

        console.log(`Response: ${response.data}`);
        if (response.status === 200) {
            console.log('success', response);
            return response.status;
        }
    } catch (error) {
        console.error('Error sending message:', error);
        return null;
    }
}
