import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './VagonStream.css';
// import { useSelector } from 'react-redux';
// import { fetchSpotify } from '../../redux/slices/spotify';
import {
    changeURLByRoomId,
    exitFullScreen,
    enterFullScreen,
    isIOSDevice,
    extractParamValue,
} from '../../utils/generalUtils';
import SpotifyInterface from '../spotify/components/SpotifyControls';
import * as constants from '../../constants';
import { sendEventsLog } from '../../api/UserController';
import { resumePlayer } from '../spotify/api/SpotifyController';
import { fetchVanityLinkData } from '../../api/VanityLinkController';
import { startInterval, stopInterval, alterState, sendVagonMessage } from './helpers/utils';
import VanityFeatures from './components/VanityFeatures';
import { setToSessionStorage, getFromSessionStorage } from '../../hooks/useSessionStorage';
import Igt from '../igt/Igt';
import LionGamingCasino from '../lionGaming/LionGamingCasino';

const VagonStreamIntraverse = (props) => {
    const isIOS = isIOSDevice();
    const elementRef = useRef(null);
    const intervalIdRef = useRef(null);

    const spotifyPlaying = useRef(false);
    const vagonShowSpotifyModal = useRef(true);

    const [vanityLinkInfo, setVanityLinkInfo] = useState({});
    const [showCornerButtons, setShowCornerButtons] = useState(true);
    const [landscape, setLandscape] = useState(false);
    const [fullScreen, setFullScreen] = useState(JSON.parse(getFromSessionStorage('fullscreen') || 'false'));
    const [openBroadcastModal, setOpenBroadcastModal] = useState(false);
    const [playingMusic, setPlayingMusic] = useState(false);
    const [openSpotify, setOpenSpotify] = useState(false);

    const [igtSlot, setIgtSlot] = useState(null);
    const [lionGamingSlot, setLionGamingSlot] = useState(null);

    // These features are WIP, need to be made dynamically configurable
    const [teddyNoteOpen, setTeddyNoteOpen] = useState(false);
    const [openTeddySelfiePopup, setOpenTeddySelfiePopup] = useState(false);
    const [openContestModalState, setOpenContestModalState] = useState(false);

    const setVanityLinkData = async (roomId) => {
        const vanityLink = await changeURLByRoomId(roomId);
        if (vanityLink) {
            const data = await fetchVanityLinkData(vanityLink);
            setVanityLinkInfo((value) => ({ ...value, ...data }));
        }
    };

    const setIgtSlotData = async (data) => {
        setIgtSlot(data);
    };

    const setLionGamingSlotData = async (data) => {
        setLionGamingSlot(data);
    };

    const removeVanityLinkData = () => {
        if (spotifyPlaying.current) {
            // This is to disconnect the spotify player when the room is unloaded
            props.spotifyPlayer?.disconnect();
            alterState(setPlayingMusic);
            spotifyPlaying.current = false;
            vagonShowSpotifyModal.current = false;
        }
        setVanityLinkInfo({});
    };

    const messageActions = {
        [constants.OPEN_BROADCAST_MODAL]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setOpenBroadcastModal);
        },
        [constants.OPEN_TEDDY_SELFIE]: () => alterState(setOpenTeddySelfiePopup),
        [constants.OPEN_SPOTIFY]: () => alterState(setOpenSpotify),
        [constants.PDF_OPEN]: () => alterState(setShowCornerButtons),
        [constants.PDF_CLOSE]: () => alterState(setShowCornerButtons),
        [constants.OPEN_TEDDY_CONTEST]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setOpenContestModalState);
        },
        [constants.OPEN_TEDDY_NOTE]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setTeddyNoteOpen);
        },
        [constants.FULL_SCREEN]: () => {
            alterState(setFullScreen);
        },
        [constants.SETUPCARD]: () => {
            window.open('/wallet?source=web_unity');
        },
        [constants.PAUSE_BACKGROUND_MUSIC]: () => {
            const audio = document.getElementById('audio');
            audio.pause();
        },
        [constants.PLAY_BACKGROUND_MUSIC]: () => {
            const audio = document.getElementById('audio');
            audio.play();
        },
        [constants.ENTERING_NAV_MENU]: () => {
            const audio = document.getElementById('audio');
            audio.stop();
        },
    };

    // This function is called when the script is loaded, it is used to listen to the messages sent from the application
    // States can not be updated within the function, so we are using alterState function to update the state
    const loadedScript = () => {
        // let vagonShowSpotifyModal = true;
        startInterval(intervalIdRef);

        window.Vagon.onConnected(() => {
            setTimeout(() => {
                const audio = document.getElementById('audio');
                audio.pause();
            }, 12000);
        });

        window.Vagon.onApplicationMessage(async (evt) => {
            const message = evt.message.toString();
            console.log('Message received from Vagon:', message);

            if (messageActions[message]) {
                messageActions[message]();
            } else if (message.includes(constants.ROOM_UNLOADED)) {
                removeVanityLinkData();
            } else if (message.includes(constants.NEW_ROOM_LOADING)) {
                const roomId = message.split(':');
                setVanityLinkData(roomId[1]);
                // Checking if the message is a spotify track and if the spotify player is not already playing music
            } else if (message.includes(constants.SPOTIFY) && spotifyPlaying.current === false) {
                const type = constants.ALBUM;

                if (vagonShowSpotifyModal.current === true && !getFromSessionStorage('spotifyAccessToken')) {
                    alterState(setOpenSpotify);
                    vagonShowSpotifyModal.current = false;
                } else if (getFromSessionStorage('spotifyAccessToken')) {
                    resumePlayer(getFromSessionStorage('spotifyAccessToken'), message, type).then(() => {
                        spotifyPlaying.current = true;
                        vagonShowSpotifyModal.current = false;
                        alterState(setPlayingMusic);
                        alterState(setOpenSpotify);
                    });
                }
            } else if (message.includes(constants.SLOT)) {
                setIgtSlotData(message);
            } else if (message.includes(constants.LIONGAME)) {
                setLionGamingSlotData(message);
            }
            const paramStart = message.indexOf('&clientSessionId=');
            if (paramStart !== -1) {
                const clientSessionId = extractParamValue(message, 'clientSessionId');
                const userToken = extractParamValue(message, 'token');
                const envunity = extractParamValue(message, 'envunity');
                const userId = extractParamValue(message, 'displayName');

                if (clientSessionId !== null && userToken !== null && envunity !== null && userId !== null) {
                    setToSessionStorage('clientSessionId', clientSessionId);
                    setToSessionStorage('envUnity', envunity);
                    await sendEventsLog(
                        userToken,
                        'igt',
                        userId,
                        constants.LOG_EVENTS_LISTENER_DATA,
                        'info',
                        { generatedSlotUrl: window.location.href, message },
                        clientSessionId,
                        envunity
                    );
                }
            }
        });
    };

    const updateOrientation = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        const aspectRatio = width / height;
        const iframeElement = document.getElementById('vagonFrame');

        // second check to ensure aspect Ratio is below 1
        if (aspectRatio < 1) {
            iframeElement.classList.add('force-landscape');
            iframeElement.style.removeProperty('width');
            iframeElement.style.removeProperty('height');
            setLandscape(true);
        } else {
            iframeElement.classList.remove('force-landscape');
            iframeElement.style.width = '100vw';
            iframeElement.style.height = '100vh';
            setLandscape(false);
        }
        if (isIOS) iframeElement.style.padding = '10px';
    };

    useEffect(() => {
        if (fullScreen === true || fullScreen === 'true') {
            enterFullScreen(elementRef);
        } else if (
            (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullScreenElement) &&
            !fullScreen
        ) {
            setToSessionStorage('fullscreen', false);
            exitFullScreen();
        }
        updateOrientation();
    }, [fullScreen]);

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://app.vagon.io/vagonsdk.js');
        script.addEventListener('load', () => {
            loadedScript();
        });
        head.appendChild(script);

        window.addEventListener('resize', updateOrientation);
        return () => window.removeEventListener('resize', updateOrientation);
    }, []);

    useEffect(() => {
        if (openBroadcastModal || teddyNoteOpen || openContestModalState || openTeddySelfiePopup) {
            stopInterval(intervalIdRef);
        } else {
            startInterval(intervalIdRef);
        }
    }, [openBroadcastModal, teddyNoteOpen, openContestModalState, openTeddySelfiePopup]);

    return (
        <div
            ref={elementRef}
            className="iframe-container"
            style={{ width: '100vw', height: '100vh', background: 'black', zIndex: 100 }}
        >
            {igtSlot && <Igt data={igtSlot} setIgtSlot={setIgtSlot} />}
            {lionGamingSlot && <LionGamingCasino data={lionGamingSlot} setLionGamingSlot={setLionGamingSlot} />}

            <VanityFeatures
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                vanityLinkInfo={vanityLinkInfo}
                openBroadcastModal={openBroadcastModal}
                setOpenBroadcastModal={setOpenBroadcastModal}
                showCornerButtons={showCornerButtons}
                landscape={landscape}
            />
            <SpotifyInterface
                player={props?.spotifyPlayer}
                setFullScreen={setFullScreen}
                landscape={landscape}
                teddyNoteOpen={teddyNoteOpen}
                setTeddyNoteOpen={setTeddyNoteOpen}
                openTeddySelfiePopup={openTeddySelfiePopup}
                setOpenTeddySelfiePopup={setOpenTeddySelfiePopup}
                playingMusic={playingMusic}
                openContestModalState={openContestModalState}
                setOpenContestModalState={setOpenContestModalState}
                openSpotify={openSpotify}
                setOpenSpotify={setOpenSpotify}
            />
            <iframe
                id="vagonFrame"
                title="Vagon Stream Content"
                allow="microphone *; clipboard-read *; clipboard-write *; encrypted-media *;"
                style={{ width: '100vw', height: '100vh', border: 0, zIndex: 100 }}
                src={props.streamUrl}
                sandbox="allow-pointer-lock allow-scripts allow-forms allow-same-origin allow-popups"
            />
        </div>
    );
};

VagonStreamIntraverse.propTypes = {
    streamUrl: PropTypes.string.isRequired,
    spotifyPlayer: PropTypes.object,
};

export default VagonStreamIntraverse;
