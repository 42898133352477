import UAParser from 'ua-parser-js';
import axios from 'axios';
import { DeleteUser } from '../redux/actions/UserActions';
import { DeleteMerchant } from '../redux/actions/MerchantActions';
import { COREAPI_WEB_USERS_URL } from '../constants';
import axiosInstance, { API_POST, API_GET } from '../config/axiosConfig';

const COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}/web`;

export const getTablesSchema = async (token) => {
    try {
        const response = await axiosInstance.get(`${COREAPIURL}/admin/tables_schema`, {
            headers: {
                'Content-Type': 'application/json',
                autorization: `Bearer ${token}`,
            },
        });
        return response; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err;
    }
};

export const getTableInfo = async (token, tableName) => {
    try {
        const response = await axiosInstance.get(`${COREAPIURL}/admin/table/${tableName}`, {
            headers: {
                'Content-Type': 'application/json',
                autorization: `Bearer ${token}`,
            },
        });
        return response; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err;
    }
};

export const getRecordInfo = async (token, tableName, id, primaryKey) => {
    try {
        const response = await axiosInstance.get(
            `${COREAPIURL}/admin/record/table/${tableName}/primary_key/${primaryKey}/id/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    autorization: `Bearer ${token}`,
                },
            }
        );
        return response; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err;
    }
};

export const updateRecordDynamically = async (token, tableName, id, primaryKey, data) => {
    try {
        const response = await axiosInstance.put(
            `${COREAPIURL}/admin/record/table/${tableName}/primary_key/${primaryKey}/id/${id}`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    autorization: `Bearer ${token}`,
                },
            }
        );
        return response; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err.response;
    }
};

export const getRoomNPCs = async (token, roomId) => {
    try {
        const response = await axiosInstance.get(`${COREAPIURL}/npc/${roomId}`, {
            headers: {
                'Content-Type': 'application/json',
                autorization: `Bearer ${token}`,
            },
        });
        return response; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err.response;
    }
};

export const updateNPC = async (token, npcData) => {
    try {
        const data = {
            name: npcData.name,
            dialogue: {
                text: npcData.dialogue.text,
            },
            key: npcData.key,
        };
        // USED fetch on this endpoint cause axios -> parses the json array incorrectlly.
        const response = await fetch(`${COREAPIURL}/npc/${npcData.npcId}`, {
            method: 'PUT',
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const json = await response.json();
        return { data: json, status: response.status }; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err.response;
    }
};

function generateRandomName() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const randomLetter = letters[Math.floor(Math.random() * letters.length)];
    const randomNumber = Math.floor(Math.random() * 1000);
    const randomName = randomLetter + randomNumber;
    return randomName;
}

export const createNPC = async (token, roomId) => {
    try {
        const data = {
            name: generateRandomName(),
            dialogue: {
                text: ['Test dialgue'],
            },
            key: generateRandomName(),
            roomId,
        };
        // USED fetch on this endpoint cause axios -> parses the json array incorrectlly.
        const response = await fetch(`${COREAPIURL}/npc`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const json = await response.json();
        return { data: json, status: response.status }; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err.response;
    }
};

export const deleteNPC = async (token, npcId) => {
    try {
        // USED fetch on this endpoint cause axios -> parses the json array incorrectlly.
        const response = await axiosInstance.delete(`${COREAPIURL}/npc/${npcId}`, {
            headers: {
                'Content-Type': 'application/json',
                autorization: `Bearer ${token}`,
            },
        });
        return response; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err.response;
    }
};
