import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { setColor } from '../../../../../assets/styles/styles';
import { default as Button } from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import Banner from '../../../../../components/layout/ui/Banner';
import { deleteRole, RemovePermissionFromRole } from '../api/rolesPermissionsController';

const DeleteModal = ({ roleId, permissionId, requestType, closeDeleteModal, name }) => {
    const navigate = useNavigate();
    const [deleteText, setDeleteText] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const [successMessageBanner, setSuccessMessageBanner] = useState('');
    const handleDeleteClick = async () => {
        if (deleteText.toLowerCase() === 'delete') {
            try {
                if(requestType === 'permissions'){
                    const response = await RemovePermissionFromRole(roleId, permissionId);
                    if (response.status === 204) {
                        setSuccessMessageBanner(
                            `Successfully deleted the permission from role (${name}). Page will reload in 3 seconds.`
                        );
                    }
                }

                if(requestType === 'roles'){
                    const response = await deleteRole(roleId);
                    if (response.status === 204) {
                        setSuccessMessageBanner(
                            `Successfully deleted the role (${name}). Page will reload in 3 seconds.`
                        );
                    }
                }

            } catch (error) {
                console.error('error', error);
                setDeleteError(
                    `Error deleting role. Please try again. Error: ${error.response.data.message}`
                );
            }
        } else {
            setDeleteError('Write DELETE to proceed.');
        }
        setDeleteText('');
    };

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeDeleteModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    useEffect(() => {
        if (deleteError) {
            const timer = setTimeout(() => {
                setDeleteError('');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [deleteError]);

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={'SUCCESS'}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                left={'0'}
            />

            {requestType === 'permissions' && (
                <Typography variant="h2PurpleHeading" margin={'0 0 2rem 0'}>
                    Are you sure you want to delete the permission?
                </Typography>
            )}

            {requestType === 'roles' && (
                <>
                <Typography variant="h2PurpleHeading" margin={'0 0 2rem 0'}>
                    Are you sure you want to delete the role?
                </Typography>

                <Typography variant="pWhite" margin={'0 0 0 0'}>
                    <YellowText> Role will delete all associated permissions.</YellowText>
                </Typography>
                </>
            )}

            <Card>
                <Typography variant="pWhite" margin={'0'}>
                    This action is <YellowText>permanent </YellowText> and{' '}
                    <YellowText> irreversible. </YellowText>
                </Typography>

                <Typography variant="pWhite" margin={'1rem 0 0 0'}>
                    Please type DELETE below to proceed.
                </Typography>
                {deleteError && <MessageDesc>{deleteError}</MessageDesc>}

                <InputBox type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)} />

                <Button
                    variant={'PinkButton'}
                    buttonWidth={'100%'}
                    disabled={deleteText.toLowerCase() !== 'delete'}
                    label={'PERMANENTLY DELETE'}
                    margin={'0  0 2rem 0'}
                    onClick={handleDeleteClick}
                />
                <Typography variant="GreyText" onClick={closeDeleteModal} margin={'0'}>
                    GO BACK
                </Typography>
            </Card>
        </>
    );
};

export default DeleteModal;

const Card = styled.div`
    height: auto;
    position: relative;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const YellowText = styled.span`
    color: ${setColor.yellowText};
    font-weight: 700;
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 2rem 0 3rem 0;
    height: 50%;
    width: 100%;
    background-color: transparent;

    @media (max-width: 768px) {
        height: 15%;
    }
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
    margin: 0rem 0rem 1rem 2rem;
`;
