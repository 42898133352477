import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axiosInstance from '../../../../config/axiosConfig';
import { setColor } from '../../../../assets/styles/styles';
import { default as Button } from '../../../../components/common/button/Button';
import Typography from '../../../../components/common/text/Typography';
import Banner from '../../../../components/layout/ui/Banner';

const DeleteRoomModal = ({ id, closeModal }) => {    
    const [deleteText, setDeleteText] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const [successMessageBanner, setSuccessMessageBanner] = useState('');

    const handleDeleteClick = async () => {
        if (deleteText.toLowerCase() === 'delete') {
            try {
                const response = await axiosInstance.delete(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
                if (response.status === 204) {
                    setSuccessMessageBanner(`Room ${id} successfully deleted.`);
                } else if (response.status === 404) {
                    setDeleteError(`Already deleted room or not found room ${id}`);
                } else {
                    setDeleteError(`Failed to delete room ${id}`);
                }
            } catch (error) {
                console.log('error', error);
                setDeleteError(
                    `Something went wrong. Please try again later.`
                );
            }
        } else {
            setDeleteError('Write DELETE to proceed.');
        }
        setDeleteText('');
    };

    useEffect(() => {
        if (deleteError) {
            const timer = setTimeout(() => {
                setDeleteError('');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [deleteError]);

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);


    return (
        <>
        <Banner
            width={'100%'}
            zIndex="1000"
            message={`${successMessageBanner}`}
            type={'SUCCESS'}
            openBanner={!!successMessageBanner}
            setOpenBanner={setSuccessMessageBanner}
            topDisplay
            showCloseButton
            gameScreen={false}
            top={'0px'}
            left={'0'}
        />
        
        <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
            Permanently Delete the room?
        </Typography>

        <Card>
            <Typography variant="pWhite" margin={'0'}>
                This action is <YellowText>permanent </YellowText> and <YellowText> irreversible.</YellowText>{' '}
                Please type DELETE below to proceed.
            </Typography>
            <Typography variant="pWhite" margin={'1rem 0'}>
            <YellowText>All room users and data associated with this room will be permanently deleted.</YellowText>
            </Typography>
            {deleteError && <MessageDesc>{deleteError}</MessageDesc>}
            <InputBox type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)} />
            <Button
                variant={'PinkButton'}
                buttonWidth={'100%'}
                disabled={deleteText.toLowerCase() !== 'delete'}
                label={'PERMANENTLY DELETE'}
                margin={' 0  0 1rem 0'}
                onClick={handleDeleteClick}
            />
            <Typography variant="GreyText" onClick={closeModal} margin={'0'}>
                GO BACK
            </Typography>
        </Card>
        </>
    );
};

export default DeleteRoomModal;

const Card = styled.div`
    height: auto;
    position: relative;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const YellowText = styled.span`
    color: ${setColor.yellowText};
    font-weight: 700;
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 20px 0;
    width: 100%;
    background-color: transparent;
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
    margin: 0rem 0rem 1rem 2rem;
`;
