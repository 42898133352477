/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import {
    transferPlayback,
    setRepeat,
    logStreaming,
    sendTeddyNoteToDb,
    handleSpotifyAuth,
} from '../api/SpotifyController';
import * as spotifyConstants from '../../../constants';
import { getFromSessionStorage, setToSessionStorage } from '../../../hooks/useSessionStorage';
import { isMobileDevice } from '../../../utils/generalUtils';
import SpotifyLogoIcon2 from '../../../assets/images/spotify/spotifyLogo2.png';
import FwdButtonIcon from '../../../assets/images/spotify/FwdButtonImg.svg';
import PauseButtonIcon from '../../../assets/images/spotify/PauseButtonImg.svg';
import playlistIcon from '../../../assets/images/spotify/PlaylistIcon.png';
import SendMessageButton from '../../../assets/images/spotify/SendMessageButton.svg';
import SendMessageButtonActive from '../../../assets/images/spotify/SendMessageButtonActive.svg';
import CloseButton from '../../../assets/images/spotify/CloseButton.svg';
import PlayButton from '../../../assets/images/spotify/PlayButtonImg.svg';
import NotNowButton from '../../../assets/images/spotify/PrimaryButtonNotNow.png';
import ReconnectButton from '../../../assets/images/spotify/PrimaryButtonReconnect.png';
import { ReactComponent as UserIconSVG } from '../../../assets/images/spotify/UserIcon.svg';
import WarningIcon from '../../../assets/images/spotify/Warning.svg';
import Banner from '../../../components/layout/ui/Banner';
import { fetchSpotify } from '../../../redux/slices/spotify';
import { fetchUserToken, fetchUserName } from '../../../redux/slices/users';
import { sendVagonMessage } from '../../vagon/helpers/utils';

import AlbumPhoto from '../../../assets/images/spotify/noAlbumImage.png';
import ContestComponent from '../../../components/ContestDropdownPage';
import { setFont } from '../../../assets/styles/styles';

const track = {
    uri: '',
    name: '',
    album: {
        images: [{ url: '' }],
        name: '',
    },
    artists: [{ name: '' }],
};

function SpotifyInterface(props) {
    const userToken = useSelector(fetchUserToken);
    const displayName = useSelector(fetchUserName);

    const isMobile = isMobileDevice();
    // const spotifyToken = useSelector(fetchSpotify);
    const spotifyToken = getFromSessionStorage('spotifyAccessToken');

    const [deviceId, setDeviceId] = useState(null);
    const [spotifyDisconnected, setSpotifyDisconnected] = useState(false);
    const [connectPrompt, setConnectPrompt] = useState(false);
    const [updateSource, setUpdateSource] = useState(false);
    const [ispaused, setPaused] = useState(false);
    const [current_track, setTrack] = useState(track);
    const [teddyNotesName, setTeddyNotesName] = useState(displayName);
    const [teddyNotesMessage, setTeddyNotesMessage] = useState('');
    const [successMessageSent, setSuccessMessageSent] = useState(false);
    const [showMessageWarning, setShowMessageWarning] = useState(false);
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (props.openContestModalState || props.teddyNoteOpen) {
            if (
                !isMobile &&
                (document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullScreenElement)
            ) {
                props.setFullScreen(false);
            }
        }
    }, [props.openContestModalState, props.teddyNoteOpen]);

    useEffect(() => {
        if (spotifyToken) {
            const playedSongs = JSON.parse(getFromSessionStorage('songsPlayed')) || [];

            const interval = setInterval(async () => {
                // eslint-disable-next-line camelcase
                if (!ispaused && props.playingMusic) {
                    // eslint-disable-next-line camelcase
                    if (playedSongs.some((track) => track.songName === current_track.name)) {
                        // eslint-disable-next-line camelcase
                        const playedTrackIndex = playedSongs.findIndex(
                            (track) => track.songName === current_track.name
                        );

                        // eslint-disable-next-line operator-assignment
                        playedSongs[playedTrackIndex].playTime = playedSongs[playedTrackIndex].playTime + 1;

                        if (spotifyConstants.STREAM_SECONDS === playedSongs[playedTrackIndex].playTime) {
                            // eslint-disable-next-line camelcase
                            // logStreaming(current_track.name, current_track.uri);
                        }
                    } else {
                        // eslint-disable-next-line no-new-object
                        const songPlayed = new Object();
                        // eslint-disable-next-line camelcase
                        songPlayed.songName = current_track.name;
                        songPlayed.playTime = 0;

                        playedSongs.push(songPlayed);
                    }

                    setToSessionStorage('songsPlayed', JSON.stringify(playedSongs));

                    const userId = window.localStorage.getItem('userId') || null;

                    const attributes = {
                        email: window.localStorage.getItem('email') || null,
                        used_spotify: !!window.localStorage.getItem('spotifyAccessToken'),
                        name: window.localStorage.getItem('displayName') || null,
                        spotify_track_duration: window.localStorage.getItem('songsPlayed') || null,
                    };

                    window.pushHotJarAttributes(userId, attributes);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line camelcase
    }, [ispaused, props.playingMusic, spotifyToken, current_track]);

    useEffect(() => {
        if (successMessageSent) {
            setTimeout(() => {
                setSuccessMessageSent(false);
            }, 8000);
        }
    }, [successMessageSent]);

    useEffect(() => {
        if (props.teddyNoteOpen) {
            textAreaRef.current.focus();
        }
    }, [props.teddyNoteOpen]);

    useEffect(() => {
        if (props?.player) {
            props.player.addListener('ready', ({ device_id }) => {
                setDeviceId(device_id);
                transferPlayback(device_id, spotifyToken).then(() => {
                    setRepeat(spotifyConstants.CONTEXT, device_id, spotifyToken);
                });
            });

            props.player.addListener('player_state_changed', (state) => {
                if (state) {
                    if (state?.context?.uri === null) {
                        props.setOpenSpotify(false);
                        setUpdateSource(true);
                        setConnectPrompt(true);
                        setSpotifyDisconnected(true);
                    } else {
                        // eslint-disable-next-line camelcase
                        const { current_track } = state.track_window;
                        setTrack(current_track);
                    }
                }
            });

            props.player.on('account_error', ({ message }) => {
                // Emitted when the user authenticated does not have a valid Spotify Premium subscription.
                console.error('Failed to validate Spotify account', message);
            });

            props.player.on('authentication_error', ({ message }) => {
                // Emitted when the Spotify.Player fails to instantiate a valid Spotify connection from the access token provided to getOAuthToken.
                console.error('Failed to authenticate', message);
            });

            props.player.addListener('autoplay_failed', () => {
                console.log('Autoplay is not allowed by the browser autoplay rules');
            });

            props.player.connect();
            props.player.activateElement();
        }
    }, [props.player, spotifyToken]);

    const handleSpotifyPause = () => {
        // eslint-disable-next-line camelcase
        if (ispaused) {
            props.player.resume().then(() => {
                setPaused(false);
            });
        } else {
            props.player.pause().then(() => {
                setPaused(true);
            });
        }
    };

    const handleSpotifyNext = () => {
        props.player.nextTrack().then(() => {
            // eslint-disable-next-line camelcase
            if (ispaused) {
                setPaused((prev) => !prev);
            }
        });
    };

    const handleReconnect = () => {
        transferPlayback(deviceId, spotifyToken).then(() => {
            setRepeat(spotifyConstants.CONTEXT, deviceId, spotifyToken);
            props.player.resume();
            props.player.setVolume(0.3);
        });
        setSpotifyDisconnected(false);
        setConnectPrompt(false);
        setUpdateSource(false);

        if (props.openSpotify) props.setOpenSpotify(false);
    };

    const sendTeddyMessage = () => {
        sendTeddyNoteToDb(teddyNotesName, teddyNotesMessage, userToken).then((response) => {
            if (response) {
                sendVagonMessage(spotifyConstants.UNITY_CLOSE_WEB_INTERFACE);
                setSuccessMessageSent(true);
                props.setTeddyNoteOpen(false);
                setShowMessageWarning(false);
            } else {
                setSuccessMessageSent(false);
                setShowMessageWarning(true);
            }
        });
    };

    return (
        <>
            {props.openContestModalState && (
                <ContestComponent setOpenContestModalState={props.setOpenContestModalState} />
            )}
            {connectPrompt && spotifyDisconnected && (
                <DiscModalWrapper landscape={props.landscape}>
                    <DiscModalContainer landscape={props.landscape}>
                        <DiscModalTitle>Spotify Disconnected!</DiscModalTitle>
                        <DiscModalText>
                            Your source on Spotify has been changed. Please change it back to{' '}
                            <strong>Web Player</strong> to resume your music.
                        </DiscModalText>
                        <DiscModalButtons>
                            <DiscModalButton
                                src={NotNowButton}
                                alt="Not Now Button"
                                onClick={() => setConnectPrompt(false)}
                            />
                            <DiscModalButton
                                src={ReconnectButton}
                                alt="Reconnect Button"
                                onClick={() => handleReconnect()}
                            />
                        </DiscModalButtons>
                    </DiscModalContainer>
                </DiscModalWrapper>
            )}
            {props.openSpotify && !updateSource && spotifyToken && (
                <SpotifyPlayerContainer landscape={props.landscape}>
                    <AlbumImagePlayer src={current_track?.album?.images[0]?.url || AlbumPhoto} alt="Album Image" />
                    <TextContainer>
                        <Playlist>
                            <PlaylistIconImg src={playlistIcon} />
                            Album: {current_track?.album?.name || 'Loading...'}
                        </Playlist>
                        <SongTitle>{current_track?.name || 'Loading...'}</SongTitle>
                        <ArtistName>{current_track?.artists[0]?.name || 'Loading...'}</ArtistName>
                    </TextContainer>
                    <CloseIconContainer style={{ margin: '0.3rem' }}>
                        <CloseIcon
                            fontSize="medium"
                            sx={{ color: '#9DA0A7' }}
                            onClick={() => props.setOpenSpotify(false)}
                        />
                    </CloseIconContainer>
                    {current_track?.artists[0]?.name !== '' && (
                        <PlayButtonsContainer landscape={props.landscape}>
                            <FwdButton src={FwdButtonIcon} alt="Fwd Button" onClick={() => handleSpotifyNext()} />
                            <PauseButton
                                src={ispaused ? PlayButton : PauseButtonIcon}
                                alt="Pause Button"
                                onClick={() => handleSpotifyPause()}
                            />
                        </PlayButtonsContainer>
                    )}
                </SpotifyPlayerContainer>
            )}
            {updateSource && props.openSpotify && (
                <BannerContainer style={{ padding: '5px 5px 15px 5px' }} landscape={props.landscape}>
                    <BannerWarning>
                        <AlbumImage src={WarningIcon} />
                    </BannerWarning>
                    <BannerTextContainer>
                        <CloseIconContainer style={{ paddingRight: '0.8rem' }}>
                            <CloseIcon
                                fontSize="medium"
                                sx={{ color: '#9DA0A7' }}
                                onClick={() => props.setOpenSpotify(false)}
                            />
                        </CloseIconContainer>
                        <BannerTitle>Update your source</BannerTitle>
                        <BannerSubtitle>
                            Please change your source to <strong>Web Player</strong> to resume your music
                        </BannerSubtitle>
                    </BannerTextContainer>
                    <BannerButton src={ReconnectButton} onClick={() => handleReconnect()} alt="Reconnect Button" />
                </BannerContainer>
            )}
            {props.openSpotify && !spotifyToken && (
                <BannerContainer landscape={props.landscape}>
                    <BannerWarning>
                        <AlbumImage src={SpotifyLogoIcon2} />
                    </BannerWarning>
                    <BannerTextContainer>
                        <CloseIconContainer style={{ padding: '0.3rem', paddingRight: '12px' }}>
                            <CloseIcon
                                fontSize="medium"
                                sx={{ color: '#9DA0A7' }}
                                onClick={() => props.setOpenSpotify(false)}
                            />
                        </CloseIconContainer>
                        <BannerTitle>Connect to Spotify</BannerTitle>
                        <BannerSubtitle>
                            Fully immerse in our Intraverse experience by connecting your <strong>premium</strong>{' '}
                            Spotify account
                        </BannerSubtitle>
                    </BannerTextContainer>
                    <BannerButton
                        src={ReconnectButton}
                        alt="Reconnect Button"
                        onClick={() => handleSpotifyAuth(window.location.href)}
                    />
                </BannerContainer>
            )}
            {props.teddyNoteOpen && (
                <NotesModalWrapper>
                    <MessageModalTitle>Teddy wants to hear from you!</MessageModalTitle>
                    <MessageModalSubtitle>
                        <strong> Press "ESC" </strong>and click below to start typing your message to Teddy.
                    </MessageModalSubtitle>
                    <TeddyNoteTextContainer>
                        <CloseIconContainer>
                            <CloseIcon
                                fontSize="large"
                                sx={{ color: '#9DA0A7', paddingTop: '3rem' }}
                                onClick={() => props.setTeddyNoteOpen(false)}
                            />
                        </CloseIconContainer>
                        <InputSection>
                            <InputIconContainer>
                                <UserIconSVG />
                                <p>NAME</p>
                            </InputIconContainer>
                            <NameInput
                                type="name"
                                required
                                placeholder="What is your name?"
                                value={teddyNotesName}
                                onChange={(e) => setTeddyNotesName(e.target.value)}
                            />
                        </InputSection>
                        <NoteInput
                            maxLength={500}
                            ref={textAreaRef}
                            type="text"
                            required
                            placeholder="Start typing..."
                            value={teddyNotesMessage}
                            onChange={(e) => setTeddyNotesMessage(e.target.value)}
                        />
                        <Counter>{teddyNotesMessage.length}/500</Counter>
                        <NotesButtonsContainer>
                            <NotesClose
                                src={CloseButton}
                                alt="Close Button"
                                onClick={() => {
                                    sendVagonMessage(spotifyConstants.UNITY_CLOSE_WEB_INTERFACE);
                                    window.Vagon?.focusIframe();
                                    props.setTeddyNoteOpen(false);
                                }}
                            />
                            {teddyNotesMessage?.length < 4 || teddyNotesName < 10 ? (
                                <NotesSendButton src={SendMessageButton} alt="Send Message Button" />
                            ) : (
                                <NotesSendButton
                                    src={SendMessageButtonActive}
                                    alt="Send Message Button Active"
                                    onClick={() => sendTeddyMessage()}
                                />
                            )}
                        </NotesButtonsContainer>
                        {showMessageWarning && (
                            <WarningMessage>
                                Message could not be sent, please reload the page and try again. Your session may have
                                expired.
                            </WarningMessage>
                        )}
                        {((teddyNotesMessage?.length < 4 && teddyNotesMessage?.length > 0) ||
                            (teddyNotesName?.length > 0 && teddyNotesName < 10)) && (
                            <WarningMessage>
                                Please add a name (min 3 chars.) and message (between 10-500 chars) before sending
                            </WarningMessage>
                        )}
                    </TeddyNoteTextContainer>
                </NotesModalWrapper>
            )}
            <Banner
                width={'auto'}
                zIndex="3000"
                message={'Thank You! Your message to Teddy has been sent!'}
                type={'MESSAGE'}
                openBanner={successMessageSent}
                setOpenBanner={setSuccessMessageSent}
                topDisplay
                showCloseButton
                gameScreen
                timer={8000}
            />
            <Banner
                width={'auto'}
                zIndex="3000"
                message={'Post a photo with Teddy to your socials and tag @teddyswims #teddyslounge'}
                type={'MESSAGE'}
                openBanner={props.openTeddySelfiePopup}
                setOpenBanner={props.setOpenTeddySelfiePopup}
                topDisplay
                showCloseButton
                gameScreen
                timer={10000}
            />
        </>
    );
}

export default SpotifyInterface;

const SpotifyLogoIcon = styled.img`
    cursor: pointer;
    height: 70px;
    position: fixed;
    /* top: 0;
    left: 0; */
    z-index: 1000;
    background: linear-gradient(180deg, rgba(27, 27, 35, 0.5) 0%, rgba(27, 27, 35, 0) 100%);
    @media (max-width: 960px) {
        height: 60px;
    }
    @media (max-width: 400px) {
        height: 7%;
    }

    transform: ${(props) => (props.landscape ? 'rotate(-90deg)' : 'none')};
    ${(props) => (props.landscape ? 'bottom: 0; left: 0;' : 'top: 0; right: 70px;')}
`;

const BannerWarning = styled.div`
    display: flex;
    width: 80px;
    height: 80px;
    margin: 1rem;
    align-content: center;
    justify-content: center;

    @media (max-width: 960px) {
        margin: 0.5rem;
        width: 50px;
        height: 100%;
    }
`;

const BannerContainer = styled.div`
    transform-origin: bottom right;
    ${(props) => (props.landscape ? 'top: -100px; right: 0;' : 'bottom: 0; left: 0;')}
    width: ${(props) => (props.landscape ? '99vh' : '100%')};
    transform: ${(props) => (props.landscape ? 'rotate(-90deg)' : 'none')};

    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 1000;
`;
const BannerTextContainer = styled.div`
    flex-grow: 1;
    color: white;
    text-align: left;
    margin: 1.5rem;

    @media (max-width: 960px) {
        margin: 0.6rem;
        text-align: center;
    }
`;

const BannerTitle = styled.div`
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: left;
    color: #fff;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    letter-spacing: 1.4px;
    opacity: 0.5;
    @media (max-width: 960px) {
        text-align: center;
        font-size: 14px;
    }
`;

const BannerSubtitle = styled.div`
    color: #fbfcff;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    & > strong {
        color: #ffe664;
        font-weight: 700;
    }

    @media (max-width: 960px) {
        font-size: 14px;
    }
`;

const BannerButton = styled.img`
    height: 80%;
    cursor: pointer;
    margin-right: 3rem;

    @media (max-width: 960px) {
        height: 50px;
    }
`;

const DiscModalWrapper = styled.div`
    transform: ${(props) => (props.landscape ? 'rotate(-90deg)' : 'none')};
    position: fixed;
    width: ${(props) => (props.landscape ? '100vh' : '100%')};
    height: 100vh;
    background: rgba(27, 27, 35, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const DiscModalContainer = styled.div`
    border: 4px solid rgba(190, 174, 255, 0.2);
    background: rgba(27, 27, 35, 0.75);
    padding: 2rem;
    text-align: center;
    position: relative;
    z-index: 1000;
    max-width: 500px;
    width: 90%;
    height: 250px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

const DiscModalTitle = styled.h2`
    color: #fbfcff;
    text-align: center;
    font-family: 'Signika Negative', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const DiscModalText = styled.p`
    color: #ced1d9;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    & > strong {
        color: #ffe664;
        font-weight: 700;
    }
`;

const DiscModalButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const DiscModalButton = styled.img`
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    @media (max-width: 960px) {
        height: 60%;
        width: 60%;
        padding-left: 0rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        height: 80%;
        width: 80%;
    }
`;

const PlaylistIconImg = styled.img`
    height: 15px;
    margin-right: 8px;
    margin-bottom: -4px;
`;

const SpotifyPlayerContainer = styled.div`
    position: fixed;
    transform-origin: bottom right;
    ${(props) => (props.landscape ? 'top: -100px; right: 0;' : 'bottom: 0; left: 0;')}
    width: ${(props) => (props.landscape ? '100vh' : '100%')};
    height: ${(props) => (props.landscape ? '100px' : '100px')};
    transform: ${(props) => (props.landscape ? 'rotate(-90deg)' : 'none')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(27, 27, 35, 0.5);
    z-index: 1000;
`;

const AlbumImagePlayer = styled.img`
    height: 100px;
    margin-right: 10px;
`;

const AlbumImage = styled.img`
    height: 100px;
    margin-right: 10px;

    @media (max-width: 960px) {
        height: 50px;
    }
`;

const TextContainer = styled.div`
    flex: 1;
    padding-left: 1rem;

    @media (max-width: 960px) {
        padding-left: 5px;
    }
`;

const Playlist = styled.h3`
    color: #beaeff;
    font-family: 'Barlow', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    @media (max-width: 960px) {
        font-size: 10px;
        padding-top: 5px;
    }
`;

const SongTitle = styled.h1`
    overflow: hidden;
    color: #fbfcff;
    text-overflow: ellipsis;
    font-family: 'Barlow', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 5px;
    @media (max-width: 960px) {
        font-size: 16px;
    }
`;
const ArtistName = styled.p`
    color: #ced1d9;
    font-family: 'Barlow', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
`;

const PlayButtonsContainer = styled.div`
    display: flex;
    align-items: stretch;
    padding-top: 10px;
    margin-right: 1.5rem;

    @media (max-width: 425px) {
        flex-direction: ${(props) => (props.landscape ? 'row' : 'column-reverse')};
        padding: 5px;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
    }
`;

const FwdButton = styled.img`
    flex: 1;
    height: 100%;
    padding: 10px 0 10px 0;
    margin-left: 5px;
    border: none;
    cursor: pointer;

    @media (max-width: 960px) {
        padding: 5px 0 0 10px;
    }
    @media (max-width: 425px) {
        /* padding: 5px 0 0 0; */
    }
`;
const PauseButton = styled.img`
    height: 100%;
    flex: 1;
    padding: 5px;
    padding: 5px 5px 5px 0;
    /* margin-left: 5px; */
    margin-right: 10px;
    border: none;
    cursor: pointer;

    @media (max-width: 960px) {
        padding: 0 10px 0 0;
    }
    @media (max-width: 425px) {
        margin-right: 0px;
        padding: 5px 0 0 0;
    }
`;

// Teddy Notes container css

const NotesModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 35, 0.75);
    backdrop-filter: blur(17.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    flex-direction: column;
`;

const TeddyNoteTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 80%;
    @media (max-width: 768px) {
        width: 95%;
    }
`;
const NoteInput = styled.textarea`
    height: 220px;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 18px;
    font-style: italic;
    font-family: 'Barlow', sans-serif;
    color: #9da0a7;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(27, 27, 35, 0.5);
    backdrop-filter: blur(50px);

    @media (max-height: 900px) {
        font-size: 16px;
        height: 100px;
    }

    ::placeholder {
        color: #9da0a7;
        font-family: 'Barlow', sans-serif;
        font-size: 18px;
        font-style: italic;
        line-height: 22px;
    }
    :focus {
        outline: none !important;
        border: 1px solid grey;
        box-shadow: 0 0 10px grey;
    }
`;

const NameInput = styled.input`
    flex: 1;
    background: none;
    border: none;
    outline: none;
    padding-left: 2rem;
    font-size: 18px;
    font-style: italic;
    font-family: 'Barlow', sans-serif;
    color: #9da0a7;
    @media (max-width: 960px) {
        font-size: 12px;
        padding-left: 10px;
        height: 30px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 14px;
        padding-left: 20px;
    }

    ::placeholder {
        color: #9da0a7;
        opacity: 50%;
        @media (max-width: 1440px) {
            font-size: 14px;
        }
    }
`;
const NotesSendButton = styled.img`
    margin: 0 0 0 1rem;
    height: 50px;
    @media (max-width: 425px) {
        margin: 2rem 0 0 0;
    }
    @media (max-width: 900px) {
        height: 40px;
    }
`;
const NotesClose = styled.img`
    height: 50px;
    @media (max-width: 425px) {
        margin: 1rem 0 0 0;
    }
    @media (max-width: 900px) {
        height: 40px;
    }
`;
const NotesButtonsContainer = styled.div`
    display: flex;
    align-items: stretch;
    padding-top: 10px;
    justify-content: flex-end;

    @media (max-width: 425px) {
        flex-direction: ${(props) => (props.landscape ? 'row' : 'column-reverse')};
        padding: 5px;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
    }
    @media (max-width: 900px) {
        padding-top: 0px;
    }
`;
const MessageModalTitle = styled.h2`
    color: #fbfcff;
    text-align: center;
    font-family: ${setFont.signikaNegative};
    font-size: 26px;
    font-weight: 700;
    @media (max-width: 900px) {
        margin: 0 0 1rem 10px;
        font-size: 20px;
        padding-top: 2.5rem;
    }
`;

const MessageModalSubtitle = styled.p`
    color: #fbfcff;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 2rem 0;
    @media (max-width: 900px) {
        margin: 0 0 1rem 5px;
        font-size: 14px;
    }
    & > strong {
        color: #ffe664;
        font-weight: 700;
    }
`;
const WarningMessage = styled.p`
    /* color: white; */
    color: #600c8e;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-top: 2rem;
    padding: 10px 5px 5px 5px;
    border-radius: 15px;
    border: 2px solid #a480b0;
    background: #faedff;
`;
const Counter = styled.p`
    color: #9da0a7;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1.4px;
    text-align: left;
    margin: 5px 0 0 5px;
    padding: 0;
    @media (max-width: 425px) {
        font-size: 12px;
    }
`;
const CloseIconContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    @media (max-width: 425px) {
        margin: 0.3rem;
    }
`;
const CheckmarkImg = styled.img`
    height: 30px;
    margin: 20px;
`;

const InputIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 15px 3px 15px;
    background: rgba(255, 255, 255, 0.1);
    @media (max-width: 425px) {
        padding: 8px 10px 0px 10px;
    }
`;
const InputSection = styled.div`
    background: rgba(27, 27, 35, 0.9);
    display: flex;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0.2);
    flex-shrink: 0;
    flex: 1;

    p {
        color: white;
        font-family: 'Barlow', sans-serif;
        font-size: 12px;
        padding: 2px 0 0 0;
        margin: 0;
        font-weight: 700;
        line-height: 22px;

        @media (max-width: 425px) {
            font-size: 10px;
        }
    }
    @media (max-width: 425px) {
        font-size: 10px;
    }
`;
