import React from 'react';
import styled from 'styled-components';
import { setColor } from '../../../assets/styles/styles';
import LandingPageContainer from '../components/LandingPageContainer';
import HeroContainer from '../components/HeroContainer';
import FeatureSection from './components/FeatureSection';
import ReviewSection from './components/ReviewSection';
import Footer from '../components/FooterSection';
import MobileIcon from '../components/MobileIcons';
// import HeroVideo from '/videos/landingPages/Pro_Hero.mp4';
import ButtonSection from './components/ButtonSection';
import GallerySection from './components/GallerySection';
import ExperienceSection from './components/ExperienceSection';
import TranscendSection from './components/TranscendSection';
// import FooterVideo from '/videos/landingPages/live-footer-v3.mp4';

const Pro = () => (
    <LandingPageContainer showOutline={false}>
        <MobileIcon />
        <HeroContainer
            videoUrl={'/videos/landingPages/Pro_Hero.mp4'}
            buttonText={'Create My Intraverse'}
            description={'Amplify How You Connect With Your Online Community'}
            arrowText={'Join The Intraverse'}
            pro
            title={
                <>
                    Build Your Virtual
                    <br /> World <Highlighted>Today</Highlighted>
                </>
            }
            fadeColor={'#110e25'}
        />
        <GallerySection />
        <ExperienceSection />
        <ReviewSection />
        <FeatureSection />
        <TranscendSection />
        <ButtonSection videoUrl={'/videos/landingPages/live-footer-v3.mp4'} buttonText="Get Early Access" />
        <Footer
            title={
                <>
                    Stay <Highlighted>Updated</Highlighted>
                </>
            }
            pro
        />
    </LandingPageContainer>
);

export default Pro;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;
