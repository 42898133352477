/* eslint-disable */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setColor, setFont } from '../../../assets/styles/styles';

export default function Footer({ color, footerText }) {
    return (
        <FooterContainer>
            <PrivacyLine color={color} footerText={footerText}>
                {footerText
                    ? footerText
                    : `©${new Date().getFullYear()} TerraZero Technologies, Intraverse, and any associated logos are trademarks, service marks, and/or
          registered trademarks of TerraZero Technologies.`}
            </PrivacyLine>
            <FooterMenu>
                <FooterItems>
                    <a
                        style={{ textDecoration: 'none' }}
                        href="https://terrazero.com/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FooterItem color={color}>privacy notice</FooterItem>
                    </a>
                    <a
                        style={{ textDecoration: 'none' }}
                        href="https://terrazero.com/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FooterItem color={color}>Terms of service</FooterItem>
                    </a>
                    <FooterItem style={{ marginRight: 0 }} color={color}>
                        cookie preferences
                    </FooterItem>
                </FooterItems>
            </FooterMenu>
        </FooterContainer>
    );
}

Footer.propTypes = {
    /**
     * What text color to use
     */
    color: PropTypes.string.isRequired,
    footerText: PropTypes.string.isRequired,
};

const FooterMenu = styled.div`
    display: flex;
    align-items: center;
`;
const FooterItems = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-evenly;
    padding-bottom: 1rem;
    padding-top: 0rem;

    @media (max-width: 320px) {
        flex-direction: column;
    }
`;

const FooterItem = styled.div`
    color: ${(props) => (props.color ? props.color : setColor.circularProgress)};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-items: center;
    margin-right: 50px;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 0.7rem;
        margin: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 0.9rem;
        margin: 10px;
        overflow: auto;
    }
`;

const PrivacyLine = styled.p`
    color: ${(props) => (props.color ? props.color : setColor.iconColor)};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.7rem;

    @media (max-width: 768px) {
        font-size: 14px;
        margin: 0rem 0.5rem;
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
        margin: 0rem 2rem;
        width: 95%;
    }
`;

const FooterContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    padding: 25px 30px;

    @media (max-width: 768px) {
        padding: 5px 5px;
        position: relative;
        bottom: 0;
        left: 0;
        right: 1rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 10px 5px;
    }

    @media (max-height: 700px) {
        padding: 5px;
        position: relative;
    }
`;
