import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { setColor } from '../../../../../../assets/styles/styles';
import { default as Button } from '../../../../../../components/common/button/Button';
import Typography from '../../../../../../components/common/text/Typography';
import { ReactComponent as DropdownIcon } from '../../../../../../assets/images/icons/Chevron.svg';
import SurveyDropdown from '../SurveyDropdown';

const AddSurveyOptions = ({ getOptionsData }) => {
    const [optionName, setOptionName] = useState('');
    const [points, setPoints] = useState('');
    const [selectedOptionCorrect, setSelectedOptionCorrect] = useState('');
    const [isDropdownOpenCorrect, setIsDropdownOpenCorrect] = useState(false);
    const [finalOptions, setFinalOptions] = useState([]);
    const [isHideCancel, setIsHideCancel] = useState(false);

    const options = [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
    ];

    const handleFieldChange = (e, field) => {
        if (field === 'optionName') {
            setOptionName(e.target.value);
        }
        if (field === 'points') {
            setPoints(e.target.value);
        }
    }

    const toggleDropdownCorrect = () => {
        setIsDropdownOpenCorrect(!isDropdownOpenCorrect);
    }

    const handleOptionClickCorrect = (option) => {
        setSelectedOptionCorrect(option);
        setIsDropdownOpenCorrect(false);
    }


    const handleCancel = () => {
        setOptionName('');
        setPoints('');
        setSelectedOptionCorrect('');
        setIsHideCancel(false);
    };

    const handleAddOption = () => {
        if(optionName !== '' && points !== '' && selectedOptionCorrect !== ''){
            const optionData = {
                name: optionName,
                points,
                isCorrect: selectedOptionCorrect,
                image: null
            };
            finalOptions.push(optionData);
            const updatedOptions = [...finalOptions];
            setFinalOptions(updatedOptions);
            getOptionsData(updatedOptions);
            setOptionName('');
            setPoints('');
            setSelectedOptionCorrect('');
        }  
    }

    useEffect(() => {
        if(optionName !== '' || points !== '' || selectedOptionCorrect !== ''){
            setIsHideCancel(true);
        }
        if(optionName === '' || points === '' || selectedOptionCorrect === ''){
            setIsHideCancel(false);
        }
    }); 

    return (
        <>
            <OptionTable>
                <TableRow>
                    <TableColumn>
                        <Label>Option Name</Label>
                        <InputBox
                            type="text"
                            id="optionName"
                            value={optionName}
                            placeholder="Enter option name/title"
                            onChange={(e) => handleFieldChange(e, 'optionName')}
                        />
                    </TableColumn>
                </TableRow>

                <TableRow>
                    <TableColumn>
                        <Label>Is Correct?</Label>
                        <SurveyDropdown
                            options={options}
                            selectedOption={selectedOptionCorrect}
                            onOptionSelect={handleOptionClickCorrect}
                            toggleDropdown={toggleDropdownCorrect}
                            isDropdownOpen={isDropdownOpenCorrect}
                            title="Select Options"
                            trueFalseOptions={'true'}
                        />
                    </TableColumn>
                </TableRow>

                <TableRow>
                    <TableColumn>
                        <Label>Points</Label>
                        <InputBox
                            type="number"
                            id="points"
                            value={points}
                            placeholder="Enter points for the option"
                            onChange={(e) => handleFieldChange(e, 'points')}
                        />
                    </TableColumn>
                </TableRow>

                {finalOptions.length !== 0 && (
                    <>
                    <TableRow>
                        <TableColumn>
                            <TitleText>
                                Option(s) Added {finalOptions.length}
                            </TitleText>
                        </TableColumn>
                        <TableColumn>
                            {finalOptions.map((option, index) => (
                                <React.Fragment key={index}>
                                    <TitleText>
                                        Option Name: {option.name}
                                    </TitleText>
                                    <TitleText>
                                        Correct or Not: {option.isCorrect}
                                    </TitleText>
                                    <TitleText>
                                        Points: {option.points}
                                    </TitleText>
                                    {finalOptions.length >= 2 && (
                                        <Line />
                                    )}
                                </React.Fragment>
                            ))}
                        </TableColumn>
                    </TableRow>
                    </>
                )}
                
                <TableRow>
                    <TableColumn>
                        <Button
                            variant={'OutlinedButton'}
                            buttonWidth={'100%'}
                            label={finalOptions.length === 0 ? 'SAVE OPTION' : 'SAVE MORE OPTION'}
                            margin={'1rem 0 0 0'}
                            onClick={handleAddOption}
                        />  
                    </TableColumn>
                    {isHideCancel && (
                        <TableColumn>
                            <Button
                                variant={'OutlinedButton'}
                                buttonWidth={'100%'}
                                label={'CANCEL'}
                                margin={'1rem 0 0 0'}
                                onClick={handleCancel}
                            />  
                        </TableColumn>
                    )}
                </TableRow>
            </OptionTable>
        </>
    );
};

export default AddSurveyOptions;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const OptionTable = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Line = styled.hr`
    border-bottom: 0.5px solid ${setColor.grey1};
    margin: 10px 0;
`;

const DropdownButton = styled.button`
    display: flex;
    font-family: 'Barlow-Regular', Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;
const TitleText = styled.p`
    font-family: 'Barlow-Regular', Helvetica;
    color: #767676;
    font-weight: 500;
`;