import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DropdownIcon } from '../../../../../assets/images/icons/Chevron.svg';
import { setColor } from '../../../../../assets/styles/styles';

const SurveyDropdown = ({ trueFalseOptions, options, selectedOption, onOptionSelect, toggleDropdown, isDropdownOpen, title }) => {
    return (
        <DropdownButton onClick={toggleDropdown} isopen={isDropdownOpen.toString()}>
            <TitleText>
                {selectedOption === '' ? title : selectedOption}
            </TitleText>
            <StyledDropdownIcon isopen={isDropdownOpen.toString()} />
            {isDropdownOpen && (
                <DropdownContent>
                    {options.map(option => (
                        <DropdownItem
                            key={option.value}
                            onClick={() => onOptionSelect(option.value)}
                            isSelected={selectedOption === option.value}
                        >
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownContent>
            )}
        </DropdownButton>
    );
};

export default SurveyDropdown;

const DropdownButton = styled.button`
    display: flex;
    font-family: 'Barlow-Regular', Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;

const TitleText = styled.p`
    font-family: 'Barlow-Regular', Helvetica;
    color: #767676;
    font-weight: 500;
`;