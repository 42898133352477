/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Iconify from '../icon/Iconify';

const CornerIconButton = ({
    iconColor,
    iconSize,
    mobileSize,
    unActiveIcon,
    activeIcon,
    func,
    showActiveIcon,
    verticalPlacement,
    horizontalPlacement,
}) => {
    const [showActiveIconState, setShowActiveIconState] = useState(showActiveIcon);

    useEffect(() => {
        setShowActiveIconState(showActiveIcon);
    }, [showActiveIcon]);

    return (
        <CornerButton
            verticalPlacement={verticalPlacement}
            horizontalPlacement={horizontalPlacement}
            onClick={() => {
                setShowActiveIconState((value) => !value);
                func();
            }}
        >
            <ResponsiveIconContainer iconSize={iconSize} mobileSize={mobileSize}>
                <Iconify
                    icon={showActiveIconState ? activeIcon : unActiveIcon}
                    mobileSize={mobileSize}
                    size={iconSize}
                    Color={iconColor}
                />
            </ResponsiveIconContainer>
        </CornerButton>
    );
};

export default CornerIconButton;

CornerIconButton.defaultProps = {
    horizontalPlacement: 'RIGHT',
    verticalPlacement: 'TOP',
    iconSize: 250,
    mobileSize: 150,
    iconColor: 'white',
    showActiveIcon: true,
    unActiveIcon: 'fa-volume-low',
    activeIcon: 'fa-volume-low',
};

CornerIconButton.propTypes = {
    iconSize: PropTypes.number,
    mobileSize: PropTypes.number,
    iconColor: PropTypes.string,
    unActiveIcon: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
    func: PropTypes.any,
    showActiveIcon: PropTypes.bool,
    verticalPlacement: PropTypes.oneOf(['BOTTOM', 'TOP']),
    horizontalPlacement: PropTypes.oneOf(['RIGHT', 'LEFT']),
};

const CornerButton = styled.button`
    width: auto;
    height: auto;
    cursor: pointer;
    border: none;
    padding: 15px 15px 10px 15px;
    z-index: 3000;
    background: transparent;
    // background: linear-gradient(180deg, rgba(27, 27, 35, 0.75) 0%, rgba(27, 27, 35, 0) 100%);
    ${(props) => (props.verticalPlacement === 'BOTTOM' ? 'bottom: 0;' : 'top: 0;')}
    ${(props) => (props.horizontalPlacement === 'LEFT' ? 'left: 0;' : 'right:0;')}

  @media (max-width: 960px) {
        padding: 10px 10px 5px 10px;
    }

    @media (max-width: 400px) {
        transform: rotate(-90deg);
        top: initial;
        right: initial;
        bottom: -5px;
        left: 0;
    }
`;

const ResponsiveIconContainer = styled.div`
    width: ${(props) => `${props.size}px`};
    max-width: ${(props) => `${props.size}px`};

    @media (max-width: 960px) {
        max-width: ${(props) => `${props.mobileSize}%`};
    }
`;
