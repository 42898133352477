import axiosInstance, {API_POST} from '../../../../../config/axiosConfig';

export async function getAllForms() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/forms`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getFormInfoById(id) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/forms/${id}`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getFormQuestionTypes() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/question-type`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getAllFormTypes() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/form-type`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getFormStyleTypes() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/form-style-type`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function updateQuestion(formId, questionId, questionBody, formQuestionTitle, formQuestionTypeId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/forms/${formId}/questions/${questionId}`;
        
        const respAxios = await axiosInstance.put(url, {
            questionBody,
            formQuestionTitle,
            formQuestionTypeId,
        });
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function updateOption(surveyId, questionId, optionId, points, name, isCorrect) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/forms/${surveyId}/questions/${questionId}/options/${optionId}`;
        const respAxios = await axiosInstance.put(url, {
            points,
            name,
            isCorrect,
        });
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function addSurvey(token, surveyName, questionBody, selectedOptionType, 
    selectedOptionStyle, selectedOptionAnonymous, selectedOptionMustFinish, 
    selectedOptionPrvAllowed, questionSets,
    minPassingScore, quizTime, maxPoints, 
    selectedOptionFeedback, selectedOptionRdmQuePool, 
    selectedOptionTimeLimit) {
    try {
        const transformedArray = {
            "questions": questionSets.map(item => ({
                "name": item.questionData[0].name,
                "body": item.questionData[0].body,
                "formQuestionType": item.questionData[0].formQuestionType,
                "image": item.questionData[0].image,
                "answers": item.optionData.map(option => ({
                    "name": option.name,
                    "isCorrect": option.isCorrect === "true",
                    "image": option.image,
                    "points": parseInt(option.points, 10)
                }))
            }))
        };

        // Building the quiz options object
        let quizOptions = null;
        if (selectedOptionType === 1) {
            quizOptions = {
                instantFeedback: selectedOptionFeedback,
                randomQuestionPool: selectedOptionRdmQuePool,
                timeLimit: selectedOptionTimeLimit,
                minimumPassingScore: minPassingScore,
                time: quizTime,
                maxPoints,
            };
        }
        // Building the final data object
        const data = {
            name: surveyName,
            body: questionBody,
            form_type_id: selectedOptionType,
            form_style_type_id: selectedOptionStyle,
            anonymous: selectedOptionAnonymous,
            mustFinish: selectedOptionMustFinish,
            previousQuestionAllowed: selectedOptionPrvAllowed,
            questions: transformedArray.questions,
        };

        // Conditionally adding quiz options
        if (quizOptions) {
            data.quiz_options = quizOptions;
        }

        const response = await API_POST(
            `${process.env.REACT_APP_CORE_API_URI}/web/forms`,
            data,
            token,
            'POST'
        );
        
        return response;
    } catch (err) {
        console.error('Error:', err);
        return err;
    }
}

export async function deleteSurvey(surveyId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/forms/${surveyId}`;
        const respAxios = await axiosInstance.delete(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function deleteQuestion(surveyId, questionId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/forms/${surveyId}/questions/${questionId}`;
        const respAxios = await axiosInstance.delete(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function deleteOption(surveyId, questionId, optionId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/forms/${surveyId}/questions/${questionId}/options/${optionId}`;
        const respAxios = await axiosInstance.delete(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function addQuestionToSurvey(token, surveyId, finalQuestionsSurveysData, finalOptionsSurveysData) {
    try {        
        const modifiedData = finalOptionsSurveysData.map((item) => {
            return {
                points: item.points,
                option: item.name,
                image_key: null,
                isCorrect: item.isCorrect,
            };
        });

        const data = {
            questionBody: finalQuestionsSurveysData.body,
            formQuestionTitle: finalQuestionsSurveysData.name,
            formQuestionTypeId: finalQuestionsSurveysData.formQuestionType,
            answers: modifiedData,
            imageId: null
        };

        const response = await API_POST(
            `${process.env.REACT_APP_CORE_API_URI}/web/forms/${surveyId}/questions`,
            data,
            token,
            'POST'
        );
            
        return response;
    } catch (err) {
        console.error('Error:', err);
        return err;
    }
}


export async function addOptionToQuestion(token, surveyId, questionId, finalOptionsSurveysData) {
    try {        
        const modifiedData = finalOptionsSurveysData.map((item) => {
            return {
                points: item.points,
                name: item.name,
                image: null,
                isCorrect: item.isCorrect,
            };
        });
     
        const responses = await Promise.all(
            modifiedData.map(element => {
                return API_POST(
                    `${process.env.REACT_APP_CORE_API_URI}/web/forms/${surveyId}/questions/${questionId}/options`,
                    element,
                    token,
                    'POST'
                );
            })
        );
        return responses;
    } catch (err) {
        console.error('Error:', err);
        return err;
    }
}