const defaultState = {
    projects: null,
};

const ProjectReducer = (state, action) => {
    switch (action.type) {
        case 'PROJECT_SAVE': {
            return {
                ...state,
                projects: action.payload.projects,
            };
        }
        case 'PROJECT_DELETE': {
            return defaultState;
        }

        default:
            if (state === undefined) {
                return defaultState;
            }
            return state;
    }
};

export default ProjectReducer;
