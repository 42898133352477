// src/utils/importImages.js
const importAll = (r) => {
    const images = {};
    // eslint-disable-next-line array-callback-return
    r.keys().map((item, index) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
};

const images = importAll(require.context('../assets/clientLogos', false, /\.(png|jpe?g|svg)$/));

export default images;
