import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import styled from 'styled-components';

export default function Iconify({ icon, size, mobileSize, onClick, cursor, Color = 'white', iconMargin, style }) {
    return (
        <ResponsiveIcon
            iconMargin={iconMargin}
            Color={Color}
            icon={icon}
            onClick={onClick}
            size={size}
            mobileSize={mobileSize}
            style={style}
            cursor={cursor}
        />
    );
}

Iconify.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    size: PropTypes.string,
    mobileSize: PropTypes.number,
    onClick: PropTypes.func,
    Color: PropTypes.any,
    iconMargin: PropTypes.string,
    style: PropTypes.object,
    cursor: PropTypes.string,
};

Iconify.defaultProps = {
    icon: 'mdi:account',
    size: '60px',
    mobileSize: '30px',
    onClick: null,
    Color: 'white',
    iconMargin: '',
    style: { marginLeft: '10px' },
    cursor: 'default',
};

const ResponsiveIcon = styled(Icon)`
    font-size: ${(props) => props.size};
    color: ${(props) => props.Color};
    margin: ${(props) => (props.iconMargin ? props.iconMargin : '0 auto')};
    vertical-align: text-bottom;
    cursor: ${(props) => (props.cursor ? props.cursor : 'default')};

    @media (max-width: 960px) {
        font-size: ${(props) => (props.mobileSize ? props.mobileSize : props.size)};
    }
`;
