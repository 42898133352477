import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getTableInfo } from '../../../../api/AdminController';
import Title from '../../components/Title';
import DataTableFiles from '../../../../components/common/dataTableFiles/DataTableFiles';
import { setColor } from '../../../../assets/styles/styles';
import { getFileUrl } from '../../../../api/FilesController';
import usernameIcon from '../../../../assets/images/icons/username.svg';
import FileUploader from './FileUploader';
import FilePreviewer from './FilePreviewer';
import Button from '../../../../components/common/button/Button';
import Body from '../../layout/Body';

const DynamicTableFiles = () => {
    const tableName = 'files';
    const token = useSelector((state) => state?.user?.token);
    const [tableData, setTableData] = useState([]);
    const [allRecords, setAllRecords] = useState([]);

    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('all');

    const [searchInput, setSearchInput] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [fileUrl, setFileUrl] = useState('');

    const [isUploaderOn, setIsUploaderOn] = useState(false);
    const [uploaderError, setUploaderError] = useState(false);
    
    const [isLoading, setIsLoading] = useState(true);

    const [primaryKey, setPrimaryKey] = useState(null);
    const [isDeletedColumn, setIsDeletedColumn] = useState('NO');
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const [headers, setHeaders] = useState(Object.keys(tableData));

    const goToRecord = (row, backUrl = false) => {
        setSelectedRow(row);
        setIsModalOpen(true);
        navigate(
            `/console/record_details?table=${tableName}&primary_key=${primaryKey}&id=${row[primaryKey]}&back_url=/console/files_dashboard`
        );
    };

    const renderRow = (record) => {
        return Object.values(record);
    };

    const fetchTableInfo = async () => {
        const response = await getTableInfo(token, tableName);
        if (response.status === 200) {
            const keys = Object.values(response.data.columns_data);
            let isDeletedFilter = false;
            for (let i = 0; i < keys.length; i += 1) {
                const key = keys[i];
                if (key.column_name === 'is_deleted') {
                    isDeletedFilter = true;
                    break;
                }
            }
            if (isDeletedFilter) {
                setIsDeletedColumn(true);
                const filteredRecords = await response.data.records.filter((record) => {
                    return record.is_deleted !== true;
                });
                setAllRecords(filteredRecords);
                setTableData(filteredRecords);
            } else {
                const s3Folders = {
                    all: true,
                };
                for (let i = 0; i < response.data.records.length; i += 1) {
                    const record = response.data.records[i];
                    s3Folders[record.folder_name] = true;
                }
                setFolders(Object.keys(s3Folders));
                setAllRecords(response.data.records);
                setTableData(response.data.records);
            }            
            setHeaders(keys);
            setPrimaryKey(response.data.primary_key);
            setIsLoading(false);
        } else {
            console.log(`Error querying record from table ${tableName}`);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleInput = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    const queryResults = (e) => {
        setIsLoading(true);
        e.preventDefault();
        e.stopPropagation();
        filterResults();
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleModalInfo = async (row) => {
        if (isModalOpen) {
            setModalError(false);
            const newModalInfo = {
                ...row,
                fileTypeModal: false,
                fileImageUrl: false,
                fileLinkUrl: false,
            };
            const response = await getFileUrl(row.id, token);

            if (response.status === 200) {
                setFileUrl(response.data);
            } else {
                setModalError(true);
            }

            if (row.file_type.includes('image')) {
                newModalInfo.fileTypeModal = 'image';
            } else if (row.file_type.includes('video')) {
                newModalInfo.fileTypeModal = 'video';
            }
            else {
                newModalInfo.fileTypeModal = 'other';
                newModalInfo.fileImageUrl = false;
            }
            setModalInfo(newModalInfo);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchTableInfo();
    }, []);

    const modalLabels = [
        'id',
        'original_name',
        'file_name',
        'created_at',
        'user_id',
        'file_type',
        'folder_name',
        'usage',
    ];

    const filterResults = async (e) => {
        setIsLoading(true);
        let newRecords = [];
        const columns = await headers.map(obj => obj.column_name)
        columns.shift();
        for (let i = 0; i < allRecords.length; i+=1) {
          if (searchInput === "") {
            newRecords = allRecords
            break
          }  
          const record = allRecords[i];
          const idCheck = {}

          for (let j = 0; j < columns.length; j+=1) {
            const column = columns[j];
            const value = record[column];
            if (value) {
              if (typeof value === "string") {
                if (value.toLowerCase().includes(searchInput.toLowerCase())) {
                    if (!idCheck[record.id]) {
                        idCheck[record.id] = true
                        newRecords.push(record)
                    }
                }
              }
            }
          }
        }

        if (selectedFolder !== "all") {
            newRecords = await newRecords.filter(record => {
                return record.folder_name === selectedFolder;
            })
            setTableData(newRecords);
        } else {
            setTableData(newRecords);
        }
        setIsLoading(false);
    }

    return (
        <>
            {isUploaderOn ? (
                <>
                    <FileUploader
                        setIsUploaderOn={setIsUploaderOn}
                    />
                </>
            ) : (
                <></>
            )}
            {isModalOpen ? (
                <FilePreviewer
                    handleCloseModal={handleCloseModal}
                    setIsUploaderOn={setIsUploaderOn}
                    modalInfo={modalInfo}
                    fileUrl={fileUrl}
                    modalLabels={modalLabels}
                />
            ) : (
                <></>
            )}
            <Title title={`Table ${tableName}`} description={'Columns information and record on table'} />

            <Row>
                {/* <input onChange={(e) => handleInput(e)} /> */}
                <img
                    src={usernameIcon}
                    alt="birthdayIcon"
                    style={{
                        margin: '5px',
                        color: setColor.iconColor3,
                        cursor: 'default',
                        height: '20px',
                        width: '20px',
                    }}
                />
                <InputContainer>
                    <Input
                        style={{ pointerEvents: 'auto' }}
                        readonly
                        type="text"
                        placeholder={searchInput}
                        value={searchInput}
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                        }}
                    />
                </InputContainer>
                <Button variant="PinkButton" label="Search" onClick={queryResults}/>
            </Row>

            <div>
                <InfoContainer>
                    <Text style={{ alignContent: 'center' }}>Folders: </Text>
                    <FolderIconsGrid>
                        {folders.map((folderName, i) => {
                            const active = folderName === selectedFolder;
                            return (
                                <Card 
                                key={uniqueId()}
                                onClick={() => {setSelectedFolder(folderName)}} isActive={active} 
                                >
                                    <p 
                                        key={uniqueId()}
                                    >{folderName}</p>
                                </Card>
                            );
                        })}
                    </FolderIconsGrid>
                </InfoContainer>
                <ButtonContainer>
                    <Button
                        exact='true'
                        onClick={() => {
                            setIsUploaderOn(!isUploaderOn);
                        }}
                        label="File Uploader"
                        margin={'0'}
                        fontSize={'14px'}
                    />
                    <Button
                            exact='true'
                            onClick={(e) => handleOpenModal(e)}
                            label="File Previewer"
                            variant="PinkButton"
                            margin={'0'}
                            fontSize={'14px'}
                    />
                </ButtonContainer>
            </div>
            <Body isLoading={isLoading}>

                <DataTableFiles
                    headers={headers}
                    data={tableData}
                    renderRow={renderRow}
                    goToRecord={goToRecord}
                    openModal={openModal}
                    isModalOpen={isModalOpen}
                    selectedRow={selectedRow}
                    handleOpenModal={handleOpenModal}
                    handleModalInfo={handleModalInfo}
                    // detailsComponent={RoomsDetails}
                />
            </Body>
        </>
    );
};

const Text = styled.p`
    text-align: left;
    height: 50px;
    color: ${setColor.inputText};
`;

const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 20px;
    position: sticky;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-gap: 20px;
`;

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    margin-right: 8px;
    border-radius: 10px;
    justify-content: center;
    background: ${setColor.purpleDark};
    cursor: pointer;
    height: 50px;
    align-items: center;
    padding: 5px;
    width: 300px;

    @media (max-width: 768px) {
        margin: 5px;
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin: 5px;
        width: 100%;
    }
`;

const FolderIconsGrid = styled.div`
    display: grid;
    padding-top: 20px;
    padding-bottom: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;


const Input = styled.input`
    flex: 1;
    border: none;
    background: none;
    width: 90%;
    height: 50px;
    color: white;
    margin-left: 8px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: ${`0 0 0 30px ${setColor.purpleDarkTwo} inset !important`};
        -webkit-text-fill-color: white !important;
    }
`;

const Card = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 6px;
    background-color: ${(props) => (props.isActive ? setColor.iconColor3 : setColor.onHoverTextcolor2)};
    cursor: pointer;
    color: ${(props) => (props.isActive ? setColor.blackColor : setColor.inputText)};
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    height: 60px;
    min-width: 200px;
    &:hover {
        background-color: ${setColor.iconColor3};
        color: ${setColor.black} ;
    }
    &:active {
        background-color: ${setColor.iconColor3};
        color: ${setColor.black};
    }
`;

export default DynamicTableFiles;
