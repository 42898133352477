import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { map, uniqueId } from 'lodash';
import { setColor, setFont } from '../../../assets/styles/styles';
import { ReactComponent as Close } from '../../../assets/images/icons/Close.svg';

const ChildTable = ({ headers, rowData, renderRow, requestType, onChildClose }) => {
    const [visibleRows, setVisibleRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalNumber, setTotalNumber] = useState(0);
    const ROWS_PER_PAGE = 5;

    useEffect(() => {
        setVisibleRows(rowData);
        setTotalNumber(rowData.length);
        setTotalPages(Math.ceil(rowData.length / ROWS_PER_PAGE));
        setVisibleRows(rowData.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE));
    }, [currentPage, rowData]);
   
    return (
        <MainCard>
            <ChildTableContainer>
                <thead>
                    <TableRow>
                    {headers.map((header, index) => {
                            if (typeof header !== "string") {
                                return (
                                    <TableHeader 
                                        key={uniqueId()}
                                    >
                                        <p
                                        key={uniqueId()}
                                        >{header.column_name}</p>
                                    </TableHeader>
                                )
                            }
                            return <TableHeader 
                                key={uniqueId()}
                            >{header}</TableHeader>
                        })
                    }
                    </TableRow>
                </thead>
                <tbody>
                    {visibleRows.map((row, index) => (
                        <StyledTableRow>
                            {renderRow(row).map((cell, idx) => {
                                return (  
                                    <TableCell 
                                        key={uniqueId()}
                                    >{cell}</TableCell>
                                )
                            })
                            }
                        </StyledTableRow>
                    ))}
                    <Footer>
                    <TableColumnLeft>
                    {visibleRows.length !== 0 && (
                            <Pagination>
                                <span>
                                    Page {currentPage} of {totalPages}
                                </span>
                                <PaginationButton
                                    onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </PaginationButton>
                                <PaginationButton
                                    onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </PaginationButton>
                            </Pagination>
                        )}
                    </TableColumnLeft>
                    
                </Footer>
                </tbody>
                
            </ChildTableContainer>
            <StyledCloseIcon onClick={onChildClose} />
        </MainCard>
    );
};

export default ChildTable;

const MainCard = styled.div`
    width: 100%;
    height: auto;
    position: relative;
`;

const StyledCloseIcon = styled(Close)`
   width: 20px;
    height: 20px;
    transform: rotate(180deg);
    position: absolute;
    top: 10px;
    right: -30px;
    cursor: pointer;
    margin-bottom: 30px;
`;

const ChildTableContainer = styled.table`
    width: 100%;
    height: auto;
    overflow-y: none;
    border-collapse: separate;
    border-spacing: 0 15px;
    border-radius: 10px;
    margin: 0 3rem 0 3rem;
    background-color: transparent;
    overflow-x: auto;
    border: 1px solid ${setColor.accent4};
    @media (max-width: 768px) {
        height: auto;
        overflow-y: auto;
    }
`;

const TableRow = styled.tr`
    text-align: center;
    height: 50px;
    color: ${setColor.inputText};
`;

const TableHeader = styled.th`
    font-size: 14px;
    text-align: left;
    color: #beaeff;
    background-color: transparent;
    padding: 0 0 0 1rem;
`;

const TableCell = styled.td`
    overflow-wrap: break-word;
    padding: 0.3rem;
    font-size: 15px;
    width: auto;
    text-align: left;
    &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

const StyledTableRow = styled.tr`
    background-color: ${setColor.onHoverTextcolor2};
    color: ${setColor.inputText};
`;

const Footer = styled.div`
    padding: 0 0 0 1rem;
    color: ${setColor.inputText};
    display: flex;
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText};
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled};
    }
`;
