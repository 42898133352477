import React, { useState } from 'react';
import styled from '@emotion/styled';
import { map, uniqueId } from 'lodash';
import { setColor } from '../../../assets/styles/styles';
import { ReactComponent as NotFoundIcon } from '../../../assets/images/icons/NotFound.svg';
import { ReactComponent as DropdownIcon } from '../../../assets/images/icons/Chevron.svg';
import ChildTable from './ChildTable';

const DataTable = ({
    headers,
    data,
    renderRow,
    openModal,
    isModalOpen,
    selectedRow,
    isHover,
    childViewData,
    renderRowChild,
    ChildTableHeader,
    requestType,
    onChildClose,
    setSelectedRowIndex,
    selectedRowIndex,
    selectedChildRowId,
    setSelectedChildRowId,
}) => {
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [searchInput, setSearchInput] = useState("");

    if (isHover === undefined) isHover = true;
    if (childViewData === undefined) childViewData = [];
    if (renderRowChild === undefined) renderRowChild = () => {};
    if (ChildTableHeader === undefined) ChildTableHeader = [];
    if (requestType === undefined) requestType = '';
    if (onChildClose === undefined) onChildClose = () => {};
    if (setSelectedRowIndex === undefined) setSelectedRowIndex = () => {};
    if (selectedRowIndex === undefined) selectedRowIndex = -1;
    if (selectedChildRowId === undefined) selectedChildRowId = '';
    if (setSelectedChildRowId === undefined) setSelectedChildRowId = () => {};
	console.log('selectedChildRowId', selectedChildRowId);
	const handleRowClick = (index, row) => {
		if (isHover) {
		  setSelectedRowIndex(index);
		  setSelectedRowData(row);
	
		  if (requestType === 'roomUsers') {
			setSelectedChildRowId(row.roomId);
		  }
		  openModal(row);
		}
	  };
  return (
    <TableContainer>
      <thead>
        <TableRow>
          {headers.map((header, index) => {
            if (typeof header !== "string") {
              return (
                <TableHeader key={uniqueId()}>
                  <p key={uniqueId()}>{header.column_name}</p>
                </TableHeader>
              );
            }
            return <TableHeader key={uniqueId()}>{header}</TableHeader>;
          })}
        </TableRow>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr key={uniqueId()}>
            <td key={uniqueId()} colSpan={headers.length} align="center">
              <NotFoundImage key={uniqueId()} alt="Not Found" />
            </td>
          </tr>
        ) : (
          data.map((row, index) => (
            <React.Fragment key={row.roomId || uniqueId()}>
              <StyledTableRow
			  onClick={() => handleRowClick(index, row)}
                requestType={requestType}
                isHover={isHover}
                childViewData={childViewData}
                key={row.roomId || uniqueId()}
                isSelected={row.roomId === selectedChildRowId}
              >
                {renderRow(row).map((cell, idx) => (
                  <TableCell key={idx}>{cell}</TableCell>
                ))}
              </StyledTableRow>
              {childViewData.length !== 0 && row.roomId === selectedChildRowId && (
                <tr key={`${row.roomId}-child`}>
                  <td colSpan={ChildTableHeader.length}>
                    <ChildTable
                      headers={ChildTableHeader}
                      renderRow={renderRowChild}
                      rowData={childViewData}
                      requestType={requestType}
                      onChildClose={onChildClose}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        )}
      </tbody>
    </TableContainer>
  );
};

export default DataTable;

const TableContainer = styled.table`
  width: 100%;
  height: 70vh;
  overflow-y: none;
  border-collapse: separate;
  border-spacing: 0 15px;
  background-color: transparent;
  overflow-x: auto;

  @media (max-width: 768px) {
    height: auto;
    overflow-y: auto;
  }
`;

const TableRow = styled.tr`
  margin-bottom: 15px;
  text-align: center;
  height: 50px;
  color: ${setColor.inputText};
`;
const TableHeader = styled.th`
  font-size: 17px;
  padding: 10px;
  text-align: left;
  color: #beaeff;
  background-color: transparent;
`;

const NotFoundImage = styled(NotFoundIcon)`
  width: 300px;
  height: 300px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;
const TableCell = styled.td`
  overflow-wrap: break-word;
  font-size: 15px;
  padding: 10px;
  width: auto;
  text-align: left;
  &:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const StyledTableRow = styled.tr`
  background-color: ${setColor.onHoverTextcolor2};
  color: ${setColor.inputText};

  ${({ isHover, setColor, childViewData, isSelected }) => {
    // Handle cursor style
    let cursorStyle = 'default';
    if (!isHover && childViewData && childViewData.length === 0) {
      cursorStyle = 'not-allowed';
    } else if (isHover) {
      cursorStyle = 'pointer';
    }
    // Handle background color and hover effect
    let rowStyles = '';
    if (!isHover && !isSelected && childViewData && childViewData.length !== 0) {
      rowStyles += `
        background-color: #252333; 
      `;
    } else if (!isHover && isSelected) {
      rowStyles += `
        background: linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
      `;
    }
    else if (isHover) {
      rowStyles += `
        &:hover {
          background-color: #beaeff;
          color: black;
        }
      `;
    }
    return `
      cursor: ${cursorStyle};
      ${rowStyles}
    `;
  }}
`;

const StyledDropdownIcon = styled(DropdownIcon)`
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin: 0px 0px -2px 5px;
  transition: transform 0.3s;
  transform: ${(props) => (props.isopen === 'acs' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
