import axiosInstance from '../../../../../config/axiosConfig';

export async function getAllUsers() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getAllRoles() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getRoomSettingsById(id) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}/settings`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getRoomUsers(roomId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}`;
        const respAxios = await axiosInstance.get(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function addUserToRoom(roomId, userId, roleId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}`;
        const respAxios = await axiosInstance.put(url, {
            roomUserId: userId,
            roleId,
        });
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function removeUserFromRoom(roomId, userId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}/${userId}`;
        const respAxios = await axiosInstance.delete(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function createRoom(
    name,
    code,
    type,
    freeFormJson,
    expiry,
    subtitle,
    description,
    selectedUserId,
    tags,
    groupId
) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms`;
        const respAxios = await axiosInstance.post(url, {
            name,
            code,
            roomType: type,
            json: freeFormJson,
            expiry,
            subtitle,
            description,
            userIdSelection: selectedUserId,
            tags,
            groupId,
        });
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getRoomTags() {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/tags`);

        return response.data.tags;
    } catch (err) {
        console.error('Error fetching roomTags:', err);
        return null;
    }
}

export async function getUserGroups() {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_CORE_API_URI}/web/groups`);

        return response.data.groups;
    } catch (err) {
        console.error('Error fetching userGroups:', err);
        return null;
    }
}
