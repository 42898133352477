import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import female2 from '../../assets/images/Avatars/female2.png';
import female3 from '../../assets/images/Avatars/female3.png';
import female4 from '../../assets/images/Avatars/female4.png';
import male1 from '../../assets/images/Avatars/male1.png';
import male2 from '../../assets/images/Avatars/male2.png';
import male3 from '../../assets/images/Avatars/male3.png';
import male4 from '../../assets/images/Avatars/male4.png';
import female1 from '../../assets/images/Avatars/female1.png';
import napsterAvatarBg from '../../assets/images/clients/napster-avatar-bg.png';
import toggleButton from '../../assets/images/buttonIcons/Icon-CTA.png';
import { setToSessionStorage } from '../../hooks/useSessionStorage';
import { isMobileDevice, getWindowSize, getDeviceOrientation } from '../../utils/generalUtils';
import UIButton from '../../components/common/button/PurpleButton';
import circleButton from '../../assets/images/buttonIcons/circle-chevron-button.png';
import { DISCOVERSE_OWNER_ID, LOG_EVENTS_AVATAR_SELECTION } from '../../constants';
import { sendInfoToActionLog, sendEventsLog } from '../../api/UserController';
import { fetchVanityLinkAvatarPresets } from '../../api/VanityLinkController';
import { setColor } from '../../assets/styles/styles';

function AvatarPresetPage({ setSelectedAvatar, forceLandScape, userToken, userId }) {
    const isMobile = isMobileDevice();
    const sliderRef = useRef(null);
    const selectedImageRef = useRef(null);
    const pathId = window.location.pathname.split('/').pop();
    const [orientation, setOrientation] = useState(getDeviceOrientation());
    const [scrollAmount, setScrollAmount] = useState(0);
    const [selectedAvatarId, setSelectedAvatarId] = useState(null);
    const [selectedAvatarIndexSet, setSelectedAvatarIndexSet] = useState(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [showPrevButton, setShowPrevButton] = useState(false);
    const [showNextButton, setShowNextButton] = useState(true);
    const [avatars, setAvatars] = useState([]);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    const setDefaultAvatars = () => {
        const defaultPresets = [
            { name: 'female2', image_url: female2, index: 0 },
            { name: 'male4', image_url: male4, index: 1 },
            { name: 'female4', image_url: female4, index: 2 },
            { name: 'male1', image_url: male1, index: 3 },
            { name: 'female1', image_url: female1, index: 4 },
            { name: 'male3', image_url: male3, index: 5 },
            { name: 'female3', image_url: female3, index: 6 },
            { name: 'male2', image_url: male2, index: 7 },
        ];
        setAvatars(defaultPresets);
        setLoading(false);
    };

    const fetchAvatarPresets = async (vanityLink) => {
        const response = await fetchVanityLinkAvatarPresets(vanityLink);
        if (response.status === 200) {
            setAvatars(response.avatarPresets);
            setLoading(false);
        } else {
            setDefaultAvatars();
        }
    };

    useEffect(() => {
        setLoading(true);
        if (params['*']) {
            fetchAvatarPresets(params['*']);
        } else {
            setDefaultAvatars();
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (sliderRef.current) {
                const containerWidth = sliderRef.current.clientWidth;
                const totalWidth = sliderRef.current.scrollWidth;
                const { scrollLeft } = sliderRef.current;

                setShowPrevButton(scrollLeft > 0);
                setShowNextButton(scrollLeft + containerWidth < totalWidth);
            }
        };

        if (sliderRef.current) {
            const currentSliderRef = sliderRef.current;
            currentSliderRef.addEventListener('scroll', handleScroll);

            return () => {
                currentSliderRef.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollAmount]);

    const handleScroll = (direction) => {
        if (sliderRef.current) {
            const containerWidth = sliderRef.current.clientWidth;
            const itemWidth = isMobile ? sliderRef.current.children[0].clientWidth : containerWidth / 2;
            const totalItems = avatars.length;

            let newScrollAmount;

            if (direction === 'next') {
                if (isMobile) {
                    // Mobile: Scroll one avatar at a time, ensuring the next avatar is centered
                    newScrollAmount = Math.min(scrollAmount + itemWidth, itemWidth * (totalItems - 1));
                } else {
                    // Non-mobile: Original scroll behavior
                    newScrollAmount = Math.min(scrollAmount + itemWidth, totalItems * itemWidth - containerWidth);
                }
            } else {
                newScrollAmount = Math.max(scrollAmount - itemWidth, 0);
            }

            let scrollToPosition;

            if (isMobile) {
                // Center the avatar for mobile
                const centerOfContainer = containerWidth / 2;
                const centerOfAvatar = newScrollAmount + itemWidth / 2;
                scrollToPosition = centerOfAvatar - centerOfContainer;
            } else {
                // Non-mobile: Original calculation for center position
                const centerPosition = newScrollAmount === 0 ? 0 : containerWidth / 2 - itemWidth / 2;
                scrollToPosition = newScrollAmount + centerPosition;
            }

            sliderRef.current.scrollTo({
                // left: newScrollAmount + centerPosition,
                left: scrollToPosition,
                behavior: 'smooth',
            });

            setScrollAmount(newScrollAmount);
        }
    };

    const handleAvatarClick = (avatar) => {
        setSelectedAvatarIndexSet(avatar.index);
        setSelectedAvatarId(avatar.name);

        selectedImageRef.current = document.getElementById(`image-${avatar.index}`);

        selectedImageRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });
    };

    const handleSubmit = () => {
        setSelectedAvatar(selectedAvatarId);
        setToSessionStorage('selectedAvatar', selectedAvatarId);

        const avatarInfo = {
            avatarpresetid: selectedAvatarId,
        };
        sendInfoToActionLog('avatar_preset_selection', avatarInfo, userToken, DISCOVERSE_OWNER_ID);
        sendEventsLog(userToken, pathId, userId, LOG_EVENTS_AVATAR_SELECTION, 'info', avatarInfo, '', '');
    };

    useEffect(() => {
        async function handleWindowResize() {
            setWindowSize(getWindowSize());
            const orientation = await getDeviceOrientation();
            setOrientation(orientation);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <App orientation={orientation} forceLandScape={forceLandScape}>
            {loading ? (
                <></>
            ) : (
                <>
                    <EmptyHeader />
                    <Button
                        onClick={() => handleScroll('prev')}
                        visible={showPrevButton}
                        orientation={orientation}
                        forceLandScape={forceLandScape}
                    >
                        <ToggleButtonLeft client={pathId} src={pathId === 'discoverse' ? circleButton : toggleButton} />
                    </Button>
                    <ImagesContainer ref={sliderRef}>
                        {avatars.map((avatar) => (
                            <Image
                                id={`image-${avatar.index}`}
                                onClick={() => handleAvatarClick(avatar)}
                                alt="sliderImage"
                                key={avatar.index}
                                src={avatar.image_url}
                                selectedAvatarIndex={avatar.index}
                                selectedAvatarId={selectedAvatarIndexSet}
                                isMobile={isMobile}
                                clientPath={pathId}
                            />
                        ))}
                    </ImagesContainer>
                    <Button
                        visible={showNextButton}
                        right
                        onClick={() => handleScroll('next')}
                        orientation={orientation}
                        forceLandScape={forceLandScape}
                    >
                        <ToggleButtonRight
                            client={pathId}
                            src={pathId === 'discoverse' ? circleButton : toggleButton}
                        />
                    </Button>
                    <AvatarBottomBanner
                        windowSize={windowSize}
                        orientation={orientation}
                        forceLandScape={forceLandScape}
                    >
                        <Copy windowSize={windowSize} orientation={orientation} forceLandScape={forceLandScape}>
                            <h1>Select your in-game character</h1>
                            <p>
                                Scroll <YellowText client={pathId}>left</YellowText> or{' '}
                                <YellowText client={pathId}>right</YellowText> to choose your avatar to explore with.
                            </p>
                        </Copy>

                        <AvatarButton>
                            {pathId === 'discoverse' ? (
                                <DiscoverseButton
                                    onClick={handleSubmit}
                                    value={selectedAvatarId}
                                    client={selectedAvatarId}
                                >
                                    Continue
                                </DiscoverseButton>
                            ) : (
                                <UIButton
                                    style={AvatarButton}
                                    text="Continue"
                                    func={handleSubmit}
                                    greyBoxText="Select Avatar"
                                    value={selectedAvatarId}
                                />
                            )}
                        </AvatarButton>
                    </AvatarBottomBanner>
                </>
            )}
        </App>
    );
}

export default AvatarPresetPage;

const App = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 4;
    height: 100%;
    width: 100%;

    ${(props) => {
        if (props.orientation && props.forceLandScape && props.orientation === 'portrait') {
            return `
        transform: rotate(-90deg);
        height:100vw;
        width: 100vh;
      `;
        }
        return `
        transform: none;
        height: 100%;
        width: 100%;
      `;
    }}
`;
const EmptyHeader = styled.div`
    display: flex;
    height: 20%;
    width: 100%;

    @media (max-width: 730px) {
        height: 15%;
    }
`;

const ImagesContainer = styled.div`
    display: flex;
    width: 100%;
    height: inherit;
    overflow: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    transition: scroll 0.1s ease-in-out;

    @media (max-width: 730px) {
        ::-webkit-scrollbar {
            display: none;
        }
    }

    /* SCROLLBAR SPECIFICATIONS */

    &::-webkit-scrollbar {
        width: 5em; /* Adjust the width as needed */
        border-width: 1px;
    }

    &::-webkit-scrollbar-track {
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        border-width: 1px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${setColor.linearGradientScrollbar};
        border-radius: 10px; /* Adjust the border-radius as needed */
        border-width: 1px;
    }
`;

const Image = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    border-right: 3px solid ${setColor.sendMessage};
    padding-bottom: 15px;
    /* padding-top: 7rem; */
    padding-top: 2rem;
    object-fit: contain;
    /* padding-bottom: 8rem; */
    ${(props) =>
        props.clientPath === 'discoverse' &&
        `background-image: url(${napsterAvatarBg}); background-size: cover; margin: 0.5rem; border-radius: 5px; border-right: none;`}
    padding-bottom: 2rem;

    @media (max-width: 730px), (max-height: 450px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    @media (max-width: 320px) {
        padding-top: 2rem;
        padding-bottom: 3rem;
        width: auto;
        height: 85%;
        margin-top: 3rem;
    }

    &:hover {
        box-shadow: 0px 8px 20px 0px ${setColor.iconColor3} inset;
    }

    ${(props) =>
        props.selectedAvatarIndex === props.selectedAvatarId &&
        `
    border: 6px solid #beaeff;
    box-shadow: 0px 8px 20px 0px rgba(190, 174, 255, 0.85) inset;
  `}
`;

const Button = styled.button`
    ${(props) => (props.visible ? 'display: block;' : 'display: none;')}
    margin: 20px;
    top: calc(100% / 2 - 125px);
    position: fixed;
    ${(props) => (props.right ? 'right: 0;' : 'left: 0;')}
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;

    @media (max-width: 730px) {
        margin: 10px;
        top: calc(100% / 2 - 150px);

        ${(props) => {
            if (props.orientation && props.forceLandScape && props.orientation === 'portrait') {
                return `
        top: calc(100% / 3);
      `;
            }
        }}
    }
`;

const ToggleButtonLeft = styled.img`
    transform: rotate(-180deg);
    ${(props) => props.client === 'discoverse' && `height: 50px; width:  50px;`};
`;

const ToggleButtonRight = styled.img`
    ${(props) => props.client === 'discoverse' && `height: 50px; width:  50px;`};
`;

const DiscoverseButton = styled.button`
    background-color: ${(props) => (props.value === null ? '#504d59' : '#2259ff')};
    border-radius: 25px;
    height: 50px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    border: none;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Barlow', sans-serif;
    cursor: pointer;

    @media (max-width: 960px) {
        padding-left: 0rem;
    }
    @media (min-width: 960px) {
        padding: 0 3rem;
    }
`;

const YellowText = styled.span`
    color: ${(props) => (props.client === 'discoverse' ? '#68DDFF' : '#ffe663')};
    font-weight: 700;
    text-decoration: none;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Copy = styled.div`
    flex: 2;
    color: white;
    padding: 20px;
    font-family: 'Barlow', sans-serif;

    @media (max-width: 768px) {
        text-align: center;
        & > h1 {
            font-size: 26px;
        }
        & > p {
            font-size: 14px;
        }

        ${(props) => {
            if (props.orientation && props.forceLandScape && props.orientation === 'portrait') {
                return `
        text-align: left;
      `;
            }
        }}
    }
    @media (max-width: 1440px) {
        & > h1 {
            ${(props) => (props.windowSize.aspectRatio > 1 ? 'font-size: 22px;' : 'font-size: 26px;')}
        }
        & > p {
            font-size: 16px;
        }
    }
`;

const AvatarBottomBanner = styled.div`
    background: linear-gradient(180deg, rgba(27, 27, 35, 0.7) 0%, rgba(27, 27, 35, 0) 100%);
    height: 150px;
    padding: 2rem;
    padding-left: 4rem;
    width: 100vw;
    display: flex;
    align-items: center;

    ${(props) => {
        if (props.orientation && props.forceLandScape && props.orientation === 'portrait') {
            return `
        height:100vw;
        width: 100vh;
      `;
        }
    }}

    ${(props) => props.windowSize.aspectRatio > 1 || (props.windowSize.innerWidth > 731 && 'height: 100px;')}

  @media (max-width: 739px) {
        height: 150px;
        height: auto;
        padding: 0.5rem;

        ${(props) =>
            props.windowSize.aspectRatio > 1 || props.forceLandScape
                ? 'flex-direction: row;'
                : 'flex-direction: column;'}
    }
    @media (max-width: 1440px) {
        padding: 0.3rem;
    }
`;

const AvatarButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 10;

    @media (max-width: 960px) {
        padding: 0rem;
        position: initial;
        padding: 0rem;
        margin: 0rem 0.6rem;
        height: 90px;
        width: 230px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        padding: 0rem;
        position: initial;
        padding: 0rem;
        margin: 1rem;
    }
`;
