import React, { useState } from 'react';
import styled from 'styled-components';
import { setColor } from '../../../assets/styles/styles';

const ToggleSwitch = ({ onChange }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        const newValue = !isChecked;
        setIsChecked(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <>
            <SwitchContainer>
                <CheckboxInput checked={isChecked} onChange={handleToggle} />
                <Slider isChecked={isChecked} onChange={handleToggle} />
            </SwitchContainer>
        </>
    );
};

export default ToggleSwitch;

const SwitchContainer = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
`;

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => (props.isChecked ? setColor.redLightWarning : setColor.aquaGreen)};
    transition: background-color 0.4s ease;
    border-radius: 34px;

    &::before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: transform 0.4s ease;
        border-radius: 50%;
        transform: ${(props) => (props.isChecked ? 'translateX(26px)' : 'translateX(0)')};
    }
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Slider} {
        background-color: ${setColor.redLightWarning};
    }

    &:checked + ${Slider}::before {
        transform: translateX(26px);
    }
`;
