import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { setColor } from '../../../../../../assets/styles/styles';
import { default as Button } from '../../../../../../components/common/button/Button';
import Typography from '../../../../../../components/common/text/Typography';
import { ReactComponent as DropdownIcon } from '../../../../../../assets/images/icons/Chevron.svg';
import SurveyDropdown from '../SurveyDropdown';
import { getFormQuestionTypes } from '../../api/surveysController';

const AddSurveyQuestion = ({ getQuestionsData, addOptionClick }) => {
    const [questionName, setQuestionName] = useState('');
    const [questionBody, setQuestionBody] = useState('');
    const [QuestionTypesData, setQuestionTypesData] = useState([]);
    const [isDropdownOpenQuestionType, setIsDropdownOpenQuestionType] = useState(false);
    const [selectedQuestionType, setSelectedQuestionType ] = useState('');
    const [finalQuestions, setFinalQuestions] = useState([]);
    const [disabledOrNotForAdd, setDisabledOrNotForAdd] = useState(true);
    const [isHideCancel, setIsHideCancel] = useState(false);

    const fetchFormData = async () => {
        try {
            const responseQueTypes = await getFormQuestionTypes();
            if (responseQueTypes.status === 200) {
                const data = responseQueTypes.data.questionTypes;
                const makeDropDownData = data.map((item) => {
                    return {
                        value: item.formQuestionTypeId,
                        label: item.formQuestionTypeName
                    };
                });
                setQuestionTypesData(makeDropDownData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const condtion = questionName === '' || questionBody === '' || selectedQuestionType === '';
        if(finalQuestions.length === 0 && condtion){
            setDisabledOrNotForAdd(true);
        }else if (finalQuestions.length !== 0 && condtion){
            setDisabledOrNotForAdd(true);
        }else{
            setDisabledOrNotForAdd(false);
            setIsHideCancel(true);
        }
    }, [finalQuestions, questionName, questionBody, selectedQuestionType]);

    useEffect(() => {
        fetchFormData();
    }, []);


    const handleFieldChange = (e, field) => {
        if (field === 'questionName') {
            setQuestionName(e.target.value);
        }
        if (field === 'questionBody') {
            setQuestionBody(e.target.value);
        }
    }

    const toggleDropdownQuestionType = () => {
        setIsDropdownOpenQuestionType(!isDropdownOpenQuestionType);
    };

    const handleOptionClickQuestionType = (option) => {
        setIsDropdownOpenQuestionType(false);
        setSelectedQuestionType(option);
    };

    const handleCancel = () => {
        setQuestionName('');
        setQuestionBody('');
        setSelectedQuestionType('');
        setIsHideCancel(false);
    };

    const handleAddOption = () => {
        if(questionName !== '' && questionBody !== '' && selectedQuestionType !== ''){
            const optionData = {
                name: questionName,
                body: questionBody,
                formQuestionType: selectedQuestionType,
                image: null
            };
            finalQuestions.push(optionData);
            const updatedQuestions = [...finalQuestions];
            setFinalQuestions(updatedQuestions);
            getQuestionsData(updatedQuestions);
            setQuestionName('');
            setQuestionBody('');
            setSelectedQuestionType('');
            addOptionClick();
        }  
    }

    return (
        <>
            <QuestionTable>
                <TableRow>
                    <TableColumn>
                        <Label>Question Name</Label>
                        <InputBox
                            type="text"
                            id="questionName"
                            value={questionName}
                            placeholder="Enter question name/title"
                            onChange={(e) => handleFieldChange(e, 'questionName')}
                        />
                    </TableColumn>
                </TableRow>

                <TableRow>
                    <TableColumn>
                        <Label>Question Body</Label>
                        <InputBox
                            type="text"
                            id="questionBody"
                            value={questionBody}
                            placeholder="Enter question body"
                            onChange={(e) => handleFieldChange(e, 'questionBody')}
                        />
                    </TableColumn>
                </TableRow>

                <TableRow>
                    <TableColumn>
                        <Label>Question Type</Label>
                        <SurveyDropdown
                            options={QuestionTypesData}
                            selectedOption={selectedQuestionType}
                            onOptionSelect={handleOptionClickQuestionType}
                            toggleDropdown={toggleDropdownQuestionType}
                            isDropdownOpen={isDropdownOpenQuestionType}
                            title="Select Question Type Options"
                            trueFalseOptions={'false'}
                        />
                    </TableColumn>
                </TableRow>
                
                {finalQuestions.length !== 0 && (
                    <>
                    <TableRow>
                        <TableColumn>
                            <TitleText>
                                Question Added ({finalQuestions.length})
                            </TitleText>
                        </TableColumn>
                        <TableColumn>
                            <TitleText>
                                Question Name: {finalQuestions[0].name}
                                </TitleText>
                            <TitleText>
                             Question Body: {finalQuestions[0].body}
                            </TitleText>
                            <TitleText>
                             Question Type: {finalQuestions[0].formQuestionType}
                            </TitleText>
                        </TableColumn>
                    </TableRow>
                    </>
                )}
                
                {finalQuestions.length === 0 && (
                    <TableRow>
                        <TableColumn>
                            <Button
                                variant={'OutlinedButton'}
                                buttonWidth={'100%'}
                                label={finalQuestions.length === 0 ? 'SAVE QUESTION' : 'SAVE MORE QUESTION'}
                                onClick={handleAddOption}
                                margin={'1rem 0 0 0'}
                            />
                        </TableColumn>
                        {isHideCancel && (
                            <TableColumn>
                                <Button
                                    variant={'OutlinedButton'}
                                    buttonWidth={'100%'}
                                    label={'CANCEL'}
                                    margin={'1rem 0 0 0'}
                                    onClick={handleCancel}
                                />  
                            </TableColumn>
                        )}
                    </TableRow>
                )}
            </QuestionTable>
        </>
    );
};

export default AddSurveyQuestion;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const QuestionTable = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;


const DropdownButton = styled.button`
    display: flex;
    font-family: 'Barlow-Regular', Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;
const TitleText = styled.p`
    font-family: 'Barlow-Regular', Helvetica;
    color: #767676;
    font-weight: 500;
`;
