import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import Typography from '../../../components/common/text/Typography';
import { setColor } from '../../../assets/styles/styles';
import { hexToRGBA } from '../../../utils/generalUtils';
import colorLogo from '../../../assets/images/logos/colorLogo.png';
import { createCheckoutSession, getPaymentInfoBySessionId } from '../api/controller';
import Banner from '../../../components/layout/ui/Banner';

const Checkout = () => {
    const token = useSelector((state) => state?.user?.token);
    const [errorMessage, setErrorMessage] = useState(''); // Set error message initially to false
    const [isError, setIsError] = useState(false);
    const [isLoading, setLoading] = useState(true); // Set loading initially to true
    const [checkoutUrl, setCheckoutUrl] = useState(null);
    const checkoutSessionId = localStorage.getItem('checkoutSessionId');
    const { roomName, productId } = useParams();
    const isDevEnvironment = process.env.NODE_ENV === 'development';
    const protocol = isDevEnvironment ? 'http' : 'https';
    const { host } = window.location;
    const successUrl = `${protocol}://${host}/${roomName}/${productId}/guest-checkout/success`;
    const cancelUrl = `${protocol}://${host}/${roomName}/${productId}/guest-checkout/cancel`;

    async function CheckoutSession() {
        const respCheckout = await createCheckoutSession(token, successUrl, cancelUrl, roomName, productId);
        if (respCheckout?.response?.status === 500) {
            setIsError(true);
            setLoading(false);
            setErrorMessage(respCheckout.response?.data?.message);

            if (roomName === 'paidia') {
                setTimeout(() => {
                    window.location.href = '/paidia';
                }, 5000);
            } else if (roomName === 'discoverse') {
                setTimeout(() => {
                    window.location.href = '/discoverse';
                }, 5000);
            }
        }
        if (respCheckout.data && respCheckout.status === 201) {
            const sessionId = respCheckout.data.id;
            localStorage.setItem('checkoutSessionId', sessionId);
            setCheckoutUrl(respCheckout.data.url);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (checkoutSessionId) {
                    const response = await getPaymentInfoBySessionId(token, checkoutSessionId, roomName, productId);
                    if (
                        response.status === 200 &&
                        response.data.status === 'open' &&
                        response.data.payment_status === 'unpaid'
                    ) {
                        window.location.href = response.data.url;
                        setCheckoutUrl(response.data.url);
                        setLoading(false);
                    } else {
                        CheckoutSession();
                    }
                } else {
                    CheckoutSession();
                }
            } catch (error) {
                console.error('Error fetching checkout session:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (checkoutUrl) {
            window.location.href = checkoutUrl; // Redirect when checkoutUrl is available
        }
    }, [checkoutUrl]); // Effect to redirect when checkoutUrl changes

    return (
        <>
            <div>
                {isError && (
                    <ModalContainer>
                        <ModalContent>
                            <StyledImage src={colorLogo} alt="logo" />
                            <Typography variant="PinkText" margin={`0rem 0 0rem 2rem`} fontSize="25px">
                                Error while processing payment. Redirecting to home page.
                            </Typography>
                        </ModalContent>
                    </ModalContainer>
                )}

                {isLoading && !isError && (
                    <ModalContainer>
                        <ModalContent>
                            <StyledImage src={colorLogo} alt="logo" />
                            <Typography variant="PinkText" margin={`0rem 0 0rem 6rem`} fontSize="25px">
                                Redirecting to Stripe
                            </Typography>
                        </ModalContent>
                    </ModalContainer>
                )}
            </div>
        </>
    );
};

export default Checkout;

const StyledImage = styled.img`
    width: 60%;
    height: 60%;
    margin: 0 auto;
    display: block;
    // padding: 10px;
`;

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${hexToRGBA(setColor.black, 0.85)};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
`;

const ModalContent = styled.div`
    border: 3px solid ${setColor.tickerBorder};
    padding: 25px;
    width: 500px;
    height: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @media (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 0.75em; /* Adjust the width as needed */
            border-width: 1px;
        }
        &::-webkit-scrollbar-track {
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;
            border-width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${hexToRGBA(setColor.accent4, 0.1)};
            border-radius: 10px;
            border-width: 1px;
        }
    }
    @media (max-width: 768px) {
        max-height: 100vh;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-radius: 0;
        justify-content: space-between;
    }
`;
