import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Title from '../../../components/Title';
import Body from '../../../layout/Body';
import { formatDateTimeWithTimeZone, capitalizeFirstLetter } from '../../../../../utils/generalUtils';
import axiosInstance from '../../../../../config/axiosConfig';
import { setColor, setFont } from '../../../../../assets/styles/styles';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/icons/Chevron.svg';
import { ReactComponent as Checkmark } from '../../../../../assets/images/icons/Checkmark.svg';
import { ReactComponent as Cross } from '../../../../../assets/images/icons/Cross.svg';
import { default as Button } from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import DeleteModal from './DeleteModal';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/images/icons/NotFound.svg';
import Modal from '../../../components/Modal';
import { DEL_ROOM_PERMISSIONS } from '../../../../../constants';
import { getPermissionsAndCheckAccess } from '../../../../../api/RolesController';
import { getRoomUsers } from '../api/roomController';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icons/deleteIcon.svg';
import AddRoomUsers from './AddUpdateRoomUsers';

const RoomsDetails = () => {
    const user = useSelector((state) => state.user);
    const [hasPemissionToDelRoom, setHasPemissionToDelRoom] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [roomData, setRoomData] = useState();
    const [length, setLength] = useState(0);
    const [roomName, setRoomName] = useState('');
    const [editedRoomName, setEditedRoomName] = useState('');
    const [editedRoomType, setEditedRoomType] = useState('');
    const [editedOwnerName, setEditedOwnerName] = useState('');
    const [editedThumbnail, setEditedThumbnail] = useState('');
    const [editedOwnerLogo, setEditedOwnerLogo] = useState('');
    const [editedLogo, setEditedLogo] = useState('');
    const [editedSubtitle, setEditedSubtitle] = useState('');
    const [editedFreeFormJson, setEditedFreeFormJson] = useState('');
    const [expiry, setExpiry] = useState('');
    const [description, setDescription] = useState('');
    const [ownerId, setOwnerId] = useState('');
    const [roomDetailsImageId, setRoomDetailsImageId] = useState('');
    const [roomOwnerImageId, setRoomOwnerImageId] = useState('');
    const [isVisibleInNavMenu, setIsVisibleInNavMenu] = useState(String(false));
    const [imageTrendingId, setImageTrendingId] = useState('');
    const [imageCommunityId, setImageCommunityId] = useState('');
    const [imageExploreId, setImageExploreId] = useState('');
    const [ticketEventAvailable, setTicketEventAvailable] = useState(String(false));
    const [ticketEventEndTime, setTicketEventEndTime] = useState('');
    const [merchAvailable, setMerchAvailable] = useState(String(false));
    const [ownerOnline, setOwnerOnline] = useState(String(false));
    const [bannerImageExternalUrl, setBannerImageExternalUrl] = useState('');
    const [tags, setTags] = useState([]);
    const [groupId, setGroupId] = useState('');
    const [roomCode, setRoomCode] = useState('');
    const [editedRoomThumbnail, setEditedRoomThumbnail] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [label, setLabel] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [fieldsObj, setFieldsObj] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [roomUsers, setRoomUsers] = useState([]);
    const [isRoomUsersDeleteModal, setIsRoomUsersDeleteModal] = useState(false);
    const [selectedRoomUser, setSelectedRoomUser] = useState();
    const [openAddUserToRoomModal, setOpenAddUserToRoomModal] = useState(false);
    const ROWS_PER_PAGE = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(roomUsers.length / ROWS_PER_PAGE);
    const visibleUsers = roomUsers.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const fetchData = async (url) => {
        try {
            setIsLoading(true);
            const roleName = user.role;
            const flagPemissionToDelRoom = await getPermissionsAndCheckAccess(roleName, DEL_ROOM_PERMISSIONS);
            setHasPemissionToDelRoom(flagPemissionToDelRoom);

            const response = await axiosInstance.get(url);
            if (response.data.room) {
                setRoomName(response.data.room.name);
                const createdDateTime = formatDateTimeWithTimeZone(response.data.room.createdAt);
                const updatedDateTime = formatDateTimeWithTimeZone(response.data.room.updatedAt);
                response.data.room.createdDateTime = createdDateTime;
                response.data.room.updatedDateTime = updatedDateTime;
                setRoomData(response.data);
                setEditedRoomName(response.data.room.name);
                setRoomCode(response.data.room.code);
                setEditedRoomType(response.data.room?.roomType);
                setEditedOwnerName(response.data.room?.ownerName);
                setEditedThumbnail(response.data.room?.thumbnail);
                setEditedOwnerLogo(response.data.room?.ownerLogo);
                setEditedLogo(response.data.room?.logo);
                setEditedSubtitle(response.data.room?.subtitle);
                setExpiry(response.data.room?.expiry);
                setDescription(response.data.room?.description);
                setOwnerId(response.data.room?.ownerId);
                setRoomDetailsImageId(response.data.room?.roomDetailsImageId);
                setRoomOwnerImageId(response.data.room?.roomOwnerImageId);
                setIsVisibleInNavMenu(String(response.data.room?.isVisibleInNavMenu));
                setImageTrendingId(response.data.room?.imageTrendingId);
                setImageCommunityId(response.data.room?.imageCommunityId);
                setImageExploreId(response.data.room?.imageExploreId);
                setTicketEventAvailable(String(response.data.room?.ticketEventAvailable));
                setTicketEventEndTime(response.data.room?.ticketEventEndTime);
                setMerchAvailable(String(response.data.room?.merchAvailable));
                setOwnerOnline(String(response.data.room?.ownerOnline));
                setBannerImageExternalUrl(response.data.room?.bannerImageExternalUrl);
                setTags(response.data.room?.tags);
                setGroupId(response.data.room?.groupId);
                setEditedFreeFormJson(JSON.stringify(response.data.room?.json));
                setEditedRoomThumbnail(response.data.room?.roomThumbnail);

                setLength(1);
                const fields = [
                    { label: 'Name', key: 'name', value: editedRoomName, rowNo: 1 },
                    { label: 'Room Type', key: 'roomType', value: editedRoomType, rowNo:1 },
                    { label: 'Owner Name', key: 'ownerName', value: editedOwnerName, rowNo: 2 },
                    { label: 'Room Code', key: 'code', value: roomCode, rowNo: 2 },
                    { label: 'Thumbnail', key: 'thumbnail', value: editedThumbnail, rowNo: 3 },
                    { label: 'Owner Logo', key: 'ownerLogo', value: editedOwnerLogo, rowNo: 3 },
                    { label: 'Logo', key: 'logo', value: editedLogo, rowNo: 4 },
                    { label: 'Subtitle', key: 'subtitle', value: editedSubtitle, rowNo: 4 },
                    { label: 'Expiry', key: 'expiry', value: expiry, rowNo: 5 },
                    { label: 'Description', key: 'description', value: description, rowNo: 5 },
                    { label: 'Owner ID', key: 'ownerId', value: ownerId, rowNo: 6 },
                    { label: 'Room Details Image ID', key: 'roomDetailsImageId', value: roomDetailsImageId, rowNo: 6 },
                    { label: 'Room Owner Image ID', key: 'roomOwnerImageId', value: roomOwnerImageId, rowNo: 7 },
                    { label: 'Is Visible In Nav Menu', key: 'isVisibleInNavMenu', value: isVisibleInNavMenu, rowNo: 7 },
                    { label: 'Image Trending ID', key: 'imageTrendingId', value: imageTrendingId, rowNo: 8 },
                    { label: 'Image Community ID', key: 'imageCommunityId', value: imageCommunityId, rowNo: 8 },
                    { label: 'Image Explore ID', key: 'imageExploreId', value: imageExploreId, rowNo: 9 },
                    { label: 'Ticket Event Available', key: 'ticketEventAvailable', value: ticketEventAvailable, rowNo: 9 },
                    { label: 'Ticket Event End Time', key: 'ticketEventEndTime', value: ticketEventEndTime, rowNo: 10 },
                    { label: 'Merch Available', key: 'merchAvailable', value: merchAvailable, rowNo: 10 },
                    { label: 'Owner Online', key: 'ownerOnline', value: ownerOnline, rowNo: 11 },
                    { label: 'Banner Image External Url', key: 'bannerImageExternalUrl', value: bannerImageExternalUrl, rowNo: 11 },
                    { label: 'Tags', key: 'tags', value: tags, rowNo: 12 },
                    { label: 'Group ID', key: 'groupId', value: groupId, rowNo: 12 },
                    { label: 'Free Form Json', key: 'json', value: editedFreeFormJson, rowNo: 13 },
                    { label: 'Room Thumbnail', key: 'roomThumbnail', value: editedRoomThumbnail, rowNo: 13 },
                ];
                setFieldsObj(fields);
                setIsLoading(false);
            }

            const roomUsers = await getRoomUsers(id);
            setRoomUsers(roomUsers.data.roomUsers);
        } catch (error) {
            console.error('Error fetching data:', error);
            setRoomData('');
            setLength(0);
            setRoomUsers([]);
            setIsLoading(false);
        }
    };

    const handleCloseClick = (label) => {
        if (hasChanges) {
            setErrorMessage(`Changes not saved for ${label.toLowerCase()}`);
            setHasChanges(false);
            fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
        }
        setLabel('');
        setErrorMessage('');
    };

    const handleCheckmarkClick = async (label) => {
        setLabel(label);
        if (!hasChanges) {
            setErrorMessage(`No changes to update for ${label.toLowerCase()}`);
        } else {
            setErrorMessage('');
        }
        const updateBody = {};
        const fieldToUpdate = fieldsObj.find((field) => field.key === label);

        if (fieldToUpdate && hasChanges) {
            const fieldName = fieldToUpdate.key;
            const fieldValue = getFieldValue(fieldToUpdate);
            updateBody[fieldName] = fieldValue;
        }

        if (Object.keys(updateBody).length !== 0) {
            try {
                const response = await axiosInstance.put(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`, {
                    ...updateBody,
                });

                if (response.status === 200) {
                    setSuccessMessage(`Successfully updated ${label.toLowerCase()}`);
                } else {
                    setErrorMessage(`Failed to update ${label.toLowerCase()}`);
                }
                setHasChanges(false);
                fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
            } catch (error) {
                console.error('Error updating data:', error);
                setErrorMessage(`Failed to update ${label.toLowerCase()}`);
                fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
            }
        }
    };

    useEffect(() => {
        if (errorMessage || successMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
                setSuccessMessage('');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [errorMessage, successMessage]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`;
        fetchData(url);
    }, [id]);

    const handleBack = () => {
        navigate(`/console/rooms`);
    };

    const handleSettingsClick = () => {
        navigate(`/console/rooms/settings/${id}`);
    };

    const getFieldValue = (field) => {
        switch (field.key) {
            case 'name':
                return editedRoomName || '';
            case 'roomType':
                return editedRoomType || '';
            case 'code':
                return roomCode || '';
            case 'ownerName':
                return editedOwnerName || '';
            case 'thumbnail':
                return editedThumbnail || '';
            case 'ownerLogo':
                return editedOwnerLogo || '';
            case 'logo':
                return editedLogo || '';
            case 'subtitle':
                return editedSubtitle || '';
            case 'expiry':
                return expiry || '';
            case 'description':
                return description || '';
            case 'ownerId':
                return ownerId || '';
            case 'roomDetailsImageId':
                return roomDetailsImageId || '';
            case 'roomOwnerImageId':
                return roomOwnerImageId || '';
            case 'isVisibleInNavMenu':
                return isVisibleInNavMenu || String(false);
            case 'imageTrendingId':
                return imageTrendingId || '';
            case 'imageCommunityId':
                return imageCommunityId || '';
            case 'imageExploreId':
                return imageExploreId || '';
            case 'ticketEventAvailable':
                return ticketEventAvailable || String(false);
            case 'ticketEventEndTime':
                return ticketEventEndTime || '';
            case 'merchAvailable':
                return merchAvailable || String(false);
            case 'ownerOnline':
                return ownerOnline || String(false);
            case 'bannerImageExternalUrl':
                return bannerImageExternalUrl || '';
            case 'tags':    
                return tags || '';
            case 'groupId':
                return groupId || '';
            case 'json':
                return editedFreeFormJson || '';
            case 'roomThumbnail':
                return editedRoomThumbnail || '';
            default:
                return '';
        }
    };

    const handleFieldChange = (e, field) => {
        setHasChanges(true);
        switch (field.key) {
            case 'name':
                setEditedRoomName(e.target.value);
                break;
            case 'roomType':
                setEditedRoomType(e.target.value);
                break;
            case 'code':
                setRoomCode(e.target.value);
                break;
            case 'ownerName':
                setEditedOwnerName(e.target.value);
                break;
            case 'thumbnail':
                setEditedThumbnail(e.target.value);
                break;
            case 'roomThumbnail':
                setEditedRoomThumbnail(e.target.value);
                break;
            case 'ownerLogo':
                setEditedOwnerLogo(e.target.value);
                break;
            case 'logo':
                setEditedLogo(e.target.value);
                break;
            case 'subtitle':
                setEditedSubtitle(e.target.value);
                break;
            case 'expiry':
                setExpiry(e.target.value);
                break;
            case 'description':
                setDescription(e.target.value);
                break;
            case 'ownerId':
                setOwnerId(e.target.value);
                break;
            case 'roomDetailsImageId':
                setRoomDetailsImageId(e.target.value);
                break;
            case 'roomOwnerImageId':
                setRoomOwnerImageId(e.target.value);
                break;
            case 'isVisibleInNavMenu':
                setIsVisibleInNavMenu(e.target.value);
                break;
            case 'imageTrendingId':
                setImageTrendingId(e.target.value);
                break;
            case 'imageCommunityId':
                setImageCommunityId(e.target.value);
                break;
            case 'imageExploreId':
                setImageExploreId(e.target.value);
                break;
            case 'ticketEventAvailable':
                setTicketEventAvailable(e.target.value);
                break;
            case 'ticketEventEndTime':
                setTicketEventEndTime(e.target.value);
                break;
            case 'merchAvailable':
                setMerchAvailable(e.target.value);
                break;
            case 'ownerOnline':
                setOwnerOnline(e.target.value);
                break;
            case 'bannerImageExternalUrl':
                setBannerImageExternalUrl(e.target.value);
                break;
            case 'tags':
                setTags(e.target.value);
                break;
            case 'groupId':
                setGroupId(e.target.value);
                break;
            case 'json':
                setEditedFreeFormJson(e.target.value);
                break;
            default:
                break;
        }
    };

    const openRoomUsersDeleteModal = (roomUser) => {
        setIsRoomUsersDeleteModal(true);
        setSelectedRoomUser(roomUser);
    };

    const handleDeleteClick = (roomUser) => {
        openRoomUsersDeleteModal(roomUser);
    };

    const renderRow = (roomUser) => {
        let name = 'Not Available';
        if (roomUser.firstName !== null && roomUser.lastName !== null) {
            name = `${roomUser.firstName} ${roomUser.lastName}`;
        }

        const buttonDel = <StyledDeleteIcon onClick={() => handleDeleteClick(roomUser)} />;

        if (roomUser.roleName !== 'admin' && roomUser.roleName !== 'superadmin') {
            return [roomUser.userId, name, roomUser.email, roomUser.roleName, <>{buttonDel}</>];
        }

        return [roomUser.userId, name, roomUser.email, roomUser.roleName, <></>];
    };

    const uniqueRowNumbers = [...new Set(fieldsObj.map((field) => field.rowNo))];

    const addUserToRoomClick = async () => {
        setOpenAddUserToRoomModal(true);
    };

    return (
        <>
            <Title
                title={'ROOM OVERVIEW'}
                description={
                    'Manage all rooms in  Intraverse. Changes made here are automatically pushed to Production.'
                }
            />
            <UserConsoleText style={{ marginBottom: '1rem' }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>
            <Body isLoading={isLoading}>
                <>
                    {roomData && (
                        <>
                            <CardTable>
                                <TableRow>
                                    <TableColumn>
                                        <Typography variant="PinkText">{roomData.room.id}</Typography>
                                    </TableColumn>

                                    <TableColumnButton>
                                        <Button
                                            variant={'SmallButton'}
                                            label="VIEW SETTINGS"
                                            buttonWidth="auto"
                                            margin="0rem 1rem 0rem 0rem"
                                            onClick={handleSettingsClick}
                                            border={`${setColor.accent4}`}
                                        />
                                        {hasPemissionToDelRoom && (
                                            <Button
                                                variant={'SmallButton'}
                                                label="DELETE ROOM"
                                                buttonWidth="auto"
                                                onClick={() => openModal()}
                                                border={`${setColor.accent4}`}
                                            />
                                        )}
                                    </TableColumnButton>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <strong>CREATED:</strong> {roomData.room.createdDateTime}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <strong>UPDATED:</strong> {roomData.room.updatedDateTime}
                                    </TableColumn>
                                </TableRow>
                            </CardTable>

                            <Table>
                                {uniqueRowNumbers.map((rowNo) => (
                                    <Row key={rowNo}>
                                        {fieldsObj
                                            .filter((field) => field.rowNo === rowNo)
                                            .map((field) => (
                                                <React.Fragment key={field.key}>
                                                    <Column errorMessage={errorMessage}>
                                                        <Label>{field.label}</Label>
                                                        {label === field.key && errorMessage && (
                                                            <MessageDesc type="error">{errorMessage}</MessageDesc>
                                                        )}
                                                        {label === field.key && successMessage && (
                                                            <MessageDesc type="success">{successMessage}</MessageDesc>
                                                        )}
                                                        <InputBox
                                                                type="text"
                                                                id={field.key}
                                                                value={getFieldValue(field)}
                                                                onChange={(e) => handleFieldChange(e, field)}
                                                            />
                                                    </Column>
                                                    <Column small>
                                                        <IconDiv>
                                                            <StyledCheckmark onClick={() => handleCheckmarkClick(field.key)} />
                                                            <StyledCloseIcon onClick={() => handleCloseClick(field.key)} />
                                                        </IconDiv>
                                                    </Column>
                                                </React.Fragment>
                                            ))}
                                    </Row>
                                ))}
                            </Table>
                        </>
                    )}
                    {length === 0 && <NotFoundImage alt="Not Found" />}
                    <Modal
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        children={
                            <DeleteModal
                                id={id}
                                selectedRoomUser={selectedRoomUser}
                                requestType={'rooms'}
                                closeModal={() => setIsModalOpen(false)}
                            />
                        }
                    />

                    {openAddUserToRoomModal && (
                        <Modal
                            isOpen={openAddUserToRoomModal}
                            setIsOpen={setOpenAddUserToRoomModal}
                            children={
                                <AddRoomUsers
                                    roomId={id}
                                    roomUsers={roomUsers}
                                    closeModal={() => setOpenAddUserToRoomModal(false)}
                                />
                            }
                        />
                    )}

                    <CardTable>
                        <TableRow>
                            <TableColumn>
                                <Typography variant="PinkText" margin={'5px'}>
                                    {roomName ? `${roomName}: USERS` : 'No Room Users Found'}
                                </Typography>
                            </TableColumn>

                            <TableColumnButton>
                                <Button
                                    variant={'SmallButton'}
                                    label="ADD USER TO ROOM"
                                    buttonWidth="auto"
                                    margin="0rem 1rem 0rem 0rem"
                                    onClick={addUserToRoomClick}
                                    border={`${setColor.accent4}`}
                                />
                            </TableColumnButton>
                        </TableRow>
                    </CardTable>

                    {roomUsers.length > 0 && (
                        <>
                            <DataTable
                                headers={['USER ID', 'USER NAME', 'EMAIL', 'ROLE', '', '']}
                                data={visibleUsers}
                                renderRow={renderRow}
                                isHover={false}
                            />

                            <Footer>
                                <TableColumnLeft>Total Number of Room Users: {roomUsers.length}</TableColumnLeft>
                                <TableColumnRight>
                                    {visibleUsers.length !== 0 && (
                                        <Pagination>
                                            <span>
                                                Page {currentPage} of {totalPages}
                                            </span>
                                            <PaginationButton
                                                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </PaginationButton>
                                            <PaginationButton
                                                onClick={() =>
                                                    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
                                                }
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </PaginationButton>
                                        </Pagination>
                                    )}
                                </TableColumnRight>
                            </Footer>

                            {isRoomUsersDeleteModal && (
                                <Modal
                                    isOpen={isRoomUsersDeleteModal}
                                    setIsOpen={setIsRoomUsersDeleteModal}
                                    children={
                                        <DeleteModal
                                            id={id}
                                            requestType={'roomUsers'}
                                            selectedRoomUser={selectedRoomUser}
                                            closeModal={() => setIsRoomUsersDeleteModal(false)}
                                        />
                                    }
                                />
                            )}
                        </>
                    )}
                </>
            </Body>
        </>
    );
};

export default RoomsDetails;

const UserConsoleText = styled.h1`
    font-family: ${setFont.barlowCondensed};
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;
const SmallSubtitle = styled.p`
    font-family: ${setFont.barlowCondensed};
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    background: ${setColor.pinkButtonBgGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const IconDiv = styled.div`
    justify-content: center;
    align-items: center;
    margin: 2.5rem 2rem 2rem 1rem;
    width: fit-content;
    color: ${setColor.accent4};
    justify-content: flex-end;
`;

const StyledCheckmark = styled(Checkmark)`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: ${setColor.greenLight};
        }
    }
`;

const StyledCloseIcon = styled(Cross)`
    border-radius: 5px !important;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: ${setColor.lightPink};
        }
    }
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Table = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
`;

const Row = styled.div`
    display: flex;
`;

const Column = styled.div`
    margin-top: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0')};
    flex: ${(props) => (props.small ? '0.5' : '2')};
    width: ${(props) => (props.small ? '5%' : '95%')};
    @media (max-width: 768px) {
        flex: ${(props) => (props.small ? '1' : '2')};
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;
const MessageDesc = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color: ${(props) => (props.type === 'success' ? setColor.successColor : setColor.errorColor)};
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
`;

const NotFoundImage = styled(NotFoundIcon)`
    width: 300px;
    height: 300px;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText};
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled};
    }
`;
