import React from 'react';
import styled from 'styled-components';
import { setColor } from '../../../assets/styles/styles';
import LandingPageContainer from '../components/LandingPageContainer';
import HeroContainer from '../components/HeroContainer';
import VideoSection from './components/VideoSection';
import GallerySection from './components/GallerySection';
import FeatureSection from './components/FeatureSection';
import NewsSection from './components/NewsSection';
import ReviewSection from './components/ReviewSection';
import ButtonSection from './components/ButtonSection';
import Footer from '../components/FooterSection';
import MobileIcon from '../components/MobileIcons';
import LandingHeader from '../intraverseLandingPage/LandingHeader';

const Live = () => (
    <LandingPageContainer showOutline>
        {process.env.REACT_APP_STREAM_API_ENV !== 'production' ? <LandingHeader /> : <MobileIcon />}
        <HeroContainer
            videoUrl={'/videos/landingPages/Header_live.mp4'}
            buttonText={'Get Early Access'}
            description={'Dive Into Digital Worlds Made By Your Favorite Creators'}
            arrowText={'Join The Intraverse'}
            fadeColor={setColor.darkPurpleBackground}
        />
        <VideoSection />
        <GallerySection />
        <FeatureSection />
        <NewsSection />
        <ReviewSection />
        <ButtonSection videoUrl={'/videos/landingPages/live-footer-v3.mp4'} buttonText="Get Early Access" />
        <Footer
            title={
                <>
                    Join Our <Highlighted>Socials</Highlighted>
                </>
            }
        />
    </LandingPageContainer>
);

export default Live;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;
