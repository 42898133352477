import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import images from '../utils/ImportImages';
import { setColor } from '../../../../assets/styles/styles';
import Typography from '../../../../components/common/text/Typography';
import { device } from '../../../../assets/styles/mediaBreakpoints';
import Modal from '../../../console/components/Modal';
import EmailInputModal from '../../components/EmailInputModal';
import { isMobileDevice } from '../../../../utils/generalUtils';
import BackgroundImage from '../assets/backgrounds/ExperienceBackground.png';

const burlbTitles = ['Intimate', 'Gamified', 'Social'];

const ExperienceSection = () => {
    const isMobile = isMobileDevice();
    const [openModal, setOpenModal] = useState(false);

    const [blurbIndex, setCurrentBlurbIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible(false);
            setTimeout(() => {
                setCurrentBlurbIndex((prevIndex) => (prevIndex + 1) % burlbTitles.length);
                setIsVisible(true);
            }, 1000);
        }, 2500);

        return () => clearInterval(interval);
    }, []);

    const currentFeature = burlbTitles[blurbIndex];

    return (
        <>
            <Modal isOpen={openModal} setIsOpen={setOpenModal} children={<EmailInputModal />} />
            <SectionBackground>
                <SectionContainer>
                    <Feature>
                        {!isMobile && (
                            <VideoContainer>
                                <FeatureVideo muted autoPlay loop>
                                    <source type="video/mp4" src={'/videos/landingPages/Pro_Intimate_Experience.mp4'} />
                                </FeatureVideo>
                            </VideoContainer>
                        )}
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                <HighlightedTitle isVisible={isVisible}>{currentFeature}</HighlightedTitle>{' '}
                                {isMobile && <br />} Experiences
                            </StyledTypographyTitle>
                            <ProductStyledTypographyDescription variant="pWhite">
                                Take your online presence to the next level through custom gaming technology.
                            </ProductStyledTypographyDescription>
                            <ProductStyledTypographyDescription variant="pWhite">
                                Invite your community to experience your world, your story, and your brand in a new
                                dimension.
                            </ProductStyledTypographyDescription>
                            <ProductStyledTypographyDescription variant="pWhite">
                                Create unforgettable interactive experiences with your fans through in-game events like
                                live Q&A’s, music, video, webcam streams, voice chat, text chat, emotes, games and more.
                            </ProductStyledTypographyDescription>
                        </FeatureTextContainer>
                        {isMobile && (
                            <VideoContainer>
                                <FeatureVideo muted autoPlay loop>
                                    <source
                                        type="video/mp4"
                                        src={
                                            <source
                                                type="video/mp4"
                                                src={'/videos/landingPages/Pro_Intimate_Experience.mp4'}
                                            />
                                        }
                                    />
                                </FeatureVideo>
                            </VideoContainer>
                        )}
                    </Feature>
                    <Feature>
                        <FeatureTextContainer>
                            <StyledTypographyTitle variant="LandingPageTitle">
                                Your <Highlighted>Imagination</Highlighted>
                            </StyledTypographyTitle>
                            <ProductStyledTypographyDescription variant="pWhite">
                                We provide you with custom designs that fit your narrative and makes you stand out.
                            </ProductStyledTypographyDescription>
                            <ProductStyledTypographyDescription variant="pWhite">
                                From a virtual night club, to a blissful outdoor retreat, anything you can envision can
                                be brought to life.
                            </ProductStyledTypographyDescription>
                        </FeatureTextContainer>
                        <VideoContainer>
                            <FeatureVideo muted autoPlay loop>
                                <source type="video/mp4" src={'/videos/landingPages/Pro_Room_Showcase.mp4'} />
                            </FeatureVideo>
                        </VideoContainer>
                    </Feature>
                    <BrandLogoContainer>
                        <Typography variant="LandingPageTSubtitle" style={{ marginBottom: '2rem' }}>
                            Our List of Growing <HighlightedSubTitle isVisible>Partners</HighlightedSubTitle>
                        </Typography>
                        <GalleryDisplayLogos>
                            {Object.keys(images).map((key, index) => {
                                if (index > 2) {
                                    return (
                                        <Image
                                            key={index}
                                            src={images[key]}
                                            alt={`logo-${index}`}
                                            style={{ height: '35px' }}
                                        />
                                    );
                                }
                                return <Image key={index} src={images[key]} alt={`logo-${index}`} />;
                            })}
                        </GalleryDisplayLogos>
                    </BrandLogoContainer>
                </SectionContainer>
            </SectionBackground>
        </>
    );
};

export default ExperienceSection;

const GalleryDisplayLogos = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
`;

const Image = styled.img`
    width: auto;
    max-width: 250px;
    height: 25px;
`;

const HighlightedSubTitle = styled.span`
    color: ${setColor.accent4};
`;

const BrandLogoContainer = styled.div`
    border: 2px solid rgba(190, 174, 255, 0.3);
    width: 100%;
    height: auto;
    background: #beaeff1a;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;

    @media (max-width: 950px) {
        background: none;
        border: none;
        padding: 15px;
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const HighlightedTitle = styled.span`
    color: ${setColor.accent4};
    animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 1s ease-in-out;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;

    @media (max-width: 960px) {
        position: static;
        margin-left: 0;
    }
`;

const StyledTypographyTitle = styled(Typography)`
    font-size: 3.2rem;
    text-align: left;

    @media (max-width: 960px) {
        text-align: center;
        margin-bottom: 16px;
    }
`;

const ProductStyledTypographyDescription = styled(Typography)`
    text-align: left;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 15px;
    line-height: 1.4;
    font-size: 19px;

    @media (max-width: 960px) {
        text-align: center;
        font-size: 16px;
        margin-right: 0;
        margin: 10px 0;
    }
`;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background:
        linear-gradient(73deg, rgba(17, 14, 37, 0.95) 0%, rgba(17, 14, 37, 0.95) 100.44%),
        linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #110e25 100%),
        url(${BackgroundImage}) no-repeat center/cover;
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;

    @media ${device.desktopWide} {
        padding: 75px 0;
    }

    @media (max-width: 960px) {
        width: 90%;
        padding-top: 0;
    }
`;

const Feature = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 950px) {
        flex-direction: column;
        height: auto;
        gap: 20px;
    }
`;

const FeatureTextContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    @media (max-width: 950px) {
        width: 100%;
        align-items: center;
    }
`;

const VideoContainer = styled.div`
    width: auto;
    height: 300px;
    border-radius: 10px;
    border: 4px solid rgba(190, 174, 255, 0.3);
    overflow: hidden;

    @media (max-width: 950px) {
        height: 175px;
    }
`;

const FeatureVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 960px) {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
`;
