import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';

const InputField = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((value) => !value);

    return (
        <CssTextField
            onKeyDown={props.handleInputKeyDown}
            fullWidth
            variant="outlined"
            id="outlined-password-input"
            value={props.value}
            onChange={(e) => props.setValue(e.target.value)}
            placeholder={props.placeHolder}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                sx: {
                    '@media (max-Width: 780px)': {
                        height: '50px !important',
                    },
                },
                style: Input,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default InputField;

InputField.defaultProp = {
    placeHolder: 'Default Placeholder',
    value: '',
};

InputField.propTypes = {
    placeHolder: PropTypes.string,
    value: PropTypes.any,
    setValue: PropTypes.any,
    handleInputKeyDown: PropTypes.any,
};

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
        '& .MuiSvgIcon-root': {
            color: 'rgba(157, 160, 167, 1)',
        },
    },
});

const Input = {
    background: 'rgba(27, 27, 35, 0.50)',
    border: '4px solid rgba(255, 255, 255, 0.20)',
    height: '60px',
    borderRadius: 0,
    color: '#FBFCFF',
    fontSize: '20px',
};
