import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import MailIcon from '@mui/icons-material/Mail';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CreateIcon from '@mui/icons-material/Create';
import { default as Button } from '../../../../components/common/button/Button';
import usernameIcon from './images/usernameIcon.png';
import birthdayIcon from './images/Birthday.png';
import ProfileBanner from './images/Profile_Banner_Pro.png';
import USFlag from './images/USFlag.png';
import CanadaFlag from './images/CanadaFlag.png';
import { EditContentModal } from './components/EditContentModal';
import { getUserProfileData } from '../../../../api/UserController';
import Typography from '../../../../components/common/text/Typography';
import { getWindowSize } from '../../../../utils/generalUtils';
import { setColor } from '../../../../assets/styles/styles';
import Modal from '../../components/Modal';

const Profile = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [editEmailModal, setEditEmailModal] = useState(false);
    const [editPassModal, setEditPassModal] = useState(false);
    const [editBirthdayModal, setEditBirthdayModal] = useState(false);
    const [editDetailsModal, setEditDetailsModal] = useState(false);
    const [birthday, setBirthday] = useState(null);

    // User Details states
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [country, setCountry] = useState('');
    const [username, setUsername] = useState('');
    // const [phoneNumber, setPhoneNumber] = useState('');

    const token = useSelector((state) => state?.user?.token);
    const userId = useSelector((state) => state?.user?.userId);
    const userEmail = useSelector((state) => state?.user?.email);
    const role = useSelector((state) => state?.user?.role).toUpperCase();

    const handleClose = () => {
        setEditEmailModal(false);
        setEditPassModal(false);
        setEditBirthdayModal(false);
        setEditDetailsModal(false);
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
    };

    useEffect(() => {
        const handlePageLoad = async () => {
            const userData = await getUserProfileData(token, userId);
            const { userBirthday, displayName, firstName, lastName, userCountry } = userData.data;

            if (userBirthday !== null && userBirthday !== undefined) {
                const apiDate = new Date(userBirthday);
                const formattedDate = apiDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
                setBirthday(formattedDate);
            } else {
                setBirthday(null);
            }

            if (displayName) {
                setUsername(displayName);
            }
            if (firstName) {
                setFirstName(firstName);
            }
            if (lastName) {
                setLastName(lastName);
            }
            if (userCountry) {
                setCountry(userCountry);
            }
        };
        handlePageLoad();
    }, [
        birthday,
        username,
        firstName,
        lastName,
        country,
        editBirthdayModal,
        editDetailsModal,
        editEmailModal,
        editPassModal,
        token,
        userId,
    ]);

    return (
        <>
            <BannerContainer windowSize={windowSize}>
                <ColumnContentContainer>
                    <RowContentContainer>
                        <img src={usernameIcon} alt="usernameIcon" />
                        <Typography
                            variant="h1UserHeader"
                            fontSize="46px"
                            style={{ color: setColor.accent4, margin: '1rem 0rem' }}
                        >
                            {username}
                        </Typography>
                    </RowContentContainer>
                    {country !== '' && country !== null && country !== undefined && (
                        <Typography variant="UsernameText">
                            <img
                                style={
                                    country === 'Canada' ? { height: '35px', width: '35px', marginTop: '3px' } : null
                                }
                                src={country === 'Canada' ? CanadaFlag : USFlag}
                                alt="US Flag"
                            />
                            {country}
                        </Typography>
                    )}
                </ColumnContentContainer>
            </BannerContainer>
            <UserConsoleText style={{ marginTop: '2rem' }}>General Details</UserConsoleText>
            <ColoredContainer>
                <CreateIcon
                    titleAccess="Edit Profile Details"
                    onClick={() => {
                        setEditDetailsModal(true);
                    }}
                    sx={{
                        float: 'right',
                        color: setColor.iconColor2,
                        cursor: 'pointer',
                        '&:hover': { color: setColor.iconColor3 },
                    }}
                />
                <Typography variant="UsernameText" style={{ marginLeft: windowSize < 768 ? '30px' : '0ox' }}>
                    <img src={usernameIcon} alt="usernameIcon" />
                    <p>{username}</p>
                </Typography>
                <RowContentContainer>
                    <RowContentContainer style={{ flexDirection: 'row' }}>
                        <Typography variant="UsernameSubtitle">
                            <PersonIcon
                                style={{ verticalAlign: 'bottom', paddingRight: '3px', color: setColor.iconColor3 }}
                            />
                            {firstName} {lastName}
                        </Typography>
                    </RowContentContainer>
                    {country !== '' && country !== null && country !== undefined && (
                        <RowContentContainer style={{ flexDirection: 'row' }}>
                            <PlaceIcon
                                style={{ verticalAlign: 'bottom', marginRight: '5px', color: setColor.iconColor3 }}
                            />
                            <Typography variant="UsernameSubtitle">{country}</Typography>
                        </RowContentContainer>
                    )}
                </RowContentContainer>
                <RowContentContainer>
                    <Typography variant="PinkHeading">
                        {role}
                    </Typography>
                </RowContentContainer>
            </ColoredContainer>
            <UserConsoleText>Birthday</UserConsoleText>
            <SmallSubtitle>
                Let Intraverse know your birthday! We may send you a gift or exclusive offers and deals on your special
                day!
            </SmallSubtitle>
            {birthday === null || birthday === undefined ? (
                <Button
                    variant="PinkButton"
                    label="ADD MY BIRTHDAY"
                    buttonWidth={120}
                    margin={'1rem 0rem 2rem 0rem'}
                    onClick={() => {
                        setEditBirthdayModal(true);
                    }}
                />
            ) : (
                <InputContainer>
                    <img
                        src={birthdayIcon}
                        alt="birthdayIcon"
                        style={{
                            margin: '5px',
                            color: setColor.iconColor3,
                            cursor: 'default',
                            height: '20px',
                            width: '20px',
                        }}
                    />
                    <Input
                        onClick={() => {
                            setEditBirthdayModal(true);
                        }}
                        type="text"
                        placeholder={birthday}
                    />
                    <CreateIcon
                        onClick={() => {
                            setEditBirthdayModal(true);
                        }}
                        sx={{ '&:hover': { color: setColor.iconColor3 }, margin: '5px', color: 'grey' }}
                    />
                </InputContainer>
            )}
            <BorderContainer>
                <PaddedContainer>
                    <UserConsoleText>Login and Password</UserConsoleText>
                    <SmallSubtitle>The information below is what you currently use to log in Intraverse.</SmallSubtitle>
                    <RowContentContainer>
                        <InputContainer>
                            <MailIcon style={{ margin: '5px', color: setColor.iconColor3, cursor: 'default' }} />
                            <Input
                                style={{ pointerEvents: 'none' }}
                                readonly
                                type="email"
                                placeholder={userEmail}
                                value={userEmail}
                            />
                            <CreateIcon
                                onClick={() => {
                                    setEditEmailModal(true);
                                }}
                                sx={{ '&:hover': { color: setColor.iconColor3 }, margin: '5px', color: 'grey' }}
                            />
                        </InputContainer>
                        <InputContainer>
                            <VpnKeyIcon style={{ margin: '5px', color: setColor.iconColor3, cursor: 'default' }} />
                            <Input style={{ pointerEvents: 'none' }} readonly type="password" placeholder="*********" />
                            <CreateIcon
                                onClick={() => setEditPassModal(true)}
                                sx={{ '&:hover': { color: setColor.iconColor3 }, margin: '5px', color: 'grey' }}
                            />
                        </InputContainer>
                    </RowContentContainer>
                </PaddedContainer>
            </BorderContainer>
            <BorderContainer>
                <PaddedContainer>
                    <UserConsoleText>Account Removal</UserConsoleText>
                    <SmallSubtitle>
                        Please contact our support team <span>hello@terrazero.com</span> to temporarily disable or{' '}
                        <strong> permanently </strong> delete your account.
                    </SmallSubtitle>
                    <Button
                        label="Contact Support"
                        variant="OutlinedButton"
                        buttonWidth={'fit-content'}
                        margin="2rem 0rem"
                        onClick={() => {
                            window.location = 'mailto:hello@terrazero.com';
                        }}
                    />
                    <Button label="Log out" buttonWidth={'fit-content'} onClick={handleLogout} />
                </PaddedContainer>
            </BorderContainer>
            <Modal
                isOpen={editEmailModal}
                setIsOpen={setEditEmailModal}
                children={
                    <EditContentModal open={editEmailModal} userEmail={userEmail} isEmail handleClose={handleClose} />
                }
            />
            <Modal
                isOpen={editPassModal}
                setIsOpen={setEditPassModal}
                children={<EditContentModal open={editPassModal} isPassword handleClose={handleClose} />}
            />
            <Modal
                isOpen={editBirthdayModal}
                setIsOpen={setEditBirthdayModal}
                children={
                    <EditContentModal
                        open={editBirthdayModal}
                        isBirthday
                        handleClose={handleClose}
                        setCurrentBirthday={setBirthday}
                    />
                }
            />
            <Modal
                isOpen={editDetailsModal}
                setIsOpen={setEditDetailsModal}
                children={<EditContentModal open={editDetailsModal} isUserData handleClose={handleClose} />}
            />
        </>
    );
};

export default Profile;

const BannerContainer = styled.div`
    display: flex;
    background-image: url(${ProfileBanner});
    background-size: 100% 95%;
    background-repeat: no-repeat;
    max-width: 1280px;
    height: auto;
    padding: 1rem 4rem 4rem 4rem;
    align-items: center;
    justify-content: center;
    margin-left: -33px;
    margin-right: -33px;

    & img {
        margin-right: 1rem;
    }

    @media (max-width: 768px) {
        background-size: cover;
    }
`;

const BorderContainer = styled.div`
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    margin: 40px -30px;
`;
const PaddedContainer = styled.div`
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
`;
const RowContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;

    @media (max-width: 768px) {
        flex-direction: column;

        & img {
            margin-left: 3rem;
        }
    }
`;
const ColumnContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
`;

const ColoredContainer = styled.div`
    border-radius: 10px;
    background: rgba(190, 174, 255, 0.1);
    padding: 1rem 1rem 1rem 2rem;
    margin: 30px 0px 50px 0px;

    @media (max-width: 768px) {
        padding: 0.5rem;
        text-align: center;
    }
`;

const Input = styled.input`
    flex: 1;
    border: none;
    background: none;
    width: 90%;
    height: 50px;
    color: white;
    margin-left: 8px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px rgb(35, 34, 48) inset !important;
        -webkit-text-fill-color: white !important;
    }
`;
const InputContainer = styled.div`
    display: flex;
    flex: 1;
    margin-right: 8px;
    border-radius: 10px;
    justify-content: center;
    background: rgba(190, 174, 255, 0.1);
    cursor: pointer;
    height: 50px;
    align-items: center;
    padding: 5px;

    @media (max-width: 768px) {
        margin: 5px;
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin: 5px;
        width: 100%;
    }
`;

const SmallSubtitle = styled.p`
    text-align: left;
    color: ${setColor.circularProgress};
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem;

    & > span {
        color: ${setColor.iconColor3};
        font-weight: 700;
    }
`;

const UserConsoleText = styled.h1`
    color: ${setColor.iconColor3};
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 1rem 0rem;
`;
