import React, { useState } from 'react';
import DataTable from '../../../../components/common/dataTable/DataTable';
import { formatDateTimeWithTimeZone, capitalizeFirstLetter } from '../../../../utils/generalUtils';

const ProductsTable = ({ productsData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const renderRow = (product) => {
        const createdDateTime = formatDateTimeWithTimeZone(product.createdAt);
        if (product.sku === '') {
            product.sku = 'Not Available';
        }
        return [
            product.productId,
            product.storeId,
            capitalizeFirstLetter(product.productName),
            capitalizeFirstLetter(product.productDescription),
            product.sku,
            capitalizeFirstLetter(product.productType),
            capitalizeFirstLetter(product.category),
            capitalizeFirstLetter(product.brand),
            product.costPrice,
            product.minStock,
            product.maxStock,
            product.quantity,
            createdDateTime,
        ];
    };

    return (
        <DataTable
            headers={[
                'Product ID',
                'Store ID',
                'Name',
                'Product Description',
                'SKU',
                'Product Type',
                'Category',
                'Brand',
                'Cost Price',
                'Min Stock',
                'Max Stock',
                'Quantity',
                'Creation Time',
            ]}
            data={productsData}
            renderRow={renderRow}
            openModal={openModal}
            isModalOpen={isModalOpen}
            selectedRow={selectedRow}
            isHover={false}
        />
    );
};

export default ProductsTable;
